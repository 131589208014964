import React from "react";

export const AttentionIcon = ({
    height = "2.4rem",
    width = "2.4rem",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1679 4.74808C13.5638 4.15434 14.4362 4.15434 14.832 4.74808L26.9635 22.9453C27.4066 23.6099 26.9302 24.5 26.1315 24.5H1.86852C1.06982 24.5 0.59343 23.6099 1.03647 22.9453L13.1679 4.74808Z" fill="#0F284C"/>
<path d="M15.2147 9V14.0758C15.2147 14.6141 15.184 15.1465 15.1227 15.673C15.0675 16.1995 14.9877 16.7468 14.8834 17.3147H13.1718C13.0675 16.7468 12.9847 16.1995 12.9233 15.673C12.8681 15.1465 12.8405 14.6141 12.8405 14.0758V9H15.2147ZM12.5 20.5891C12.5 20.3939 12.5368 20.2105 12.6104 20.0389C12.6902 19.8614 12.7975 19.7106 12.9325 19.5863C13.0675 19.4562 13.2239 19.3556 13.4018 19.2846C13.5859 19.2077 13.7853 19.1693 14 19.1693C14.2086 19.1693 14.4018 19.2077 14.5798 19.2846C14.7638 19.3556 14.9233 19.4562 15.0583 19.5863C15.1933 19.7106 15.3006 19.8614 15.3804 20.0389C15.4601 20.2105 15.5 20.3939 15.5 20.5891C15.5 20.7902 15.4601 20.9766 15.3804 21.1481C15.3006 21.3197 15.1933 21.4705 15.0583 21.6007C14.9233 21.7249 14.7638 21.8225 14.5798 21.8935C14.4018 21.9645 14.2086 22 14 22C13.7853 22 13.5859 21.9645 13.4018 21.8935C13.2239 21.8225 13.0675 21.7249 12.9325 21.6007C12.7975 21.4705 12.6902 21.3197 12.6104 21.1481C12.5368 20.9766 12.5 20.7902 12.5 20.5891Z" fill="white"/>
</svg>
);