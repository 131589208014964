export const EyeControlIcon = ({ width="15.2rem", height="3.6rem" }: React.SVGProps<SVGSVGElement>) => (
<svg width="152" height="36" viewBox="0 0 152 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3788_1166)">
<path d="M3.55853 21.5306C3.86112 24.0026 5.91878 25.6166 8.91145 25.6166C10.189 25.6123 11.4468 25.3037 12.5789 24.7166C12.9442 24.5194 13.3638 24.4439 13.7757 24.5011C14.1876 24.5584 14.57 24.7454 14.8665 25.0346L15.8923 26.0216C14.1978 27.8666 11.3716 28.7906 8.84488 28.7906C3.45867 28.7816 0 25.3856 0 20.1776C0 15.2336 3.49196 11.6996 8.61188 11.6996C13.4534 11.6996 16.8758 14.3996 16.9454 19.9346C16.9474 20.143 16.9078 20.3497 16.8289 20.5428C16.7501 20.736 16.6335 20.9118 16.4858 21.0601C16.3382 21.2084 16.1624 21.3264 15.9687 21.4071C15.7749 21.4878 15.5669 21.5298 15.3567 21.5306H3.55853ZM13.5654 18.6626C13.3657 16.0916 11.4714 14.7386 8.72384 14.7386C6.23045 14.7386 4.1849 16.0916 3.6372 18.6626H13.5654Z" fill="white"/>
<path d="M34.9467 12.2011L25.2304 34.5631C25.0455 34.9901 24.7381 35.354 24.3465 35.6094C23.9549 35.8649 23.4962 36.0007 23.0275 36.0001H20.8155L24.2076 28.2211L17.7441 12.2011H19.9712C20.4633 12.201 20.9435 12.3509 21.3468 12.6305C21.75 12.9101 22.0568 13.3058 22.2256 13.7641L24.4769 19.8811L26.1049 24.4951L27.869 19.9471L30.5107 13.6651C30.6925 13.2313 31 12.8607 31.3943 12.6001C31.7885 12.3395 32.2518 12.2007 32.7257 12.2011H34.9467Z" fill="white"/>
<path d="M70.1301 26.2772C69.3243 27.1 68.3565 27.7499 67.2868 28.1865C66.2171 28.6231 65.0684 28.8371 63.9118 28.8152C59.1913 28.8152 55.2666 25.8812 55.2666 20.2772C55.2666 14.6732 59.158 11.7392 63.9118 11.7392C65.0121 11.7119 66.1061 11.911 67.1249 12.3241C68.1436 12.7372 69.0649 13.3552 69.8305 14.1392L68.8168 15.1892C68.5118 15.4991 68.1134 15.7024 67.6817 15.7684C67.25 15.8344 66.8084 15.7596 66.4233 15.5552C65.6568 15.1635 64.8074 14.9578 63.9451 14.9552C60.9857 14.9552 58.692 17.0312 58.692 20.2622C58.692 23.6912 61.0855 25.5032 63.9118 25.5032C64.8991 25.5177 65.8732 25.2768 66.738 24.8042C67.0891 24.6095 67.4946 24.5335 67.8931 24.5877C68.2917 24.6418 68.6616 24.8231 68.947 25.1042L70.1301 26.2772Z" fill="white"/>
<path d="M87.8676 20.2771C87.8676 25.0771 84.5088 28.7161 79.3556 28.7161C74.2024 28.7161 70.9102 25.0891 70.9102 20.2771C70.9102 15.4651 74.269 11.8051 79.3556 11.8051C84.4422 11.8051 87.8676 15.4981 87.8676 20.2771ZM74.3355 20.2771C74.3355 23.1781 76.2298 25.5511 79.3556 25.5511C82.4814 25.5511 84.4241 23.1781 84.4241 20.2771C84.4241 17.3761 82.3604 14.9371 79.3677 14.9371C76.2298 14.9371 74.3355 17.4001 74.3355 20.2771Z" fill="white"/>
<path d="M146.529 20.2771C146.529 25.0771 143.173 28.7161 138.017 28.7161C132.861 28.7161 129.572 25.0891 129.572 20.2771C129.572 15.4651 132.931 11.8051 138.017 11.8051C143.104 11.8051 146.529 15.4981 146.529 20.2771ZM132.997 20.2771C132.997 23.1781 134.894 25.5511 138.017 25.5511C141.14 25.5511 143.074 23.1781 143.074 20.2771C143.074 17.3761 141.01 14.9371 138.017 14.9371C134.894 14.9371 132.997 17.4001 132.997 20.2771Z" fill="white"/>
<path d="M152 7.72458V28.3856H148.575V5.34558H149.597C150.234 5.34558 150.845 5.59613 151.295 6.04219C151.746 6.48825 151.999 7.09336 152 7.72458Z" fill="white"/>
<path d="M97.7386 11.8717C93.7473 11.8717 89.8711 14.2417 89.8711 19.2187V28.3867H93.3298V19.6867C93.3298 16.8847 94.9244 15.0067 97.7507 15.0067C100.577 15.0067 102.172 16.8847 102.172 19.6867V28.3867H105.63V19.2187C105.63 14.2417 101.742 11.8717 97.7386 11.8717Z" fill="white"/>
<path d="M128.906 11.9197C128.617 11.8887 128.328 11.8727 128.037 11.8717C124.046 11.8717 120.17 14.2417 120.17 19.2187V28.3867H123.626V19.6867C123.626 16.8847 125.223 15.0067 128.05 15.0067C128.34 15.0076 128.631 15.0307 128.918 15.0757L128.906 11.9197Z" fill="white"/>
<path d="M113.189 20.5556V15.3236H117.175V12.1676H113.189V7.72458C113.189 7.09363 112.937 6.48852 112.487 6.04237C112.037 5.59622 111.426 5.34558 110.79 5.34558H109.773V12.1676H106.547V15.3236H109.773V20.8046C109.773 25.7846 113.622 28.3706 117.61 28.3706V25.2386C114.784 25.2386 113.189 23.3576 113.189 20.5556Z" fill="white"/>
<path d="M39.498 21.5305C39.8006 24.0025 41.8582 25.6165 44.8509 25.6165C46.1285 25.6122 47.3863 25.3036 48.5184 24.7165C48.8842 24.5195 49.3042 24.4441 49.7165 24.5014C50.1288 24.5586 50.5118 24.7455 50.809 25.0345L51.8318 26.0215C50.1372 27.8665 47.311 28.7905 44.7843 28.7905C39.3981 28.7905 35.9395 25.3945 35.9395 20.1865C35.9395 15.2425 39.4314 11.7145 44.5513 11.7145C49.3929 11.7145 52.8152 14.3995 52.8697 19.9435C52.8721 20.152 52.8328 20.3588 52.7541 20.5522C52.6753 20.7455 52.5588 20.9215 52.411 21.0699C52.2633 21.2183 52.0874 21.3362 51.8935 21.4168C51.6995 21.4974 51.4914 21.5391 51.2811 21.5395L39.498 21.5305ZM49.5048 18.6625C49.3051 16.0915 47.4109 14.7385 44.6633 14.7385C42.1699 14.7385 40.1092 16.0915 39.5767 18.6625H49.5048Z" fill="#00B4CD"/>
</g>
<defs>
<clipPath id="clip0_3788_1166">
<rect width="152" height="36" fill="white"/>
</clipPath>
</defs>
</svg>
);