import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import {
    SquareIcon,
    PlayIcon,
    ArrowDownloadIcon,
    ArchiveIcon,
} from "../../icons";
import Moment from "react-moment";
import { downloadFromPresignedUrl, formatFloatAsTime } from "src/utils/generalMethods";
import {
    FamilyRecordingModel,
    FamilyRecordingAttribute,
    RecordingStatus,
} from "../../models/recording.model";
import { useTranslation } from "react-i18next";
import { TOAST_SEVERITY } from "../../models/toast.model";
import Timer from "../general/Timer";

interface FamilyRecordingsProps {
    recordings: FamilyRecordingModel[];
    patientId: string;
    updateFamilyRecording: Function;
    showToast: Function;
}

const NOT_PLAYING = -1;

const FamilyRecordings: React.FC<FamilyRecordingsProps> = ({
    recordings,
    patientId,
    updateFamilyRecording,
    showToast,
}: FamilyRecordingsProps) => {
    const { t } = useTranslation();

    const [playingArray, setPlayingArray] = useState<boolean[]>([]);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [playingIndex, setPlayingIndex] = useState<number>(NOT_PLAYING);
    const [animationClasses, setAnimationClasses] = useState<{
        [key: string]: boolean;
    }>({});

    const togglePlaying = (index: number) => {
        setPlayingArray((prev) => {
            const newState = [...prev];
            newState[index] = !prev[index];

            if (newState[index]) {
                let newVoiceRecord: HTMLAudioElement = new Audio();
                newVoiceRecord.src = recordings[index]?.presigned_url;
                newVoiceRecord
                    ?.play()
                    .then()
                    .catch((error) => {});
                newVoiceRecord?.addEventListener("ended", (event) => {
                    togglePlaying(playingIndex);
                });
                setAudio(newVoiceRecord);
            } else {
                if (audio) {
                    audio?.pause();
                    audio.src = "";
                    setAudio(null);
                }
            }

            for (let i = 0; i < newState.length; i++) {
                if (i !== index) {
                    newState[i] = false;
                }
            }
            return newState;
        });
    };

    const updateAnimationClass = (
        isOn: boolean,
        recording: FamilyRecordingModel
    ) => {
        setAnimationClasses((prev: { [key: string]: boolean }) => {
            const newState = { ...prev };
            newState[recording.id] = isOn;
            return newState;
        });
    };

    const toggleRecordingStatus = (
        value: any,
        recording: FamilyRecordingModel
    ) => {
        const updatedRecording = {
            ...recording,
            status: value ? RecordingStatus.NOT_PLAYED : RecordingStatus.PLAYED,
        };

        updateAnimationClass(true, recording);

        setTimeout(() => {
            updateAnimationClass(false, recording);
        }, 1000);

        const prevRecording = { ...recording };

        updateFamilyRecording(
            patientId,
            updatedRecording,
            prevRecording,
            FamilyRecordingAttribute.STATUS,
            (error: string) => {
                showToast({
                    severity: TOAST_SEVERITY.ERROR,
                    summary: t("Something went wrong!"),
                    detail: t("The recording status wasn't change"),
                });
            }
        );
    };

    const downloadFamilyRecording = (
        fileName: string,
        presignedUrl: string
    ) => {
        downloadFromPresignedUrl(fileName, presignedUrl);
    };

    useEffect(() => {
        setPlayingArray(Array(recordings.length).fill(false));
        const classAnimationObj: { [key: string]: boolean } = {};
        recordings.forEach((recording) => {
            classAnimationObj[recording.id] = false;
            setAnimationClasses(classAnimationObj);
        });
    }, []);

    useEffect(() => {
        return () => {
            if (audio) {
                audio?.pause();
                audio.src = "";
                setAudio(null);
            }
        };
    }, [recordings, audio]);

    return (
        <ul className="recordings-list__list">
            {recordings.map((recording, index) => {
                return (
                    <li
                        className={`recordings-list__item ${
                            animationClasses[recording.id] ? "animation" : ""
                        }`}
                        key={recording.id}
                    >
                        <div className="recordings-list__item-start">
                            <div className="recordings-list__play">
                                <Button
                                    rounded
                                    onClick={() => togglePlaying(index)}
                                    className="recording-general__play-button button"
                                    disabled={playingArray.some(
                                        (p, i) => p && i !== index
                                    )}
                                    icon={
                                        playingArray[index] ? (
                                            <SquareIcon />
                                        ) : (
                                            <PlayIcon />
                                        )
                                    }
                                ></Button>
                            </div>
                            <div className="recordings-list__name">
                                {recording.ui_name || recording.file_name}
                            </div>
                            <div className="recordings-list__duration">
                                {playingArray[index] ? (
                                    <Timer
                                        isRunning={playingArray[index]}
                                        timeLimit={parseFloat(
                                            recording.duration
                                        )}
                                    />
                                ) : (
                                    formatFloatAsTime(
                                        parseFloat(recording.duration) / 60
                                    )
                                )}
                            </div>
                            <div className="recordings-list__date">
                                <Moment format="MMM Do, YYYY HH:mm">
                                    {recording.creation_date}
                                </Moment>
                            </div>
                        </div>
                        <div className="recordings-list__item-end">
                            <div className="recordings-list__active-toggle">
                                <InputSwitch
                                    checked={recording.status === 0}
                                    onChange={(e) =>
                                        toggleRecordingStatus(
                                            e.value,
                                            recording
                                        )
                                    }
                                />
                            </div>
                            <div
                                onClick={() =>
                                    downloadFamilyRecording(
                                        recording.file_name,
                                        recording.presigned_url
                                    )
                                }
                                className="recordings-list__download"
                            >
                                <ArrowDownloadIcon />
                            </div>
                            {/* <div className="recordings-list__delete">
                                                    <ArchiveIcon />
                                                </div> */}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default FamilyRecordings;
