import { Dropdown } from "primereact/dropdown";
import { SelectItemOptionsType } from "primereact/selectitem";

interface MedDropDownProps {
    input: { value: number; onChange: Function };
    options?: SelectItemOptionsType | undefined;
    label?: string;
    disabled: boolean;
    optionLabel: string;
    optionValue: string;
    className?: string;
    placeholder: string;
    panelClassName?: string;
    valueTemplate?: Function;
    itemTemplate?: Function;
    meta?: any;
}

export const MedDropDown = ({
    input: { value, onChange },
    label,
    options,
    disabled,
    optionLabel,
    optionValue,
    className,
    placeholder,
    panelClassName,
    valueTemplate,
    itemTemplate,
    meta: { touched, error, warning },
}: MedDropDownProps) => {
    return (
        <div className="med-dropdown">
            {label ? (
                <label className="med-dropdown__label">{label}</label>
            ) : null}
            <Dropdown
                disabled={disabled}
                optionLabel={optionLabel}
                optionValue={optionValue}
                className={`med-dropdown__dropdown ${
                    touched && error ? "input-error__wrapper" : ""
                }`}
                options={options}
                onChange={(e) => {
                    onChange(e.value);
                }}
                value={value}
                placeholder={placeholder}
                panelClassName={
                    !panelClassName
                        ? "med-dropdown__dropdown-panel"
                        : panelClassName
                }
                valueTemplate={valueTemplate}
                itemTemplate={itemTemplate}
            />
            <div className="med-dropdown__error input-error">
                <div className="med-dropdown__error-text input-error__text">
                    {touched &&
                        ((error && <span>{error}</span>) ||
                            (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
