export enum DeviceActionTypes {
    FETCH_DEVICES="DeviceActionTypes.FETCH_DEVICES",
    DELETE_DEVICE="DeviceActionTypes.DELETE_DEVICE",
    UPDATE_REPORTED_SETTINGS="DeviceActionTypes.UPDATE_REPORTED_SETTINGS",
    UPDATE_DELTA_SETTINGS="DeviceActionTypes.UPDATE_DELTA_SETTINGS",
    UPDATE_REPORTED_STATE="DeviceActionTypes.UPDATE_DELTA_STATE",
    UPDATE_ACTIVE_COMMUNICATION="DeviceActionTypes.UPDATE_ACTIVE_COMMUNICATION",
    SET_DEVICE_SETTINGS = "DeviceActionTypes.SET_DEVICE_SETTINGS",
    SAVE_SUBSCRIPTION = "DeviceActionTypes.SAVE_SUBSCRIPTION",
    REMOVE_SUBSCRIPTIONS = "DeviceActionTypes.REMOVE_SUBSCRIPTIONS",
    UPDATE_DEVICE_MODEL="DeviceActionTypes.UPDATE_DEVICE_MODEL",
    UPDATE_PATIENT="DeviceActionTypes.UPDATE_PATIENT",
    UPDATE_WIFI_STRENGTH="DeviceActionTypes.UPDATE_WIFI_STRENGTH",
    UPDATE_COMMUNICATION_LOGS="DeviceActionTypes.UPDATE_COMMUNICATION_LOGS",
    UPDATE_NOTIFICATIONS="DeviceActionTypes.UPDATE_NOTIFICATIONS",
    UPDATE_QNA_STATUS="DeviceActionTypes.UPDATE_QNA_STATUS",
    ATTACH_PATIENT_TO_DEVICE="DeviceActionTypes.ATTACH_PATIENT_TO_DEVICE",
    DETACH_PATIENT_FROM_DEVICE="DeviceActionTypes.DETACH_PATIENT_FROM_DEVICE",
    DELETE_DEVICES="DeviceActionTypes.DELETE_DEVICES",
    UPDATE_PATIENT_IMAGE="DeviceActionTypes.UPDATE_PATIENT_IMAGE"
}
export enum AuthenticationActionTypes {
    AUTHENTICATED="AuthenticationActionTypes.AUTHENTICATED",
    NOT_AUTHENTICATED="AuthenticationActionTypes.NOT_AUTHENTICATED"
}

export enum UserActionTypes {
    FETCH_USERS="AuthenticationActionTypes.FETCH_USERS"
}

export enum ShadowUpdaterActionTypes {
    SET_SHADOW_ITEM = "ShadowUpdaterActionTypes.SET_SHADOW_ITEM",
}

export enum SqsMessageSenderActionTypes {
    SET_MESSAGE_ITEM = "SqsMessageSenderActionTypes.SET_MESSAGE_ITEM",
}

export enum tenantActionTypes {
    SET_VOICES = "tenantActionTypes.SET_VOICES"
}

export enum toastActionTypes {
    UPDATE_TOAST = "toastActionTypes.UPDATE_TOAST",
    CLEAR_TOAST = "toastActionTypes.CLEAR_TOAST"
}

export enum IcuActionTypes {
    SET_ICU_SETTINGS = "IcuActionTypes.SET_ICU_SETTINGS",
    FETCH_ICUS = "IcuActionTypes.FETCH_ICUS"
}

export enum QuestionActionTypes {
    FETCH_CURRENT_QUESTIONS = "QuestionActionTypes.FETCH_CURRENT_QUESTIONS"
}

export enum schedulerEventActionTypes {
    FETCH_EVENTS = "schedulerEventActionTypes.FETCH_EVENTS",
    ADD_EVENT = "schedulerEventActionTypes.ADD_EVENT",
    UPDATE_EVENT = "schedulerEventActionTypes.UPDATE_EVENT",
    DELETE_EVENT = "schedulerEventActionTypes.DELETE_EVENT"
}

export enum RecordingEventActionTypes {
    FETCH_FAMILY_RECORDINGS = "recordingEventActionTypes.FETCH_FAMILY_RECORDINGS",
    UPDATE_FAMILY_RECORDING = "recordingEventActionTypes.UPDATE_RECORDING",
    SORT_FAMILY_RECORDINGS = "recordingEventActionTypes.SORT_FAMILY_RECORDINGS",
    FETCH_GENERAL_RECORDINGS = "recordingEventActionTypes.GENERAL_RECORDINGS",
    FETCH_RECORDING_SLOTS = "recordingEventActionTypes.FETCH_RECORDING_SLOTS",
    FETCH_PRE_COMMUNICATION_SLOT = "recordingEventActionTypes.FETCH_PRE_COMMUNICATION_SLOT",
    UPDATE_PRE_COMMUNICATION_SLOT = "recordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT",
    DELETE_PRE_COMMUNICATION_SLOT = "recordingEventActionTypes.DELETE_PRE_COMMUNICATION_SLOT",
    UPDATE_RECORDING_SLOT = "recordingEventActionTypes.UPDATE_RECORDING_SLOT",
    DELETE_RECORDING_SLOT = "recordingEventActionTypes.DELETE_RECORDING_SLOT",
    FAMILY_RECORDINGS_PENDING = 'FAMILY_RECORDINGS_PENDING',
    SLOTS_PENDING = 'SLOTS_PENDING',
    GENERAL_RECORDINGS_PENDING = 'GENERAL_RECORDINGS_PENDING',
    FAMILY_RECORDINGS_ERROR = 'FAMILY_RECORDINGS_ERROR',
    SLOTS_ERROR = 'SLOTS_ERROR',
    GENERAL_RECORDINGS_ERROR = 'GENERAL_RECORDINGS_ERROR'
}