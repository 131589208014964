import React from "react";

export const NoScheduledEventsIcon = ({
  height = "24px",
  width = "24px",
  color = "#0F284C",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width="506" height="267" viewBox="0 0 506 267" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8" clipPath="url(#clip0_1603_8897)">
<path opacity="0.5" d="M506 249.578C506 257.569 411.074 263.243 252.994 266.463C94.0595 269.696 0 257.569 0 249.578C0 241.587 113.333 239.147 253.006 235.109C447.277 229.485 506.012 241.587 506.012 249.578H506Z" fill="#E1EDF3"/>
<path d="M447.913 239.147C448.339 242.135 446.041 244.816 443.024 244.853L110.124 248.912C107.527 248.944 105.339 246.983 105.086 244.399L83.5893 24.3372C83.3051 21.4279 85.5664 18.8963 88.4891 18.8516L411.369 13.9145C413.886 13.876 416.039 15.7153 416.395 18.2078L447.913 239.147Z" fill="white" fillOpacity="0.8"/>
<path d="M416.423 18.4027C416.849 21.3843 414.561 24.0628 411.55 24.1087L88.6575 29.0392C86.0544 29.0789 83.8564 27.1145 83.6046 24.5233L83.5862 24.3347C83.3036 21.4263 85.5646 18.8964 88.4864 18.8517L411.369 13.9145C413.886 13.876 416.04 15.7151 416.395 18.2073L416.423 18.4027Z" fill="#CEDFEA"/>
<path d="M142.594 71.4344C142.901 74.3561 140.634 76.9108 137.696 76.9547L116.689 77.2682C114.094 77.3069 111.9 75.3527 111.64 72.7697L109.606 52.5723C109.313 49.6574 111.577 47.1158 114.506 47.072L135.431 46.7584C138.02 46.7197 140.209 48.6626 140.479 51.237L142.594 71.4344Z" fill="#FFAC80" fillOpacity="0.5"/>
<path d="M362.706 67.9252C363.106 70.8943 360.823 73.5463 357.827 73.5921L336.851 73.9134C334.311 73.9523 332.145 72.0806 331.816 69.5619L329.178 49.3718C328.791 46.4091 331.071 43.7705 334.059 43.7245L354.954 43.4032C357.486 43.3643 359.648 45.225 359.986 47.7351L362.706 67.9252Z" fill="#FD86DD" fillOpacity="0.5"/>
<path d="M220.272 107.631C220.611 110.57 218.338 113.159 215.381 113.204L194.216 113.526C191.638 113.565 189.453 111.638 189.17 109.076L186.905 88.584C186.581 85.6535 188.85 83.0803 191.798 83.0352L212.864 82.7128C215.433 82.6735 217.614 84.5872 217.908 87.1393L220.272 107.631Z" fill="#86D9BC" fillOpacity="0.5"/>
<path d="M257.141 107.047C257.495 109.993 255.22 112.598 252.252 112.643L231.101 112.965C228.534 113.004 226.354 111.093 226.058 108.543L223.678 88.05C223.336 85.1104 225.609 82.519 228.568 82.4738L249.637 82.1514C252.197 82.1123 254.373 84.0127 254.678 86.5544L257.141 107.047Z" fill="#F6F4F4"/>
<path d="M294.011 106.463C294.38 109.417 292.102 112.037 289.125 112.082L267.979 112.405C265.421 112.444 263.246 110.545 262.939 108.006L260.46 87.5121C260.104 84.5647 262.379 81.9577 265.347 81.9123L286.412 81.5901C288.962 81.551 291.133 83.4383 291.45 85.9695L294.011 106.463Z" fill="#FD86DD" fillOpacity="0.5"/>
<path d="M404.625 104.707C405.042 107.684 402.756 110.354 399.749 110.4L378.622 110.722C376.092 110.761 373.931 108.903 373.591 106.395L370.808 85.8995C370.405 82.9287 372.689 80.2733 375.686 80.2274L396.723 79.9055C399.245 79.8669 401.401 81.7128 401.751 84.2108L404.625 104.707Z" fill="#AEA0FA" fillOpacity="0.5"/>
<path d="M150.491 146.575C150.798 149.497 148.532 152.052 145.594 152.097L124.271 152.42C121.675 152.459 119.481 150.505 119.221 147.921L117.125 127.102C116.832 124.187 119.095 121.646 122.024 121.601L143.256 121.278C145.844 121.239 148.035 123.181 148.305 125.755L150.491 146.575Z" fill="#FFAC80" fillOpacity="0.5"/>
<path d="M266.247 182.944C266.601 185.89 264.325 188.495 261.358 188.539L239.891 188.863C237.323 188.902 235.144 186.989 234.848 184.439L232.403 163.312C232.063 160.372 234.336 157.782 237.294 157.737L258.67 157.413C261.23 157.375 263.405 159.275 263.71 161.817L266.247 182.944Z" fill="#AEA0FA" fillOpacity="0.5"/>
<path d="M415.244 180.569C415.661 183.546 413.375 186.215 410.369 186.262L388.924 186.594C386.393 186.633 384.233 184.775 383.892 182.267L381.024 161.149C380.621 158.178 382.904 155.523 385.901 155.477L407.255 155.145C409.778 155.106 411.934 156.952 412.285 159.45L415.244 180.569Z" fill="#FD86DD" fillOpacity="0.5"/>
<path d="M158.548 223.267C158.854 226.188 156.589 228.743 153.652 228.788L132.009 229.121C129.413 229.161 127.218 227.207 126.958 224.624L124.792 203.17C124.498 200.255 126.761 197.713 129.689 197.668L151.249 197.335C153.838 197.295 156.029 199.238 156.299 201.813L158.548 223.267Z" fill="#FD86DD" fillOpacity="0.5"/>
<path d="M233.431 222.071C233.769 225.009 231.498 227.596 228.541 227.642L206.908 227.975C204.331 228.015 202.146 226.089 201.861 223.528L199.479 202.084C199.154 199.153 201.423 196.578 204.371 196.532L225.922 196.199C228.491 196.16 230.673 198.075 230.966 200.628L233.431 222.071Z" fill="#86D9BC" fillOpacity="0.5"/>
<path d="M345.756 220.289C346.142 223.251 343.86 225.888 340.873 225.933L319.247 226.258C316.698 226.296 314.528 224.41 314.211 221.881L311.521 200.445C311.15 197.491 313.428 194.869 316.405 194.823L337.931 194.49C340.472 194.451 342.639 196.324 342.966 198.845L345.756 220.289Z" fill="#86D9BC" fillOpacity="0.5"/>
<path d="M383.197 219.691C383.599 222.662 381.314 225.315 378.317 225.36L356.697 225.685C354.157 225.723 351.993 223.851 351.664 221.332L348.865 199.896C348.479 196.934 350.759 194.296 353.746 194.249L375.267 193.917C377.798 193.878 379.96 195.737 380.299 198.246L383.197 219.691Z" fill="#FFAC80" fillOpacity="0.5"/>
<path d="M179.279 70.8489C179.602 73.7793 177.332 76.3511 174.384 76.395L153.382 76.7085C150.796 76.7471 148.607 74.8076 148.335 72.2361L146.195 52.048C145.885 49.1251 148.151 46.5667 151.09 46.5215L172.011 46.1998C174.59 46.1602 176.776 48.0888 177.058 50.6526L179.279 70.8489Z" fill="#F6F4F4"/>
<path d="M215.966 70.2661C216.304 73.2037 214.032 75.7908 211.075 75.8361L190.077 76.1577C187.5 76.1972 185.315 74.2709 185.031 71.7089L182.793 51.5097C182.468 48.5789 184.737 46.005 187.686 45.9596L208.602 45.638C211.172 45.5985 213.353 47.5136 213.646 50.0669L215.966 70.2661Z" fill="#F6F4F4"/>
<path d="M252.653 69.6818C253.005 72.6271 250.731 75.23 247.765 75.2754L226.771 75.597C224.203 75.6363 222.023 73.7235 221.728 71.172L219.392 50.972C219.052 48.0333 221.324 45.4437 224.282 45.3982L245.193 45.0766C247.754 45.0372 249.93 46.939 250.235 49.4818L252.653 69.6818Z" fill="#F6F4F4"/>
<path d="M289.336 69.0942C289.705 72.0481 287.427 74.6683 284.451 74.7139L263.463 75.0353C260.905 75.0745 258.73 73.1762 258.423 70.6361L255.98 50.4375C255.624 47.4895 257.9 44.8824 260.869 44.8379L281.775 44.5247C284.325 44.4864 286.495 46.3734 286.812 48.9039L289.336 69.0942Z" fill="#F6F4F4"/>
<path d="M326.021 68.5099C326.406 71.4714 324.126 74.1075 321.14 74.1532L300.157 74.4746C297.608 74.5137 295.438 72.6287 295.12 70.0994L292.58 49.9101C292.208 46.955 294.486 44.3323 297.464 44.2865L318.364 43.9651C320.906 43.926 323.072 45.7999 323.4 48.3206L326.021 68.5099Z" fill="#F6F4F4"/>
<path d="M399.389 67.3383C399.806 70.3156 397.52 72.985 394.514 73.0311L373.544 73.3523C371.014 73.391 368.854 71.5336 368.513 69.0263L365.768 48.8377C365.364 45.866 367.649 43.2097 370.648 43.1648L391.537 42.8518C394.058 42.814 396.214 44.6602 396.563 47.1578L399.389 67.3383Z" fill="#F6F4F4"/>
<path d="M146.524 108.817C146.831 111.738 144.565 114.293 141.628 114.338L120.462 114.66C117.866 114.7 115.672 112.746 115.411 110.162L113.342 89.6497C113.048 86.7344 115.313 84.1922 118.243 84.1485L139.326 83.8342C141.914 83.7957 144.104 85.7382 144.374 88.3121L146.524 108.817Z" fill="#F6F4F4"/>
<path d="M183.392 108.217C183.715 111.147 181.446 113.719 178.499 113.764L157.339 114.087C154.753 114.126 152.563 112.186 152.291 109.613L150.124 89.1218C149.815 86.1992 152.081 83.6416 155.02 83.5967L176.089 83.2742C178.668 83.2347 180.853 85.1629 181.136 87.7262L183.392 108.217Z" fill="#F6F4F4"/>
<path d="M330.879 105.878C331.264 108.84 328.983 111.476 325.997 111.521L304.856 111.843C302.307 111.882 300.137 109.997 299.819 107.468L297.241 86.9737C296.87 84.0186 299.148 81.3959 302.126 81.3504L323.185 81.0282C325.727 80.9893 327.893 82.8635 328.22 85.3842L330.879 105.878Z" fill="#F6F4F4"/>
<path d="M367.756 105.291C368.158 108.261 365.875 110.915 362.878 110.961L341.734 111.283C339.194 111.321 337.029 109.45 336.7 106.931L334.024 86.4357C333.637 83.473 335.918 80.8346 338.905 80.7889L359.95 80.4668C362.481 80.4281 364.642 82.287 364.982 84.7955L367.756 105.291Z" fill="#F6F4F4"/>
<path d="M187.553 145.979C187.876 148.908 185.608 151.48 182.661 151.526L161.343 151.858C158.756 151.898 156.565 149.957 156.293 147.384L154.09 126.565C153.781 123.643 156.048 121.085 158.987 121.04L180.214 120.717C182.792 120.677 184.978 122.605 185.26 125.168L187.553 145.979Z" fill="#F6F4F4"/>
<path d="M224.607 145.392C224.944 148.33 222.672 150.918 219.715 150.963L198.401 151.286C195.824 151.325 193.639 149.398 193.356 146.836L191.055 126.027C190.73 123.096 193 120.523 195.948 120.478L217.171 120.155C219.74 120.116 221.921 122.03 222.215 124.583L224.607 145.392Z" fill="#F6F4F4"/>
<path d="M261.67 144.807C262.023 147.753 259.748 150.357 256.781 150.402L235.472 150.725C232.905 150.764 230.725 148.852 230.43 146.301L228.021 125.491C227.681 122.552 229.953 119.962 232.912 119.917L254.13 119.593C256.689 119.555 258.865 121.455 259.17 123.997L261.67 144.807Z" fill="#F6F4F4"/>
<path d="M298.732 144.21C299.102 147.164 296.825 149.785 293.849 149.832L272.545 150.163C269.987 150.203 267.811 148.304 267.504 145.764L264.988 124.955C264.632 122.007 266.907 119.4 269.876 119.355L291.088 119.032C293.638 118.993 295.809 120.88 296.126 123.41L298.732 144.21Z" fill="#F6F4F4"/>
<path d="M335.785 143.625C336.17 146.587 333.889 149.224 330.903 149.269L309.604 149.592C307.055 149.631 304.885 147.746 304.568 145.216L301.953 124.415C301.581 121.461 303.858 118.838 306.836 118.792L328.043 118.461C330.585 118.421 332.752 120.295 333.079 122.816L335.785 143.625Z" fill="#F6F4F4"/>
<path d="M372.849 143.041C373.249 146.01 370.965 148.663 367.969 148.708L346.675 149.031C344.136 149.069 341.971 147.198 341.641 144.68L338.919 123.868C338.531 120.905 340.813 118.265 343.801 118.22L365.013 117.897C367.545 117.859 369.706 119.719 370.044 122.229L372.849 143.041Z" fill="#F6F4F4"/>
<path d="M409.91 142.443C410.327 145.42 408.041 148.09 405.035 148.135L383.746 148.458C381.217 148.496 379.057 146.639 378.716 144.133L375.887 123.332C375.483 120.361 377.767 117.704 380.765 117.659L401.971 117.336C404.493 117.297 406.649 119.144 406.999 121.642L409.91 142.443Z" fill="#F6F4F4"/>
<path d="M154.506 184.725C154.814 187.647 152.547 190.203 149.609 190.247L128.115 190.571C125.519 190.61 123.326 188.656 123.065 186.073L120.935 164.946C120.641 162.032 122.903 159.491 125.832 159.445L147.235 159.113C149.823 159.073 152.014 161.015 152.285 163.59L154.506 184.725Z" fill="#F6F4F4"/>
<path d="M191.752 184.128C192.075 187.058 189.807 189.63 186.86 189.676L165.372 190.008C162.785 190.048 160.594 188.107 160.322 185.533L158.093 164.397C157.784 161.475 160.051 158.918 162.989 158.873L184.377 158.549C186.955 158.51 189.14 160.438 189.423 163.001L191.752 184.128Z" fill="#F6F4F4"/>
<path d="M229.001 183.543C229.338 186.481 227.066 189.068 224.109 189.113L202.634 189.437C200.058 189.476 197.874 187.55 197.59 184.989L195.244 163.863C194.918 160.931 197.188 158.357 200.138 158.312L221.529 157.988C224.098 157.949 226.278 159.864 226.572 162.416L229.001 183.543Z" fill="#F6F4F4"/>
<path d="M303.496 182.349C303.865 185.303 301.588 187.922 298.612 187.968L277.147 188.3C274.589 188.34 272.413 186.441 272.106 183.901L269.553 162.775C269.197 159.827 271.473 157.22 274.441 157.175L295.823 156.852C298.374 156.813 300.544 158.701 300.86 161.232L303.496 182.349Z" fill="#F6F4F4"/>
<path d="M340.752 181.761C341.138 184.723 338.856 187.361 335.869 187.406L314.402 187.73C311.853 187.768 309.683 185.883 309.365 183.353L306.713 162.235C306.342 159.28 308.619 156.659 311.596 156.612L332.964 156.28C335.505 156.241 337.671 158.114 338 160.634L340.752 181.761Z" fill="#F6F4F4"/>
<path d="M377.999 181.167C378.4 184.136 376.117 186.789 373.122 186.835L351.668 187.167C349.129 187.206 346.964 185.336 346.634 182.817L343.865 161.69C343.477 158.726 345.758 156.086 348.747 156.041L370.117 155.717C372.648 155.679 374.809 157.539 375.148 160.047L377.999 181.167Z" fill="#F6F4F4"/>
<path d="M195.991 222.67C196.313 225.599 194.044 228.17 191.097 228.215L169.459 228.548C166.873 228.588 164.683 226.648 164.41 224.076L162.137 202.623C161.827 199.699 164.094 197.141 167.033 197.096L188.588 196.772C191.167 196.733 193.352 198.661 193.633 201.225L195.991 222.67Z" fill="#F6F4F4"/>
<path d="M270.874 221.473C271.227 224.419 268.953 227.023 265.986 227.069L244.358 227.402C241.791 227.441 239.611 225.53 239.314 222.979L236.824 201.534C236.483 198.595 238.755 196.004 241.714 195.958L263.259 195.625C265.819 195.586 267.995 197.487 268.3 200.029L270.874 221.473Z" fill="#F6F4F4"/>
<path d="M308.315 220.886C308.684 223.841 306.406 226.461 303.429 226.506L281.795 226.83C279.237 226.869 277.063 224.97 276.756 222.431L274.166 200.986C273.81 198.038 276.086 195.431 279.055 195.387L300.596 195.062C303.146 195.024 305.317 196.911 305.633 199.441L308.315 220.886Z" fill="#F6F4F4"/>
<path d="M420.641 219.098C421.058 222.075 418.772 224.744 415.766 224.79L394.15 225.123C391.619 225.162 389.458 223.303 389.118 220.795L386.211 199.35C385.809 196.379 388.093 193.724 391.091 193.678L412.616 193.354C415.138 193.316 417.293 195.163 417.643 197.661L420.641 219.098Z" fill="#F6F4F4"/>
<path d="M90.0678 18.7397C88.1759 13.8718 90.6537 7.74734 95.5117 5.85632C100.382 3.9653 105.862 6.38092 107.754 11.2488C109.646 16.1166 107.229 21.5945 102.359 23.4855" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M120.241 18.2758C118.349 13.4079 120.815 7.28347 125.685 5.39245C130.555 3.50143 136.036 5.91706 137.928 10.7849C139.82 15.6528 137.403 21.1306 132.533 23.0216" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M150.427 17.8124C148.535 12.9446 151 6.82009 155.871 4.92907C160.741 3.03805 166.221 5.45368 168.113 10.3215C170.005 15.1894 167.588 20.6672 162.718 22.5583" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M180.612 17.3485C178.72 12.4807 181.186 6.35622 186.056 4.4652C190.926 2.57418 196.407 4.98981 198.299 9.85766C200.191 14.7255 197.774 20.2034 192.904 22.0944" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M210.798 16.8974C208.906 12.0295 211.372 5.90505 216.242 4.01403C221.112 2.12301 226.592 4.53864 228.484 9.40649C230.376 14.2743 227.959 19.7522 223.089 21.6432" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M240.983 16.4335C239.091 11.5657 241.557 5.44119 246.427 3.55016C251.297 1.65914 256.778 4.07477 258.67 8.94262C260.562 13.8105 258.145 19.2883 253.275 21.1794" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M271.156 15.9701C269.264 11.1023 271.73 4.97781 276.6 3.08679C281.47 1.19576 286.951 3.61139 288.843 8.47925C290.735 13.3471 288.318 18.825 283.448 20.716" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M301.342 15.5063C299.45 10.6384 301.915 4.51394 306.786 2.62292C311.656 0.731898 317.136 3.14752 319.028 8.01538C320.92 12.8832 318.503 18.3611 313.633 20.2521" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M331.527 15.0551C329.635 10.1872 332.101 4.06277 336.971 2.17175C341.841 0.280726 347.322 2.69635 349.214 7.56421C351.106 12.4321 348.689 17.9099 343.819 19.8009" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M361.713 14.5912C359.821 9.72337 362.287 3.5989 367.157 1.70788C372.027 -0.183141 377.507 2.23249 379.399 7.10034C381.291 11.9682 378.875 17.4461 374.004 19.3371" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M391.898 14.1278C390.006 9.25999 392.484 3.13552 397.342 1.2445C402.212 -0.64652 407.692 1.76911 409.584 6.63696C411.476 11.5048 409.06 16.9827 404.189 18.8737" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M94.9629 18.6542C93.0709 13.7864 95.5365 7.66189 100.407 5.77087C105.277 3.87985 110.757 6.29547 112.649 11.1633C114.541 16.0312 112.125 21.509 107.254 23.4001" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M125.148 18.2026C123.256 13.3347 125.722 7.21023 130.592 5.31921C135.462 3.42819 140.943 5.84381 142.835 10.7117C144.727 15.5795 142.31 21.0574 137.44 22.9484" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M155.334 17.7392C153.442 12.8713 155.908 6.74685 160.778 4.85583C165.648 2.96481 171.129 5.38043 173.02 10.2483C174.912 15.1161 172.496 20.594 167.625 22.485" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M185.52 17.2753C183.628 12.4075 186.093 6.28298 190.963 4.39196C195.834 2.50094 201.314 4.91657 203.206 9.78442C205.098 14.6523 202.681 20.1301 197.811 22.0212" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M215.692 16.8119C213.8 11.9441 216.266 5.8196 221.136 3.92858C226.006 2.03756 231.487 4.45319 233.379 9.32104C235.271 14.1889 232.854 19.6668 227.984 21.5578" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M245.878 16.3603C243.986 11.4924 246.452 5.36794 251.322 3.47692C256.192 1.5859 261.672 4.00153 263.564 8.86938C265.456 13.7372 263.04 19.2151 258.169 21.1061" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M276.063 15.8969C274.172 11.029 276.637 4.90456 281.507 3.01354C286.378 1.12252 291.858 3.53815 293.75 8.406C295.642 13.2739 293.225 18.7517 288.355 20.6427" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M306.249 15.433C304.357 10.5652 306.823 4.4407 311.693 2.54968C316.563 0.658655 322.044 3.07428 323.936 7.94214C325.827 12.81 323.411 18.2879 318.54 20.1789" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M336.435 14.9696C334.543 10.1018 337.008 3.97732 341.878 2.0863C346.749 0.195277 352.229 2.6109 354.121 7.47876C356.013 12.3466 353.596 17.8245 348.726 19.7155" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M366.608 14.518C364.716 9.65013 367.194 3.52566 372.052 1.63464C376.922 -0.256384 382.402 2.15924 384.294 7.0271C386.186 11.895 383.77 17.3728 378.899 19.2638" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M396.792 14.0546C394.901 9.18675 397.378 3.06228 402.236 1.17126C407.107 -0.719763 412.587 1.69586 414.479 6.56372C416.371 11.4316 413.954 16.9094 409.084 18.8005" stroke="#A0ACC5" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M105.533 248.968L29.7705 245.223L83.0619 18.9346L105.533 248.968Z" fill="#F6F4F4"/>
</g>
<defs>
<clipPath id="clip0_1603_8897">
<rect width="506" height="267" fill="white"/>
</clipPath>
</defs>
</svg>

);