import { useTranslation } from "react-i18next";
import {
    ConnectionStateModel,
    DeviceConnectionStatus,
    DisconnectionReason,
    WifiStrength,
} from "../models/device.model";
import { WifiFairIcon } from "./WifiFair";
import { WifiGoodIcon } from "./WifiGood";
import { WifiNoConnectionIcon } from "./WifiNoConnection";
import { WifiWeakIcon } from "./WifiWeak";

interface WifiStrengthIconProps {
    currentStrength: WifiStrength | undefined;
    connectionState: ConnectionStateModel | undefined;
    color?: string;
    height?: string;
    width?: string;
}

export const WifiStrengthIcon = ({
    currentStrength,
    connectionState,
    color = "#0F284C",
    height = "2rem",
    width = "2rem",
}: WifiStrengthIconProps) => {
    const { t } = useTranslation();

    const getWifiStrengthIcon = (currentStrength: WifiStrength | undefined) => {
        if (connectionState && connectionState.state) {
            if (
                connectionState.state === DeviceConnectionStatus.DISCONNECTED &&
                connectionState.disconnection_reason ===
                    DisconnectionReason.CLIENT_INITIATION
            ) {
                return (
                    <div style={{ color }} className="wifi-icon__off">
                        {t("Device off")}
                    </div>
                );
            }
            if (
                connectionState.state === DeviceConnectionStatus.DISCONNECTED &&
                (connectionState.disconnection_reason ===
                    DisconnectionReason.CONNECTION_ERROR ||
                    connectionState.disconnection_reason ===
                        DisconnectionReason.CONNECTION_LOST)
            ) {
                return (
                    <WifiNoConnectionIcon
                        height={height}
                        width={width}
                        color={color}
                    />
                );
            }

            switch (currentStrength) {
                case WifiStrength.GOOD:
                    return (
                        <WifiGoodIcon
                            height={height}
                            width={width}
                            color={color}
                        />
                    );
                case WifiStrength.FAIR:
                    return (
                        <WifiFairIcon
                            height={height}
                            width={width}
                            color={color}
                        />
                    );
                case WifiStrength.WEAK:
                    return (
                        <WifiWeakIcon
                            height={height}
                            width={width}
                            color={color}
                        />
                    );
                default:
                    return (
                        <WifiNoConnectionIcon
                            height={height}
                            width={width}
                            color={color}
                        />
                    );
            }
        } else {
            return <div></div>;
        }
    };

    return getWifiStrengthIcon(currentStrength);
};
