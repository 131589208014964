import { GeneralRecordingModel, NOT_PLAYING } from "../models/recording.model";

export const playRecordings = (
    links: any[] | undefined,
    index: number,
    setAudio: Function,
    setPlayingIndex: Function,
    togglePlaying: Function,
    playingIndex: number,
    setPlayingArray: Function,
    audio: HTMLAudioElement | null,
    playRecordings: Function,
    recordings: GeneralRecordingModel[],
) => {
    if (!links) {
        return;
    }
    if (index < links.length) {
        const newVoiceRecord: HTMLAudioElement = new Audio();
        newVoiceRecord.src = links[index];

        newVoiceRecord
            .play()
            .then()
            .catch((error) => {});

        newVoiceRecord.addEventListener("ended", () => {
            playRecordings(
                links,
                index + 1,
                setAudio,
                setPlayingIndex,
                togglePlaying,
                playingIndex,
                setPlayingArray,
                audio,
                playRecordings,
                recordings
            );
        });

        setAudio(newVoiceRecord);
    } else {
        setAudio(null);
        togglePlaying(
            index,
            setPlayingArray,
            audio,
            playRecordings,
            recordings,
            setAudio,
            setPlayingIndex,
            togglePlaying,
            playingIndex,
            true
        );
        setPlayingIndex(NOT_PLAYING);
    }
};

export const togglePlaying = (
    index: number,
    setPlayingArray: Function,
    audio: HTMLAudioElement | null,
    playRecordings: Function,
    recordings: GeneralRecordingModel[],
    setAudio: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>,
    setPlayingIndex: Function,
    togglePlaying: Function,
    playingIndex: number,
    isFinish?: boolean
) => {
    setPlayingArray((prev: any) => {
        const newState = [...prev];
        newState[playingIndex] = !prev[playingIndex];

        if (newState[playingIndex]) {
            audio?.pause();
            if (!isFinish) {
                playRecordings(
                    recordings[index]?.links,
                    0,
                    setAudio,
                    setPlayingIndex,
                    togglePlaying,
                    playingIndex,
                    setPlayingArray,
                    audio,
                    playRecordings,
                    recordings
                );
            }
        } else {
            audio?.pause();
            setAudio(null);
        }

        for (let i = 0; i < newState.length; i++) {
            if (i !== playingIndex) {
                newState[i] = false;
            }
        }
        return newState;
    });
};
