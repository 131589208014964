import React from "react";

export const SentIcon = ({
    height = "90",
    width = "135",
    color = "#5781A7",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 135 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M130.574 23.6623L59.5705 42.6877M35.8662 73.9045L22.1912 77.5687M29.8682 51.5197L2.71088 58.7965M23.8702 29.1349L10.1952 32.7991M42.6152 3.07399L126.661 18.8829C131.837 19.8564 133.57 26.3271 129.575 29.7581L64.6936 85.4718C60.2488 89.2886 53.4428 85.5764 54.3366 79.8229L59.708 45.2469C59.9763 43.5201 59.5057 41.764 58.41 40.4026L36.4702 13.1446C32.8194 8.60884 36.8575 1.99098 42.6152 3.07399Z" stroke={color} strokeWidth="5" strokeLinecap="round"/>
    </svg>
);