export enum GenericSubscriptionActions {
    CREATE = 'create',
    DELETE = 'delete',
    UPDATE = 'update'
};

export enum GenericSubscriptionResources {
    FAMILY_MESSAGE = 'family message',
    DEVICE = 'device',
    PROFILE_IMAGE = 'profile image'
};