import React from "react";

export const RoomIcon = ({
    height = "1.3rem",
    width = "1rem",
    color = "#0F284C",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg width={width} height={height} viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.5 1C1.22386 1 1 1.22386 1 1.5V10.5C1 10.7761 0.776142 11 0.5 11C0.223858 11 0 10.7761 0 10.5V1.5C0 0.671573 0.671573 0 1.5 0H8.5C9.32843 0 10 0.671573 10 1.5V10.5C10 10.7761 9.77614 11 9.5 11C9.22386 11 9 10.7761 9 10.5V1.5C9 1.22386 8.77614 1 8.5 1H1.5ZM0.5 12C0.5 11.7239 0.723858 11.5 1 11.5L9 11.5C9.27614 11.5 9.5 11.7239 9.5 12C9.5 12.2761 9.27614 12.5 9 12.5L1 12.5C0.723858 12.5 0.5 12.2761 0.5 12ZM5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6ZM6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z" fill={color}/>
</svg>
);
