import React from "react";

export const CameraIcon = ({
    height = "1.7rem",
    width = "2rem",
    color = "#0F284C",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="1"
            y="4"
            width="18"
            height="12"
            rx="1"
            stroke={color}
            strokeWidth="2"
        />
        <path
            d="M9.65903 1.80388C9.75251 1.33646 10.1629 1 10.6396 1H15.3604C15.8371 1 16.2475 1.33646 16.341 1.80388L16.541 2.80388C16.6647 3.42268 16.1914 4 15.5604 4H10.4396C9.80856 4 9.33527 3.42268 9.45903 2.80388L9.65903 1.80388Z"
            stroke={color}
            strokeWidth="2"
        />
        <rect
            width="5"
            height="3"
            rx="1"
            transform="matrix(1 0 0 -1 2 4)"
            fill={color}
        />
        <circle cx="10" cy="10" r="3" stroke={color} strokeWidth="2" />
    </svg>
);
