import { IcuAction } from 'src/actions/icu.action';
import { IcuModel } from 'src/models/icu.model';
import { IcuActionTypes } from '../actions/types';

export function icuReducer(state: IcuModel[] = [], action: IcuAction): IcuModel[] {
    switch (action.type) {
        case IcuActionTypes.FETCH_ICUS:
            return [...action.payload];
        case IcuActionTypes.SET_ICU_SETTINGS:
            return state.map( icu => {
                if (icu._id ===  action.payload.icuId) {
                    icu.settings = {
                        ...icu.settings,
                        ...action.payload.icuSettings
                    }
                }
                return icu;
            });
        default:
            return state;
    }
}