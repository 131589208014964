import { Amplify, PubSub, Auth , Hub, Logger, AWSCloudWatchProvider, Storage} from 'aws-amplify';
import { AWSIoTProvider, CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { ThunkDispatch } from 'redux-thunk';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import { logoutUser } from 'src/actions';
import { StoreState } from 'src/reducers';
import { getCurrentUser } from 'src/services/localStorageService'

const STORAGE_LOG_NAME = 'amplifyLog';

class AmplifyService {
    private static _instance: AmplifyService;
    private static clientAppLogger: Logger;
    private logUserGroupHasEmail: boolean;

    private constructor() {
        const timestamp = Date.now();
        const logGroupName = 'med-client-app';
        const { email } = getCurrentUser() || {};
        const logStreamName = `${logGroupName}-${email ? `${email}-` : ''}${timestamp}`;

        this.logUserGroupHasEmail = !!email;

        Amplify.configure({
            Auth: {
                identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
                region: process.env.REACT_APP_REGION,
                userPoolId: process.env.REACT_APP_USER_POOL_ID,
                userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
                oauth: {
                    domain: `${process.env.REACT_APP_SUB_DOMAIN}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com`,
                    scope:  ["email", "profile", "openid"],
                    redirectSignIn: `${process.env.REACT_APP_OVERVIEW_PAGE}`,
                    redirectSignOut: `${process.env.REACT_APP_LOGIN_PAGE}`,
                    responseType: "code"
                }
            },
            Logging: {
                logGroupName,
                logStreamName,
                region: process.env.REACT_APP_REGION
            },
            Storage: {
                AWSS3: {
                    bucket: process.env.REACT_APP_MED_STORAGE_BUCKET,
                    region: process.env.REACT_APP_REGION
                }
            }
        });

        Amplify.addPluggable(new AWSIoTProvider({
            aws_pubsub_region: process.env.REACT_APP_REGION,
            aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
        }));

        AmplifyService.clientAppLogger = new Logger('clientAppLogger', 'DEBUG');
        Amplify.register(AmplifyService.clientAppLogger);
        AmplifyService.clientAppLogger.addPluggable(new AWSCloudWatchProvider());

        //Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
        Amplify.Logger.LOG_LEVEL = "DEBUG"
    }

    public log(log:any) {
        return AmplifyService.clientAppLogger.debug(JSON.stringify(log));
    }

    public get getAmplify() {
        return Amplify;
    }

    public get getPubSub() {
        return PubSub;
    }

    public get getStorage() {
        return Storage;
    }

    public get getAuth() {
        return Auth;
    }

    public get getHub() {
        return Hub;
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public connectivityCheck = (navigate: NavigateFunction, dispatch: ThunkDispatch<StoreState, void, Action>) => {
        Hub.listen('pubsub', (data: any) => {
            const { payload } = data;
            if (payload.event === CONNECTION_STATE_CHANGE) {
              const connectionState = payload.data.connectionState as ConnectionState;
              this.log({date: new Date() , connectionState});
            }
        });

        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'tokenRefresh': {
                    this.log("New token has been process");
                    break;
                }
                case 'tokenRefresh_failure': {
                    this.log("Failed to retrieve new token. navigate to login page");
                    dispatch(logoutUser(() => {
                        navigate(`/login`);
                        window.location.reload(); // hack to achieve Amplify logStreamName has email in it
                    }))
                    break;
                }
            }
        });
    }

    public getLogFromStorage = (): string[] => {
        const log = localStorage.getItem(STORAGE_LOG_NAME);
        return log ? JSON.parse(log) : [];
    };

    public addItemToLogStorage = (item: string) => {
        const log = this.getLogFromStorage();
        log.push(item);
        localStorage.setItem(STORAGE_LOG_NAME, JSON.stringify(log));
    };

    public clearLogStorage = () => {
        localStorage.removeItem(STORAGE_LOG_NAME);
    };

    public triggerStoredLogs() {
        if (this.logUserGroupHasEmail) {
            const log = this.getLogFromStorage();

            for (const message of log) {
                this.log(message)
            }

            this.clearLogStorage();
        }
    }
}
 
export default AmplifyService.Instance;