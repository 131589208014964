import React, { useEffect, useImperativeHandle, useRef } from "react";
import { ForwardRefExoticComponent, RefAttributes, RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { Rnd } from "react-rnd";
import { DeviceModel } from "../../models/device.model";
import { CameraIcon, CloseBigIcon } from "../../icons";
import { ProgressSpinner } from 'primereact/progressspinner';
import WebRtcService from '../../services/webRtc.service';
import { store } from "../../Root";
import { useMixpanel } from "src/hooks/useMixpanel";

export type RefType = RefObject<
    ForwardRefExoticComponent<
        VideoStreamBoxProps & RefAttributes<HTMLButtonElement>
    >
>;

interface VideoStreamBoxProps {
    deviceId: string | undefined;
    tenantId: string | undefined;
    updateStreamStatus: Function;
    visibilityFlag?: boolean;
}


export const VideoStreamBox = React.forwardRef<RefType, VideoStreamBoxProps>(
    (
        {
            deviceId,
            tenantId,
            updateStreamStatus,
            visibilityFlag=false
        }: VideoStreamBoxProps,
        ref
    ): JSX.Element => {
        const { t } = useTranslation();
        const [isVisable, setIsVisbale] = useState<boolean>(visibilityFlag);
        const [isStream, setIsStream] = useState<boolean>(false);
        const [webRtcService] = useState<WebRtcService>(new WebRtcService());
        const [isBusy, setIsBusy] = useState<boolean>(false);
        const { trackOpenVideoStream, trackCloseVideoStream } = useMixpanel()

        const streamWindow = useRef<HTMLVideoElement>(null);

        useEffect(() => {
            return () => {
                webRtcService.unsubscribe();
                webRtcService.closeConnections();
            };
        }, []);

        const show = () => {
            const devices = store.getState()?.deviceObject.devices;
            const device: DeviceModel | undefined = devices?.find(device => device.id === deviceId)
            trackOpenVideoStream();

            if (deviceId && tenantId && device) {
                setIsVisbale(true);
                updateStreamStatus(true);
                webRtcService.createConnection(deviceId, device.icu_id, tenantId, streamWindow, setIsStream, setIsBusy);
            }
        };

        const hide = () => {
            if (isVisable) {
                setIsVisbale(false);
                setIsStream(false);
                updateStreamStatus(false);
                setIsBusy(false);
                webRtcService.closeConnections();
                webRtcService.unsubscribe();
                trackCloseVideoStream();
            }
        };

        useImperativeHandle(ref, (): any => ({
            show() {
                show();
            },
            hide() {
                hide();
            },
        }));

        return isVisable ? (
            <Rnd
                default={{
                    x: 100,
                    y: 100,
                    width: 405,
                    height: 355,
                }}
                minWidth="405px"
                minHeight="355px"
                lockAspectRatio={true}
                bounds=".main"
                className="video-stream-box"
                cancel=".video-stream-box__close-resize"
            >
                <div className="video-stream-box__content">
                    <div className="video-stream-box__top">
                        <div className="video-stream-box__title-wrapper">
                            <div className="video-stream-box__icon">
                                <CameraIcon />
                            </div>
                            <div className="video-stream-box__title">
                                {t("Camera view")}
                            </div>
                        </div>
                        <div
                            className="video-stream-box__close-resize"
                            onClick={() => {
                                hide();
                            }}
                        >
                            <CloseBigIcon />
                        </div>
                    </div>
                    <div className="video-stream-box__main">
                        {isStream ? (
                            <div className="video">
                                <video
                                    controls
                                    ref={streamWindow}
                                    autoPlay
                                    style={{ width: "100%", height: "100%", pointerEvents: "none" }}
                                />
                            </div>
                        ) : (
                            <div className="video-stream-box__loading">
                                {!isBusy ? (
                                    <ProgressSpinner />
                                ) : (
                                    "The stream is busy"
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Rnd>
        ) : (
            <></>
        );
    }
);
