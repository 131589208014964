import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateSettingsField } from "../../../actions/device.action";
import { DeviceModel } from "../../../models/device.model";
import { VoiceModel } from "src/models/voice.model";
import { sortAndModifyVoicesToUpperCase } from "src/utils/generalMethods";

interface DeviceLanguageProps {
    voices?: VoiceModel[];
    devices?: DeviceModel[];
}

const mapStateToProps = ({ tenant, deviceObject }: StoreState) => {
    return {
        voices: tenant.voices,
        devices: deviceObject.devices,
    };
};

const _DeviceLanguage= ({
    voices,
    devices,
}: DeviceLanguageProps): JSX.Element => {
    const { t } = useTranslation();
    let params = useParams();

    let [patientVoice, setPatientVoice] = useState<number>();
    let [isCurrentValueFromShadow, setIsCurrentValueFromShadow] = useState<boolean>(false);
    let [isDropdownsDisabled, setIsDropdownsDisabled] = useState<boolean>(true);
    let deviceId = params.deviceId ? params.deviceId : "";

    const onPatientLanguageChange = (e: { value: any }) => {
        setPatientVoice(e.value);
        updateSettingsField(deviceId, "patient_voice_id", { patient_voice_id: e.value });
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);

        if (currentDevice && (!isCurrentValueFromShadow || currentDevice.isReset)) {
            setPatientVoice(currentDevice?.shadow?.settings?.patient_voice_id);
            setIsCurrentValueFromShadow(true);
            setIsDropdownsDisabled(false);
        }
    }, [devices]);

    return (
        <div className="card-container">
            <div className="card-container__title">{t("communication")}</div>
            <div className="card-container__language">{t("Patient Language")}</div>
            <div className="card-container__patient-language">
                <div className="card-container__dropdown-title">
                    {t("Set the desired language in which the communication shall be played to the patient")}
                </div>
                <Dropdown disabled={isDropdownsDisabled}
                    optionLabel="language.name"
                    optionValue="id"
                    className="card-container__dropdown"
                    options={sortAndModifyVoicesToUpperCase(voices)}
                    onChange={onPatientLanguageChange}
                    value={patientVoice}
                    placeholder={t("select")}
                    panelClassName="card-container__dropdown-panel"
                />
            </div>
        </div>
    );
};

export const DeviceLanguage = connect(
    mapStateToProps,
    null
)(_DeviceLanguage);
