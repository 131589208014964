import React from "react";

export const TechnicalErrorIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3060_35467)">
    <path d="M4.99262 4.86299C5.28551 4.57009 5.76039 4.57009 6.05328 4.86299L7.11394 5.92365C7.40683 6.21654 7.40683 6.69141 7.11394 6.98431L2.55208 11.5462C2.25919 11.8391 1.78431 11.8391 1.49142 11.5462L0.43076 10.4855C0.137866 10.1926 0.137867 9.71774 0.43076 9.42485L4.99262 4.86299Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.01482 0.84099C7.66913 0.313657 6.08016 0.593664 4.99282 1.68101C3.52835 3.14548 3.52835 5.51984 4.99282 6.98431C6.45728 8.44878 8.83165 8.44878 10.2961 6.98431C11.3835 5.89696 11.6635 4.308 11.1361 2.96231L8.18694 5.91151L6.59798 5.37915L6.06562 3.79018L9.01482 0.84099Z" fill={color}/>
    <path d="M7.50526 4.72059C7.66959 4.42647 8.08041 4.42647 8.24474 4.72059L11.9422 11.3382C12.1065 11.6324 11.9011 12 11.5724 12H4.17758C3.84892 12 3.64351 11.6324 3.80784 11.3382L7.50526 4.72059Z" fill="#D23A3A"/>
    <path d="M7.5 8.25H8.25V10.125V10.5V11.25H7.5V10.5V10.125V8.25Z" fill="white"/>
    <rect width="0.75" height="0.75" rx="0.375" transform="matrix(1 0 0 -1 7.5 7.5)" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_3060_35467">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);