import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useLocation,
  Location
} from "react-router-dom";
import { setActiveTab } from "src/utils/generalMethods";
import { TabModel } from "../../models/tab.model";
import { SideBar } from "../general/SideBar";

const NESTED_ROUTING_INDEX = 2;

export const SettingsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const sideTabs: TabModel[] = [
    {
      label: t("ICU settings"),
      link: "icu",
      pageName: "IcuSettings"
    },
    {
      label: t("Devices"),
      link: "devices",
      pageName: "Devices"
    },
  ];

  useEffect(() => {
    setActiveTab(
      NESTED_ROUTING_INDEX,
      activeIndex,
      sideTabs,
      setActiveIndex,
      location
    );
  }, []);

  let onSideBarChange = (index: number) => {
    navigate(sideTabs[index].link);
    setActiveIndex(index);
  };

  return (
    <div className="settings-page">
      <div className="settings-page__title-wrapper">
        <h2 className="settings-page__header">{t("Settings")}</h2>
      </div>
      <div className="settings-page__body">
        <div className="settings-page__side-bar">
          <SideBar
            items={sideTabs}
            onChange={(index: number) => onSideBarChange(index)}
            activeIndex={activeIndex}
          />
        </div>
        <div className="settings-page__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
