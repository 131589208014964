import React, { useState, ReactNode, useEffect, ReactElement, JSXElementConstructor } from "react";
import { ArrowDownIcon } from "../../icons";
import { ResourcePermissionModel, ResourceAction } from "../../models/Authorization.model";
import { checkResourcePermission } from "src/utils/generalMethods";

interface CustomAccordionItemProps {
    headerStartElement: any;
    headerEndElement?: any;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    isTabOpen?: boolean;
    authorizationResource?: ResourcePermissionModel;
}

const CustomAccordionItem: React.FC<CustomAccordionItemProps> = ({
    headerStartElement,
    headerEndElement,
    children,
    isTabOpen,
    authorizationResource
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isTabOpen) {
            setIsOpen(isTabOpen);
        }
    }, []);

    if (authorizationResource && !checkResourcePermission(authorizationResource, ResourceAction.READ)) {
        return null;
    }

    return (
        <div className="custom-accordion__item">
            <div
                className="custom-accordion__item-header"
                onClick={toggleAccordion}
            >
                <div className="custom-accordion__item-header--start">
                    {headerStartElement}
                </div>
                <div className="custom-accordion__item-header--end">
                    {headerEndElement}
                    <div className="custom-accordion__item-icon">
                        {isOpen ? (
                            <div className="recordings-list__icon-wrapper recordings-list__icon-wrapper--inverted">
                                <ArrowDownIcon />
                            </div>
                        ) : (
                            <div className="recordings-list__icon-wrapper">
                                <ArrowDownIcon />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="custom-accordion__content">{React.cloneElement(children, { authorizationResource })}</div>
            )}
        </div>
    );
};

interface CustomAccordionProps {
    children: ReactNode;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ children }) => {
    return <div className="custom-accordion">{children}</div>;
};

export { CustomAccordion, CustomAccordionItem };
