import React from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { DialogTypes } from './general/CustomDialog';
import { DialogContext } from 'src/contexts/DialogContext';
import { DialogPropTypes } from 'src/contexts/types';
import { useMixpanel } from 'src/hooks/useMixpanel';

interface ShareLinkProps {
}

export const ShareLink: React.FC<ShareLinkProps> = () => {
  const { t } = useTranslation();
  const { showDialog, hide } = React.useContext(DialogContext) as DialogPropTypes ;
  const { trackClickGetLinkButton } = useMixpanel();

  const onGetALinkClick = () => {
    trackClickGetLinkButton();
    showDialog({
      dialogType: DialogTypes.LINK_DIALOG,
      component: null,
      actionCallback: sendLink
    });
  };

  const sendLink = () => {
    hide();
  };

  return (
    <div className="share-link">
      <div className="share-link__content">
        <div className="share-link__text">
          <div className="share-link__header">{t('Family & Friends')}</div>
          <p>{t('Send personal messages and support your loved one\'s recovery.')}</p>
        </div>
          <Button label={t('Get a Link')} onClick={onGetALinkClick} className="button button-regular" />
      </div>
    </div>
  );
};