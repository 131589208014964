import { useTranslation } from "react-i18next";
import { DeviceConnectionStatus, DeviceModel } from "../../models/device.model";

interface DeviceInformationBarProps {
    device: DeviceModel | undefined;
}

export const DeviceInformationBar = ({
    device,
}: DeviceInformationBarProps): JSX.Element => {
    const { t } = useTranslation();
    const deviceState = device?.shadow?.state?.presence?.state;
    
    return (
        <div className="device-information-bar">
            <div className="device-information-bar__serial-wrapper">
                <div className="device-information-bar__serial">
                    {device?.serial}
                </div>
            </div>
            <div className="device-information-bar__info-box">
                <div className="device-information-bar__general-info">
                    <div className="device-information-bar__connection-status-wrapper">
                        <div className="device-information-bar__connection-status">
                            {deviceState === DeviceConnectionStatus.CONNECTED ? t("Connected") : t("Disconnected")}
                        </div>
                        <div className="device-information-bar__connection-status-text device-information-bar__text-font">
                            {t("Device Status")}
                        </div>
                    </div>
                    <div className="device-information-bar__patient-wrapper">
                        <div className="device-information-bar__patient-name">
                            {`${
                                device?.patient?.first_name
                                    ? device?.patient.first_name
                                    : ""
                            } ${
                                device?.patient?.last_name
                                    ? device?.patient.last_name
                                    : ""
                            }`}
                        </div>
                        <div className="device-information-bar__patient-text device-information-bar__text-font">
                            {t("Patient Name")}
                        </div>
                    </div>
                    <div className="device-information-bar__room">
                        <div className="device-information-bar__room-number">
                            {device?.patient?.room_number}
                        </div>
                        <div className="device-information-bar__room-text device-information-bar__text-font">
                            {t("Room")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
