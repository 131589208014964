import React from "react";

export const LanguageIcon = ({
  height = "24px",
  width = "24px",
  color = "#0F284C",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.5 10C27.3 9.3 27 8.6 26.7 8C24.6 3.7 20.5 0.7 15.7 0.0999999L15.2 0C14.8 0 14.5 0 14.1 0C13.7 0 13.4 0 13 0L12.4 0.0999999C7.6 0.6 3.5 3.6 1.4 8C1.1 8.6 0.8 9.3 0.6 10C-0.2 12.6 -0.2 15.4 0.6 18C0.8 18.7 1.1 19.4 1.4 20C3.5 24.3 7.6 27.3 12.4 27.9L13 28C13.4 28 13.7 28 14.1 28C14.5 28 14.8 28 15.2 28L15.8 27.9C20.6 27.3 24.7 24.3 26.8 20C27.1 19.4 27.4 18.7 27.6 18C28.3 15.3 28.3 12.6 27.5 10ZM24.5 8H19.3C18.8 6 18 4.1 16.9 2.3C20.1 3.1 22.8 5.1 24.5 8ZM18.1 14C18.1 15.3 18 16.7 17.7 18H10.4C9.9 15.4 9.9 12.6 10.4 10H17.7C18 11.3 18.1 12.7 18.1 14ZM17.3 20C16.7 22.2 15.7 24.2 14.3 26H14C12.6 24.2 11.6 22.2 11 20H17.3ZM10.9 8C11.5 5.8 12.5 3.8 13.9 2H14.2C15.6 3.8 16.6 5.8 17.2 8H10.9ZM11.3 2.3C10.2 4.1 9.4 6 8.9 8H3.7C5.4 5.1 8.1 3.1 11.3 2.3ZM2.1 14C2.1 12.6 2.3 11.3 2.8 10H8.5C8 12.6 8 15.4 8.5 18H2.8C2.3 16.7 2.1 15.4 2.1 14ZM3.7 20H8.9C9.4 22 10.2 23.9 11.3 25.7C8.1 24.9 5.4 22.9 3.7 20ZM16.9 25.7C18 23.9 18.8 22 19.3 20H24.5C22.8 22.9 20.1 24.9 16.9 25.7ZM19.8 18C20 16.7 20.1 15.3 20.1 14C20.1 12.7 20 11.3 19.8 10H25.4C26.3 12.6 26.3 15.4 25.4 18H19.8Z" fill="#6C7C92"/>
</svg>
);