import { useRef } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { StoreState } from "src/reducers";
import { DialogContext } from "src/contexts/DialogContext";
import { Navbar } from "src/components/general/Navbar";
import { Main } from "src/components/general/Main";
import { CustomDialog, OpenDialogArgs } from "src/components/general/CustomDialog";
import { DeviceList } from "src/components/general/DeviceList";
import DeviceSettings from "src/components/general/DeviceSettings";
import IcuSettings from "src/components/general/IcuSettings";
import { SettingsPage } from "src/components/pages/SettingsPage";
import { OverviewPage } from "src/components/pages/OverviewPage";
import { PatientViewPage } from "src/components/pages/PatientViewPage";
import { DevicesSettings } from "src/components/pages/settings/DevicesSettings";
import { NotFoundPage } from "src/components/pages/NotFoundPage";
import { NearBedViewPage } from "src/components/pages/NearBedViewPage";
import { Login } from "src/components/authentication/Login";
import { PatientInformationScreen } from "src/components/patientView/PatientInformationScreen";
import { AuthorizationPageWrapper } from "src/components/authentication/AuthorizationPageWrapper";
import "src/scss/components/App.scss";

interface AppProps {
    isAuthenticated?: boolean;
}

const mapStateToProps = ({ authInfo }: StoreState) => {
    return {
        isAuthenticated: !!authInfo?.currentUser?.token
    };
};

const _App = ({ isAuthenticated }: AppProps): JSX.Element => {
    // TODO: change in the future the type to be specific.
    const dialogRef = useRef<any>(null);
    const attentionDialogRef = useRef<any>(null);

    return (
        // TODO: Add route gaurd
        <div className="container">
            <DialogContext.Provider
                value={{
                    showDialog: (args: OpenDialogArgs) => {
                        dialogRef?.current?.showDialog(args);
                    },
                    hide: () => dialogRef?.current?.hide(),
                    resetTimeOut: () => dialogRef?.current?.resetTimeOut(),
                    updateArguments: (args) => dialogRef?.current?.updateArguments(args),
                    showAttentionDialog: (args: OpenDialogArgs) => {
                        attentionDialogRef?.current?.showDialog(args);
                    },
                    hideAttentionDialog: () => attentionDialogRef?.current?.hide(),
                    resetAttentionTimeOut: () => attentionDialogRef?.current?.resetTimeOut(),
                    updateAttentionArguments: (args) => attentionDialogRef?.current?.updateArguments(args)
                }}
            >
                <BrowserRouter>
                    {isAuthenticated && <Navbar />}
                    <Main>
                        <Routes>
                            <Route index element={<Navigate to={isAuthenticated ? "/overview" : "/login"} replace />}/>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={isAuthenticated ? <Outlet /> : <Navigate to="/login" />}>
                                <Route path="overview" element={<OverviewPage />} />
                                <Route
                                    path="patientview"
                                    element={
                                        <AuthorizationPageWrapper pageName="PatientView">
                                            <PatientViewPage />
                                        </AuthorizationPageWrapper>
                                    }
                                >
                                    <Route path=":deviceId" element={<PatientInformationScreen />} />
                                </Route>
                                <Route
                                    path="neartobedpatientview"
                                    element={
                                        <AuthorizationPageWrapper pageName="NearToBedPatientView">
                                            <NearBedViewPage />
                                        </AuthorizationPageWrapper>
                                    }
                                >
                                    <Route path=":deviceId" element={<PatientInformationScreen />} />
                                </Route>
                                <Route path="settings" element={<SettingsPage />}>
                                    <Route index element={<Navigate replace to="icu" />} />
                                    <Route path="icu" element={<IcuSettings />} />
                                    <Route path="devices" element={<DevicesSettings/>}>
                                        <Route path="" element={<DeviceList />} />
                                        <Route path=":deviceId" element={ <DeviceSettings />} />
                                    </Route>
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Main>
                    <CustomDialog ref={dialogRef} />
                    <CustomDialog ref={attentionDialogRef} />
                </BrowserRouter>
            </DialogContext.Provider>
        </div>
    );
};

export const App = connect(mapStateToProps, null)(_App);
