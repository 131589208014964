import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { setIcuSettings} from "../../../actions/icu.action";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { useTranslation } from "react-i18next";
import { VoiceModel } from "src/models/voice.model";
import { sortAndModifyVoicesToUpperCase } from "src/utils/generalMethods";

interface IcuLanguageProps {
  patientVoiceId?: number;
  medicalStaffVoiceId?: number;
  voices?: VoiceModel[];
  setSettings: Function;
  icuId: string;
}

const mapStateToProps = ({ icus, tenant }: StoreState) => {
  let patientVoiceId;
  let medicalStaffVoiceId;
  // TODO: using a solution for multi icus.
  let icuId: string = "";
  const voices = tenant.voices;

  // TODO: write code that support more then one icu.

  if (icus.length > 0) {
    const patientVoice = voices.find(voice => voice.id === icus[0].settings.patient_voice_id)
    patientVoiceId = patientVoice?.id;

    const medicalStaffVoice = voices.find(voice => voice.id === icus[0].settings.medical_staff_voice_id)
    medicalStaffVoiceId = medicalStaffVoice?.id;

    icuId = icus[0]._id;
  }
  return {
    patientVoiceId,
    medicalStaffVoiceId,
    voices,
    icuId
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, Action>
) => {
  return {
    setSettings: (settings: any, icuId: string) => {
      dispatch(setIcuSettings(settings, icuId));
    }
  };
};

const _IcuLanguage = ({
    patientVoiceId,
    medicalStaffVoiceId,
    voices,
    setSettings,
    icuId,
}: IcuLanguageProps): JSX.Element => {
    const { t } = useTranslation();
    let [patientVoice, setPatientVoice] = useState<number>();
    let [medicalStaffVoice, setmedicalStaffVoiceId] = useState<number>();

    useEffect(() => {
        setPatientVoice(patientVoiceId);
    }, [patientVoiceId]);

    useEffect(() => {
        setmedicalStaffVoiceId(medicalStaffVoiceId);
    }, [medicalStaffVoiceId]);

    const onPatientLanguageChange = (e: { value: number }) => {
        setPatientVoice(e.value);
        setSettings({ patient_voice_id: e.value }, icuId);
    };

    const onMedicalStaffLanguageChange = (e: { value: any }) => {
        setmedicalStaffVoiceId(e.value);
        setSettings({ medical_staff_voice_id: e.value }, icuId);
    };

    return (
        <div className="card-container">
            <div className="card-container__title">{t("communication")}</div>
            <div className="card-container__language">{t("Languages")}</div>
            <div className="card-container__patient-language">
                <div className="card-container__dropdown-title">
                    {t(
                        "Set the desired language in which the communication shall be played to the medical staff"
                    )}
                </div>
                <Dropdown
                    optionLabel="language.name"
                    optionValue="id"
                    className="card-container__dropdown"
                    options={sortAndModifyVoicesToUpperCase(voices)}
                    onChange={onMedicalStaffLanguageChange}
                    value={medicalStaffVoice}
                    placeholder={t("select")}
                    panelClassName="card-container__dropdown-panel"
                />
            </div>
        </div>
    );
};

export const IcuLanguage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_IcuLanguage);
