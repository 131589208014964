import { axios } from "./apiMiddleware";
import { BASE_URL } from '../globals';
import { AxiosInstance } from "axios";

let VOICE_URL: string = `${BASE_URL}/questions/`;

const newAxios: AxiosInstance = axios.create({
    baseURL: VOICE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

newAxios.interceptors.request = axios.interceptors.request;
newAxios.interceptors.response = axios.interceptors.response;

export default newAxios;
