import PasswordInput from "./PasswordInput";
import TextInput from "./TextInput";

interface MedInputProps {
    input: { value: number; onChange: Function };
    label?: string;
    placeholder: string;
    meta?: any;
    type: string
}

export const MedInput = ({
    input: { value, onChange },
    label,
    placeholder,
    meta: { touched, error, warning },
    type
}: MedInputProps) => {
    const isTypePassword = type === 'password'
    const inputProps = { placeholder, value, onChange, touched, error }

    return (
        <div className="med-input">
            {label ? <label className="med-input__label">{label}</label> : null}
            {isTypePassword ? <PasswordInput {...inputProps} /> : <TextInput {...inputProps} type={type} />}
            <div className="med-dropdown__error input-error">
                <div className="med-dropdown__error-text input-error__text">
                    {touched &&
                        ((error && <span>{error}</span>) ||
                            (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
