import { Dispatch } from "redux";
import questionApi from "../apis/question";
import { QuestionActionTypes } from "./types";
import { QuestionModel } from "../models/question.model";

export interface FetchCurrentQuestionsAction {
    type: QuestionActionTypes.FETCH_CURRENT_QUESTIONS;
    payload: QuestionModel[];
}

export type QuestionAction = FetchCurrentQuestionsAction;

export const fetchCurrentQuestions = () => {
    return async (dispatch: Dispatch) => {
        const response = await questionApi.get("/allCurrent", {});

        if (response.status === 200) {
            setCurrentQuestions(dispatch, response.data);
        }
    };
};

const setCurrentQuestions = (dispatch: Dispatch, questions: QuestionModel[]) => {
    dispatch<QuestionAction>({
        type: QuestionActionTypes.FETCH_CURRENT_QUESTIONS,
        payload: questions,
    });
}
