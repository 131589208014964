import React from "react";

interface SvgProps {
    onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    className?: string
}

export const EyeOpenIcon = ({
    height = "1rem",
    width = "1.5rem",
    color = "#0F284C",
    onClick
}: React.SVGProps<SVGSVGElement>) => {
    const svgProps: SvgProps = {}

    if (!!onClick) {
        svgProps.onClick = onClick
        svgProps.className = 'cursor-pointer'
    }

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
        >
            <path
                d="M7.5 9C10.9378 9 15 6.43967 15 4.5C15 2.56036 10.9378 0 7.5 0C4.06217 0 0 2.56045 0 4.5C0 6.43964 4.06217 9 7.5 9ZM7.5 7.99064C4.59947 7.99064 1.09755 5.78335 1.09755 4.5C1.09755 3.21656 4.59934 1.00936 7.5 1.00936C10.4007 1.00936 13.9025 3.21665 13.9025 4.5C13.9025 5.78333 10.4007 7.99064 7.5 7.99064Z"
                fill={color}
            />
            <path
                d="M8 4.5C8 4.77614 7.77614 5 7.5 5C7.22386 5 7 4.77614 7 4.5C7 4.22386 7.22386 4 7.5 4C7.77614 4 8 4.22386 8 4.5Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 3C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6C8.32843 6 9 5.32843 9 4.5C9 3.67157 8.32843 3 7.5 3ZM10 4.5C10 5.88071 8.88071 7 7.5 7C6.11929 7 5 5.88071 5 4.5C5 3.11929 6.11929 2 7.5 2C8.88071 2 10 3.11929 10 4.5Z"
                fill={color}
            />
        </svg>
    );
} 
