import React from "react";

interface NearBedSchedulerViewInformationProps {
    time: string | undefined
    text: string | undefined
    duration?: string
}

const NearBedSchedulerViewInformation = ({time, text, duration}: NearBedSchedulerViewInformationProps): JSX.Element => (
    <div className="near-bed-scheduler-view__information">
        <div className="near-bed-scheduler-view__date">
            {time}
        </div>
        <div className="near-bed-scheduler-view__text">
            {text} {!!duration ? <span className="near-bed-scheduler-view__duration">&#xB7; {duration}</span> : null}
        </div>
    </div>
)


export default NearBedSchedulerViewInformation