import { Calendar, CalendarProps } from 'primereact/calendar';

interface MedCalendarProps extends CalendarProps{
    input: { value: any; onChange: Function };
    label?: string;
    meta?: any;
    type: string;
}

export const MedCalendar = ({
    input: { value, onChange },
    label,
    meta: { touched, error, warning },
    type,
    ...calendarProps
}: MedCalendarProps) => {
    return (
        <div className={`med-calendar ${ type ? "med-calendar--" + type : ""}`}>
            <label style={{visibility: label ? 'visible' : 'hidden'}}
                   className="med-calendar__label">{label ? label : "label"}</label>
            <Calendar
                value={value}
                onChange={(e: any) => {
                    onChange(e.value);
                    e.preventDefault();
                }}
                {...calendarProps}
            />
            <div className="med-dropdown__error input-error">
                <div className="med-dropdown__error-text input-error__text">
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
