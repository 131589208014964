import { useTranslation } from "react-i18next";
import TimeService from "../../services/time.service"
import { CommunicationIcon } from "../../icons";
import { ActiveCommunicationType, CommunicationLogModel } from "../../models/device.model";
import Moment from 'react-moment';
import 'moment-timezone';
import { MessageType } from "../../models/log.model";

interface CommunicationLogProps {
    communicationLogs: CommunicationLogModel[] | undefined;
    timeZone: string | undefined;
}

export const CommunicationLog = ({
    communicationLogs,
    timeZone
}: CommunicationLogProps): JSX.Element => {
    const { t } = useTranslation();

    const removeSentStopCommunicationLog = (
        communicationLog: CommunicationLogModel
    ) => {
        return !(
            communicationLog.communication_type_id ===
                ActiveCommunicationType.STOP_COMMUNICATION &&
            communicationLog.message_type_id === MessageType.SENT_COMMUNICATION
        );
    };

    if (!communicationLogs) return <div>Loading...</div>;

    return (
        <div className="communication-log">
            <div className="communication-log__header">
                {t("Communication Log")}
            </div>
            <ul className="communication-log__items">
                {TimeService.sortArrayByDate(communicationLogs)
                    ?.filter(removeSentStopCommunicationLog)
                    .map(
                        (
                            communicationLog: CommunicationLogModel,
                            index: number
                        ) => {
                            return (
                                <li
                                    key={index}
                                    className="communication-log__item"
                                >
                                    <div className="communication-log__icon">
                                        <CommunicationIcon
                                            communicatoinType={
                                                communicationLog.communication_type_id
                                            }
                                        />
                                    </div>
                                    <div
                                        className={`communication-log__line communication-log__line--${communicationLog.communication_type_id}`}
                                    ></div>
                                    <div className="communication-log__information-wrapper">
                                        <div className="communication-log__information">
                                            <div className="communication-log__date">
                                                <Moment
                                                    tz={timeZone}
                                                    format="MMM Do, YYYY HH:mm:ss"
                                                >
                                                    {communicationLog.time}
                                                </Moment>
                                            </div>
                                            <div className="communication-log__text">
                                                {communicationLog.text}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                    )}
            </ul>
        </div>
    );
};
