import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { DeviceModel } from "src/models/device.model";
import { StoreState } from "src/reducers";
import { CommunicationLog } from "./CommunicationLog";
import { Notifications } from "./Notifications";
import { PatientInformationBar } from "./PaitentIformationBar";
import { Skeleton } from "primereact/skeleton";
import { RecordingsSection } from "./RecordingsSection";
import { RecordingsObjModel } from "../../models/recording.model";
import { IcuFeatureFlagsModel } from "../../models/icu.model";

interface PatientInformationScreenProps {
    devices?: DeviceModel[];
    timeZone?: string;
    recordings?: Map<string, RecordingsObjModel>;
    icuFeatureFlags?: IcuFeatureFlagsModel;
}

const mapStateToProps = ({ deviceObject, icus, recordings }: StoreState) => {
    return {
        devices: deviceObject.devices,
        //TODO: change to set specific icu.
        timeZone: icus[0]?.settings?.account_timezone,
        recordings,
        icuFeatureFlags: icus[0]?.feature_flags,
    };
};

const _PatientInformationScreen = ({
    devices,
    timeZone,
    recordings,
    icuFeatureFlags,
}: PatientInformationScreenProps): JSX.Element => {
    let params = useParams();

    const findCurrentDevice = (deviceId: string | undefined) => {
        if (!deviceId) {
            return undefined;
        }

        return devices?.find((device) => {
            return device.id === deviceId;
        });
    };

    const bodyTemplate = () => {
        return <Skeleton height="100%" className="skeleton"></Skeleton>;
    };

    const device = findCurrentDevice(params.deviceId);
    const patientId = device?.patient?._id ? device?.patient?._id : "";

    return (
        <div className="patient-information-screen">
            <div className="patient-information-screen__general-information">
                <div className="patient-information-screen__patient-bar">
                    {devices?.length ? (
                        <PatientInformationBar device={device} />
                    ) : (
                        <Skeleton height="9rem" className="skeleton"></Skeleton>
                    )}
                </div>
                <div className="patient-information-screen__nav-bar"></div>
                <div className="patient-information-screen__boxes">
                    <div className="patient-information-screen__top-box">
                        <div className="patient-information-screen__notifications">
                            {devices?.length ? (
                                <Notifications
                                    timeZone={timeZone}
                                    notifications={device?.notifications}
                                />
                            ) : (
                                bodyTemplate()
                            )}
                        </div>
                        <div className="patient-information-screen__communication-log">
                            {devices?.length ? (
                                <CommunicationLog
                                    timeZone={timeZone}
                                    communicationLogs={
                                        device?.communicationLogs
                                    }
                                />
                            ) : (
                                bodyTemplate()
                            )}
                        </div>
                    </div>
                    <div className="patient-information-screen__recording-box">
                        {recordings?.get(patientId)?.familyRecordingPending ||
                        recordings?.get(patientId)?.generalRecordingPending ||
                        recordings?.get(patientId)?.slotsPending || !devices?.length ? (
                            bodyTemplate()
                        ) : icuFeatureFlags?.recordings
                              ?.family_recordings_management ||
                          icuFeatureFlags?.recordings
                              ?.general_recordings_management ? (
                            <RecordingsSection
                                recordingsObjModel={recordings?.get(patientId)}
                                recordingFlags={icuFeatureFlags?.recordings}
                                patientId={patientId}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const PatientInformationScreen = connect(
    mapStateToProps,
    null
)(_PatientInformationScreen);
