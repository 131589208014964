import { Context, createContext } from "react";
import {
    OpenDialogArgs,
    UpdateDialogArgs,
} from "src/components/general/CustomDialog";
import { DialogPropTypes } from "./types";

export const DialogContext: Context<DialogPropTypes> = createContext({
    showDialog: (args: OpenDialogArgs) => {
        console.log(args);
    },
    hide: () => {},
    resetTimeOut: () => {},
    updateArguments: (args: UpdateDialogArgs) => {
        console.log(args);
    },
    showAttentionDialog: (args: OpenDialogArgs) => {
        console.log(args);
    },
    hideAttentionDialog: () => {},
    resetAttentionTimeOut: () => {},
    updateAttentionArguments: (args: UpdateDialogArgs) => {
        console.log(args);
    },
});
