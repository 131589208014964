import { axios } from "./apiMiddleware";
import { BASE_HEADERS, BASE_URL } from '../globals';
import { AxiosInstance } from "axios";

const DEVICE_URL: string = `${BASE_URL}/device`;

const newAxios: AxiosInstance = axios.create({
    baseURL: DEVICE_URL,
    headers: {
        ...BASE_HEADERS
    }
});

newAxios.interceptors.request = axios.interceptors.request;
newAxios.interceptors.response = axios.interceptors.response;

export default newAxios;
