import { TenantDevicesAction, tenantActionTypes } from "../actions";
import { TenantModel } from "../models/tenant.model";


const initialState: TenantModel = {
    id: "",
    name: "",
    icus: [],
    voices: []
};

export const tenantReducer = (state: TenantModel = initialState, action: TenantDevicesAction) => {
    switch (action.type) {
        case tenantActionTypes.SET_VOICES:
            return {...state, voices: action.payload }
        default:
            return state;
    }
};