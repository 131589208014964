import { REPEAT_TYPE } from "src/models/scheduler.model";

export const SUB_TYPE_NAMES = {
    PAIN_ASSESSMENT: "Pain Assessment"
};

interface CommunicationType {
    name: string;
    id: number;
}

export const communicationTypes: CommunicationType[] = [
    { name: "Q&A", id: 1 },
    { name: "Instructions", id: 8 },
    { name: "CAM-ICU", id: 2 },
    { name: "Orientation", id: 5 },
    { name: "Relaxing", id: 6 },
    { name: "Family Message", id: 7 },
    { name: "Personal", id: 9 },
    { name: "Training", id: 11 }
];

interface WeekDay {
    label: string;
    value: string;
}

export const WEEK_DAYS_OPTIONS: WeekDay[] = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
];

interface RepeatOption {
    label: string;
    value: REPEAT_TYPE;
}

export const repeatOptions: RepeatOption[] = [
    { label: "Daily", value: REPEAT_TYPE.DAILY },
    { label: "Weekly", value: REPEAT_TYPE.WEEKLY }
];

export enum Q_AND_A_SUB_TYPES {
    PAIN_ASSESSMENT = 1
}

export enum GENERAL_MESSAGE_TYPES {
    SLOT_1 = 1,
    SLOT_2 = 2,
    SLOT_3 = 3,
    SLOT_4 = 4,
    SLOT_5 = 5,
}

export const SUB_TYPES = {
    Q_AND_A: Q_AND_A_SUB_TYPES,
    GENERAL_MESSAGE: GENERAL_MESSAGE_TYPES
};

interface Slot {
    name: string;
    value: GENERAL_MESSAGE_TYPES;
}

export const SLOTS: Slot[] = [
    { name: "Slot 1", value: GENERAL_MESSAGE_TYPES.SLOT_1 },
    { name: "Slot 2", value: GENERAL_MESSAGE_TYPES.SLOT_2 },
    { name: "Slot 3", value: GENERAL_MESSAGE_TYPES.SLOT_3 },
    { name: "Slot 4", value: GENERAL_MESSAGE_TYPES.SLOT_4 },
    { name: "Slot 5", value: GENERAL_MESSAGE_TYPES.SLOT_5 },
];

interface Question {
    name: string;
    value: Q_AND_A_SUB_TYPES;
}

export const QUESTIONS: Question[] = [
    { name: SUB_TYPE_NAMES.PAIN_ASSESSMENT, value: Q_AND_A_SUB_TYPES.PAIN_ASSESSMENT }
];