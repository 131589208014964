import React from "react";

export const ArrowLeftIcon = ({
    height = "1rem",
    width = "1rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="7"
        height="15"
        viewBox="0 0 7 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6 0.999999L1.2345 7.19514C1.09625 7.37487 1.09625 7.62513 1.2345 7.80486L6 14"
            stroke="#0F284C"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
