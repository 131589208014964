import React from "react";

export const PowerConnectedIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3.26087C2 3.1168 2.1168 3 2.26087 3H9.73913C9.8832 3 10 3.1168 10 3.26087V5C10 7.20914 8.20914 9 6 9C3.79086 9 2 7.20914 2 5V3.26087Z" fill="#0F284C"/>
    <rect x="3" width="1" height="5" rx="0.5" fill="#0F284C"/>
    <rect x="8" width="1" height="5" rx="0.5" fill="#0F284C"/>
    <path d="M6 8V8.68744C6 9.80112 6.81477 10.7472 7.91612 10.9124V10.9124C8.30483 10.9707 8.69735 11 9.09041 11H11" stroke="#0F284C"/>
    </svg>
);