import React from "react";

interface SvgProps {
    onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    className?: string
}

export const EyeCloseIcon = ({
    height = "1rem",
    width = "1.5rem",
    color = "#0F284C",
    onClick
}: React.SVGProps<SVGSVGElement>) => {
    const svgProps: SvgProps = {}

    if (!!onClick) {
        svgProps.onClick = onClick
        svgProps.className = 'cursor-pointer'
    }

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 -2 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
        >
            <path
                d="M2.96195 2.9257L1.78125 4.50007C1.62594 4.7072 1.33217 4.74905 1.125 4.59374C0.917867 4.43843 0.876015 4.14467 1.03132 3.9375L2.16297 2.42851C1.49007 1.9755 0.818631 1.43648 0.148992 0.811456C-0.0403231 0.634726 -0.0506234 0.338147 0.126103 0.148849C0.302671 -0.0403035 0.599413 -0.0506033 0.788544 0.126124C5.29605 4.33313 9.70395 4.33313 14.2115 0.126124C14.4006 -0.0506062 14.6973 -0.0403064 14.8739 0.148849C15.0506 0.338164 15.0403 0.634713 14.851 0.811456C14.1814 1.43648 13.5099 1.97546 12.837 2.42851L13.9687 3.9375C14.124 4.14463 14.0821 4.43843 13.875 4.59374C13.6679 4.74905 13.3741 4.7072 13.2187 4.50007L12.038 2.9257C11.5254 3.21867 11.012 3.46179 10.4982 3.65519L11.2165 5.45091C11.3127 5.69124 11.1956 5.9641 10.9553 6.06024C10.715 6.15637 10.4421 6.03948 10.3459 5.79915L9.60307 3.94162C9.05848 4.08516 8.51361 4.17311 7.96869 4.20499V6.09375C7.96869 6.35271 7.75894 6.56246 7.49998 6.56246C7.24103 6.56246 7.03128 6.3527 7.03128 6.09375V4.20499C6.48636 4.17311 5.94148 4.08516 5.3969 3.94162L4.65402 5.79915C4.55789 6.03948 4.28503 6.15637 4.04469 6.06024C3.80436 5.96411 3.68731 5.69125 3.78344 5.45091L4.50179 3.65519C3.98797 3.46179 3.47461 3.21867 2.96192 2.9257H2.96195Z"
                fill={color}
            />
        </svg>
    );
} 