import React from "react";

export const PatientIcon = ({
    height = "26px",
    width = "26px",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg width={width} height={height} viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="9" r="9" fill="#0F284C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.7605 19.9016C9.32229 18.696 6.29381 18.0954 4.39246 20.0404C2.67115 21.8013 1.30574 23.8917 0.374173 26.1924C-1.16764 30.0001 2.29339 33.4491 6.40146 33.4491L17.5 33.4491H28.5985C32.7066 33.4491 36.1676 30.0001 34.6258 26.1924C33.6943 23.8917 32.3288 21.8013 30.6075 20.0404C28.706 18.0953 25.6773 18.6959 23.239 19.9016C21.5035 20.7598 19.5565 21.2408 17.4998 21.2408C15.4429 21.2408 13.496 20.7598 11.7605 19.9016Z" fill="#0F284C"/>
</svg>

);
