import { useParams } from "react-router-dom";
import { Link, Element } from "react-scroll";
import { DeviceModel } from "../../models/device.model";
import { ScrollingSidebarModel } from "src/models/scrollingSidebar.model";

interface ScrollingSidebarProps {
  scrollingSidebarObj: ScrollingSidebarModel;
  devices: DeviceModel[] | undefined;
}

export const ScrollingSidebar = ({
  scrollingSidebarObj,
}: ScrollingSidebarProps): JSX.Element => {

  return (
      <div className="scrolling-sidebar">
          <div className="scrolling-sidebar__header">
              {scrollingSidebarObj.headerComponent ? (
                  scrollingSidebarObj.headerComponent
              ) : (
                  <div>
                      <div className="scrolling-sidebar__header">
                          {scrollingSidebarObj.title}
                      </div>
                      <div className="scrolling-sidebar__sub-title">
                          {scrollingSidebarObj.subTitle}
                      </div>
                  </div>
              )}
          </div>
          <div className="scrolling-sidebar__main">
              <div className="scrolling-sidebar__side-bar">
                  <ul className="scrolling-sidebar__side-bar-items">
                      {scrollingSidebarObj.sections.map((section, index) => {
                          return (
                              <li
                                  key={index}
                                  className="scrolling-sidebar__side-bar-item"
                              >
                                  <Link
                                      className="scrolling-sidebar__side-bar-item-link"
                                      activeClass="active"
                                      to={`scrolling-sidebar__element${index}`}
                                      spy={true}
                                      smooth={true}
                                      offset={0}
                                      duration={500}
                                      containerId="containerElement"
                                  >
                                      {section.name}
                                  </Link>
                              </li>
                          );
                      })}
                  </ul>
              </div>
              <div className="scrolling-sidebar__side-content-wrapper">
                  <Element
                      name="scrolling-sidebar__elements"
                      id="containerElement"
                      className="scrolling-sidebar__side-content"
                  >
                      {scrollingSidebarObj.sections.map((section, index) => {
                          return (
                              <Element
                                  key={index}
                                  id="containerElement"
                                  name={`scrolling-sidebar__element${index}`}
                                  className="element"
                              >
                                  {section.element}
                              </Element>
                          );
                      })}
                      <div className="scrolling-sidebar__blank"></div>
                  </Element>
              </div>
          </div>
      </div>
  );
};
