export const EyeControlIconLogin = ({ width="15.2rem", height="3.6rem" }: React.SVGProps<SVGSVGElement>) => (
<svg width={width} height="38" viewBox="0 0 152 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_892_2352)">
<path d="M3.55853 22.6531C3.86112 25.254 5.91878 26.9521 8.91145 26.9521C10.189 26.9476 11.4468 26.6229 12.5789 26.0052C12.9442 25.7977 13.3638 25.7183 13.7757 25.7785C14.1876 25.8388 14.57 26.0355 14.8665 26.3398L15.8923 27.3782C14.1978 29.3194 11.3716 30.2916 8.84488 30.2916C3.45867 30.2821 0 26.7091 0 21.2296C0 16.0278 3.49196 12.3096 8.61188 12.3096C13.4534 12.3096 16.8758 15.1503 16.9454 20.9739C16.9474 21.1931 16.9078 21.4106 16.8289 21.6138C16.7501 21.8171 16.6335 22.002 16.4858 22.1581C16.3382 22.3141 16.1624 22.4382 15.9687 22.5232C15.7749 22.6081 15.5669 22.6523 15.3567 22.6531H3.55853ZM13.5654 19.6356C13.3657 16.9305 11.4714 15.507 8.72384 15.507C6.23045 15.507 4.1849 16.9305 3.6372 19.6356H13.5654Z" fill="#0F284C"/>
<path d="M34.947 12.8372L25.2306 36.365C25.0457 36.8143 24.7384 37.1971 24.3468 37.4659C23.9551 37.7347 23.4965 37.8776 23.0277 37.8769H20.8157L24.2078 29.6923L17.7444 12.8372H19.9715C20.4636 12.8371 20.9438 12.9948 21.347 13.289C21.7502 13.5831 22.057 13.9995 22.2258 14.4816L24.4771 20.9175L26.1051 25.7721L27.8692 20.987L30.5109 14.3775C30.6928 13.9211 31.0003 13.5312 31.3945 13.257C31.7888 12.9828 32.2521 12.8367 32.7259 12.8372H34.947Z" fill="#0F284C"/>
<path d="M70.1299 27.6469C69.3241 28.5126 68.3563 29.1964 67.2866 29.6557C66.2169 30.1151 65.0681 30.3402 63.9115 30.3172C59.191 30.3172 55.2664 27.2302 55.2664 21.3341C55.2664 15.4379 59.1577 12.351 63.9115 12.351C65.0118 12.3223 66.1059 12.5318 67.1246 12.9664C68.1434 13.4011 69.0647 14.0513 69.8303 14.8761L68.8166 15.9808C68.5115 16.3069 68.1132 16.5208 67.6815 16.5903C67.2498 16.6597 66.8081 16.581 66.4231 16.3659C65.6565 15.9538 64.8072 15.7375 63.9448 15.7346C60.9854 15.7346 58.6917 17.9189 58.6917 21.3183C58.6917 24.9261 61.0853 26.8325 63.9115 26.8325C64.8989 26.8478 65.873 26.5944 66.7378 26.0971C67.0889 25.8923 67.4944 25.8124 67.8929 25.8693C68.2914 25.9263 68.6614 26.117 68.9467 26.4127L70.1299 27.6469Z" fill="#0F284C"/>
<path d="M87.8676 21.3341C87.8676 26.3843 84.5088 30.213 79.3556 30.213C74.2024 30.213 70.9102 26.3969 70.9102 21.3341C70.9102 16.2712 74.269 12.4204 79.3556 12.4204C84.4422 12.4204 87.8676 16.3059 87.8676 21.3341ZM74.3355 21.3341C74.3355 24.3863 76.2298 26.883 79.3556 26.883C82.4814 26.883 84.4241 24.3863 84.4241 21.3341C84.4241 18.2818 82.3604 15.7157 79.3677 15.7157C76.2298 15.7157 74.3355 18.3071 74.3355 21.3341Z" fill="#0F284C"/>
<path d="M146.529 21.3341C146.529 26.3843 143.174 30.213 138.017 30.213C132.861 30.213 129.572 26.3969 129.572 21.3341C129.572 16.2712 132.931 12.4204 138.017 12.4204C143.104 12.4204 146.529 16.3059 146.529 21.3341ZM132.997 21.3341C132.997 24.3863 134.895 26.883 138.017 26.883C141.14 26.883 143.074 24.3863 143.074 21.3341C143.074 18.2818 141.01 15.7157 138.017 15.7157C134.895 15.7157 132.997 18.3071 132.997 21.3341Z" fill="#0F284C"/>
<path d="M152 8.12729V29.8654H148.575V5.62427H149.597C150.234 5.62427 150.845 5.88788 151.295 6.35719C151.746 6.82651 151.999 7.46316 152 8.12729Z" fill="#0F284C"/>
<path d="M97.7385 12.4907C93.7472 12.4907 89.871 14.9843 89.871 20.2207V29.8667H93.3296V20.7131C93.3296 17.7651 94.9243 15.7892 97.7506 15.7892C100.577 15.7892 102.171 17.7651 102.171 20.7131V29.8667H105.63V20.2207C105.63 14.9843 101.742 12.4907 97.7385 12.4907Z" fill="#0F284C"/>
<path d="M128.906 12.5412C128.617 12.5086 128.328 12.4917 128.037 12.4907C124.046 12.4907 120.17 14.9843 120.17 20.2207V29.8667H123.626V20.7131C123.626 17.7651 125.223 15.7892 128.05 15.7892C128.34 15.7901 128.631 15.8144 128.918 15.8618L128.906 12.5412Z" fill="#0F284C"/>
<path d="M113.189 21.6272V16.1224H117.174V12.8019H113.189V8.12729C113.189 7.46345 112.936 6.82679 112.486 6.35739C112.036 5.88798 111.426 5.62427 110.79 5.62427H109.773V12.8019H106.547V16.1224H109.773V21.8892C109.773 27.1288 113.622 29.8496 117.61 29.8496V26.5543C114.784 26.5543 113.189 24.5753 113.189 21.6272Z" fill="#0F284C"/>
<path d="M39.4981 22.6529C39.8007 25.2538 41.8584 26.9519 44.851 26.9519C46.1286 26.9475 47.3864 26.6227 48.5185 26.005C48.8844 25.7978 49.3043 25.7185 49.7166 25.7787C50.129 25.8389 50.5119 26.0355 50.8091 26.3396L51.8319 27.3781C50.1374 29.3192 47.3111 30.2914 44.7845 30.2914C39.3982 30.2914 35.9396 26.7184 35.9396 21.2389C35.9396 16.0371 39.4315 12.3252 44.5515 12.3252C49.393 12.3252 52.8153 15.1502 52.8698 20.9832C52.8722 21.2025 52.8329 21.4202 52.7542 21.6236C52.6755 21.8271 52.5589 22.0122 52.4112 22.1683C52.2634 22.3245 52.0875 22.4485 51.8936 22.5333C51.6996 22.6181 51.4915 22.662 51.2812 22.6624L39.4981 22.6529ZM49.5049 19.6354C49.3052 16.9304 47.411 15.5068 44.6634 15.5068C42.17 15.5068 40.1093 16.9304 39.5768 19.6354H49.5049Z" fill="#00B4CD"/>
</g>
<defs>
<clipPath id="clip0_892_2352">
<rect width="152" height="37.8768" fill="white"/>
</clipPath>
</defs>
</svg>
);