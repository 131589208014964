import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { StoreState } from "src/reducers";
import { updateSettingsField } from "src/actions";
import { DeviceModel } from "src/models/device.model";
import { SCHEDULER_VIEW } from "src/models/scheduler.model";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import { SchedulerEventForm } from "../../forms/ScheduleEventForm/SchedulerEventForm";
import { CustomScheduler } from "../customScheduler/CustomScheduler";
import { DialogTypes, OpenDialogArgs } from "../CustomDialog";

interface SchedulerCardProps {
    devices?: DeviceModel[];
    schedulerView?: SCHEDULER_VIEW;
}

const mapStateToProps = ({ tenant, deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _SchedulerCard = ({ devices, schedulerView=SCHEDULER_VIEW.ICU }: SchedulerCardProps): JSX.Element => {
    const { t } = useTranslation();
    const params = useParams();
    const { showDialog, hide} = React.useContext(DialogContext) as DialogPropTypes;
    const deviceId = params.deviceId ? params.deviceId : "";
    const [isCurrentValueFromShadow, setIsCurrentValueFromShadow] = useState<boolean>(false);
    const [isSchedulerOn, setIsSchedulerOn] = useState<boolean>();

    const openAddEventDialog = () => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Add New Event"),
            component: <SchedulerEventForm onClose={hide} />,
            actionText: t("Save"),
            cancelText: t("Cancel"),
        };

        showDialog(args);
    };

    const onSchedulerOnChange = (value: boolean | undefined) => {
        if(value !== isSchedulerOn) {
            setIsSchedulerOn(value);
            updateSettingsField(deviceId, "is_scheduler_on", { is_scheduler_on: value });
        }
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);
        if (currentDevice && (!isCurrentValueFromShadow || currentDevice.isReset)) {
            setIsSchedulerOn(currentDevice.shadow?.settings?.is_scheduler_on as boolean );
            setIsCurrentValueFromShadow(true);
        }
    }, [devices?.length]);

    return (
        <div className="scheduler-card card-container">
            <div className="scheduler-card__header">
                <div className="scheduler-card__title-wrapper">
                    <div className="scheduler-card__title">
                        {t("Scheduler")}
                    </div>
                    <div className="scheduler-card__sub-title">
                        {t("Create a daily schedule that will be sent to every patient automatically according to the settings.")}
                    </div>
                    {schedulerView === SCHEDULER_VIEW.DEVICE ? (
                        <div className="scheduler-card__scheduler-checkbox med-checkbox">
                            <Checkbox onChange={(e) => onSchedulerOnChange(e.checked)}
                                      checked={isSchedulerOn || false}/>
                            <label htmlFor="rb1" className="scheduler-card__checbox-label p-checkbox-label">
                                {t("Receive scheduled events")}
                            </label>
                        </div>
                    ) : null}
                </div>
                <div className="scheduler-card__button-wrapper">
                    {schedulerView === SCHEDULER_VIEW.ICU ? (
                        <Button onClick={openAddEventDialog} className="button button-regular">
                            {t("Add New Event")}
                        </Button>
                    ) : null}
                </div>
            </div>
            <div className="scheduler-card__main">
                <CustomScheduler />
            </div>
        </div>
    );
};

export const SchedulerCard = connect(mapStateToProps, null)(_SchedulerCard);
