export enum MIXPANEL_ACTIONS {
    OPEN_MODAL = 'open_modal',
    CLOSE_MODAL = 'close_modal',
    COMMUNICATION_SENT = 'communication_sent',
    MORE_ACTION_OPEN = 'more_action_open',
    MORE_ACTION_CLOSE = 'more_action_close',
    VIDEO_STREAM_OPEN = 'video_stream_open',
    VIDEO_STREAM_CLOSE = 'video_stream_close',
    EDIT_PATIENT_OPEN = 'edit_patient_open',
    EDIT_PATIENT_CANCEL = 'edit_patient_cancel',
    EDIT_PATIENT_SAVE = 'edit_patient_save',
    ALERT_CLOSE = 'alert_close',
    STOP_COMMUNICATION = 'stop_communication',
    ADD_PATIENT_OPEN = 'add_patient_open',
    ADD_PATIENT_CANCEL = 'add_patient_cancel',
    ADD_PATIENT_SAVE = 'add_patient_save',
    EDIT_PHONE_NUMBER = 'edit_phone_number',
    ADDING_PHONE_NUMBER = 'adding_phone_number',
    PHONE_NUMBER_CONFIRMED = 'phone_number_confirmed',
    SMS_RESEND = 'sms_resend'
}

export enum MIXPANEL_ACTION_TYPES {
    DAILY_SCHEDULE = 'daily_schedule',
    MUSIC = 'music',
    FAMILY_MESSAGES = 'family_messages',
    STOP_COMMUNICATION = 'stop_communication',
    EDIT_PATIENT = 'edit_patient',
    DEVICE_OFF = 'device_off',
    WIFI_DISCONNECTED = 'wifi_disconnected',
    POWER_DISCONNECTED = 'power_disconnected',
    TECHNICAL_ISSUE = 'technical_issue',
    RESPONSES = 'responses',
    NOTIFY = 'notify',
    GET_APP_LINK = 'get_app_link'
}

export enum MIXPANEL_ACTION_SUB_TYPES {
    X_BUTTON = 'x_button',
    CLOSE_BUTTON = 'close_button',
    CANCEL_BUTTON = 'cancel_button',
    ADD_PHONE_NUMBER = 'add_phone_number',
    VALIDATE_PHONE_NUMBER = 'validate_phone_number'
}
