import React from "react";

export const RefreshIcon = ({height = "36px", width = "31px", color = "#273E5E", ...props}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.51991 22.1263C3.59793 18.2212 4.49079 13.765 7.79274 10.7744C11.0962 7.78648 16.0237 6.97853 20.3427 8.71682C20.3837 8.73307 20.4256 8.73985 20.4665 8.75385L18.3514 10.2564C17.468 10.8832 17.3133 12.0376 18.0065 12.8374C18.4073 13.299 19.0037 13.5415 19.6061 13.5415C20.0468 13.5415 20.4875 13.4132 20.8598 13.1495L26.222 9.34321C26.8303 8.91192 27.1148 8.20603 26.9571 7.52183L25.5612 1.46334C25.3331 0.470222 24.2591 -0.167918 23.1586 0.0389245C22.0601 0.245767 21.3544 1.21946 21.5835 2.21393L22.2278 5.01262C22.2103 5.00449 22.1958 4.99365 22.1769 4.98643C16.1379 2.55535 9.24059 3.69299 4.60059 7.88719C-0.0334146 12.0836 -1.29111 18.325 1.39646 23.7883C1.77028 24.5484 2.59477 25 3.45968 25C3.76612 25 4.07805 24.9426 4.3765 24.823C5.51542 24.3637 6.02598 23.1565 5.51991 22.1263Z"
            fill={color}/>
        <path
            d="M29.638 12.2068C29.1446 11.1814 27.8406 10.7201 26.7274 11.1755C25.6141 11.6301 25.1128 12.8306 25.6083 13.8556C27.4827 17.7434 26.6113 22.1752 23.3842 25.1463C20.2234 28.0577 15.5426 28.8837 11.388 27.2906L13.4512 25.8007C14.3128 25.1773 14.465 24.0279 13.7867 23.2325C13.1103 22.4388 11.8625 22.2987 11.0004 22.9235L5.76031 26.7089C5.16638 27.1378 4.88746 27.8399 5.04204 28.5194L6.40545 34.5439C6.6005 35.4071 7.42557 36 8.34621 36C8.47982 36 8.61685 35.9874 8.75241 35.9623C9.82519 35.7552 10.5152 34.7877 10.2918 33.7987L9.66525 31.0288C11.627 31.7856 13.6726 32.1593 15.6958 32.1593C19.6743 32.1593 23.5573 30.7319 26.505 28.0155C31.0307 23.8496 32.261 17.6424 29.638 12.2068Z"
            fill={color}/>
    </svg>
);
