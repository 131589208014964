import React from "react";

export const RedDotIcon = ({
    height = "24px",
    width = "24px",
    className = "",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg className={className} width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 7.50002C15 11.6422 11.6421 15 7.5 15C3.35786 15 0 11.6422 0 7.50002C0 3.35788 3.35786 0 7.5 0C11.6421 0 15 3.35788 15 7.50002Z" fill="#E52B17"/>
</svg>
);
