import React from "react";

export const PowerNotConnectedIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_712_11978)">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 0C3.22386 0 3 0.223858 3 0.5V2H2.26087C2.1168 2 2 2.1168 2 2.26087V4C2 6.20914 3.79086 8 6 8H5.5V8.68744C5.5 10.0486 6.49584 11.205 7.84195 11.4069C8.25522 11.4689 8.67252 11.5 9.09041 11.5H11V10.5H9.09041C8.72218 10.5 8.35445 10.4726 7.99029 10.4179C7.1337 10.2895 6.5 9.55362 6.5 8.68744V8H6C8.20914 8 10 6.20914 10 4V2.26087C10 2.1168 9.8832 2 9.73913 2H9V0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5V2H4V0.5C4 0.223858 3.77614 0 3.5 0Z" fill="#BE3535"/>
    <path d="M0.000978828 10.3644C-0.385001 10.768 -0.379064 11.4164 0.0142366 11.8128C0.407538 12.2092 1.03927 12.2034 1.42525 11.7999L11.3253 1.44984C11.7113 1.04632 11.7054 0.397862 11.3121 0.00147129C10.9188 -0.394919 10.287 -0.389136 9.90105 0.0143868L0.000978828 10.3644Z" fill="#BE3535" stroke="white" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_712_11978">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);