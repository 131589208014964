import { Dispatch } from "redux";
import { SchedulerEvent } from "src/models/scheduler.model";
import { schedulerEventActionTypes } from "./types";
import scheduleApi from '../apis/schedulerEvent';

export interface FetchSchedulerEventsAction {
    type: schedulerEventActionTypes.FETCH_EVENTS;
    payload: SchedulerEvent[];
}

export interface AddSchedulerEventAction {
    type: schedulerEventActionTypes.ADD_EVENT;
    payload: SchedulerEvent;
}

export interface UpdateSchedulerEventAction {
    type: schedulerEventActionTypes.UPDATE_EVENT;
    payload: SchedulerEvent;
}

export interface DeleteSchedulerEventAction {
    type: schedulerEventActionTypes.DELETE_EVENT;
    payload: string;
}

export type SchedulerEventsAction = FetchSchedulerEventsAction | AddSchedulerEventAction | UpdateSchedulerEventAction | DeleteSchedulerEventAction;

export const fetchSchedulerEvents = () => {
    return async (dispatch: Dispatch) => {
        try {

            const response = await scheduleApi.get("/")

            dispatch<SchedulerEventsAction>({
                type: schedulerEventActionTypes.FETCH_EVENTS,
                payload: response.data as SchedulerEvent[],
            });
            
            
        } catch (e) {
            console.log(e);
        }
    };
};


export const addSchedulerEvent = (
    schedulerEvent: SchedulerEvent,
    callBack: (result: { status: boolean; error?: any }) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await scheduleApi.post("/", schedulerEvent);

            dispatch<AddSchedulerEventAction>({
                type: schedulerEventActionTypes.ADD_EVENT,
                payload: response.data as SchedulerEvent
            });

            callBack({
                status: true,
                error: null,
            });

        } catch (e: any) {
            console.log(e);
            callBack({
                status: false,
                error: e?.response?.data?.message
            });
        }
    };
};

export const updateSchedulerEvent = (
    schedulerEvent: SchedulerEvent,
    callBack: (result: { status: boolean; error?: any }) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            await scheduleApi.put(`/${schedulerEvent.id}`, schedulerEvent);

            dispatch<UpdateSchedulerEventAction>({
                type: schedulerEventActionTypes.UPDATE_EVENT,
                payload: schedulerEvent
            });

            callBack({
                status: true,
                error: null,
            });

        } catch (e: any) {
            console.log(e);
            callBack({
                status: false,
                error: e?.response?.data?.message
            });
        }
    };
};

export const deleteSchedulerEvent = (
    id: string,
    callBack: (result: { status: boolean; error?: any }) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            await scheduleApi.delete(`/${id}`);

            dispatch<DeleteSchedulerEventAction>({
                type: schedulerEventActionTypes.DELETE_EVENT,
                payload: id
            });

            callBack({
                status: true,
                error: null,
            });

        } catch (e: any) {
            console.log(e);
            callBack({
                status: false,
                error: e?.response?.data?.message
            });
        }
    };
};