import React from "react";

export const RepeatIcon = ({
    height = "1.4rem",
    width = "1.4rem",
    color = "#455472",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99557 6.00384C9.69426 6.32224 9.20574 6.32224 8.90443 6.00384C8.60312 5.68545 8.60312 5.16923 8.90443 4.85083L9.95679 3.7388H3.87818C2.59306 3.7388 1.55127 4.83387 1.55127 6.1847V7C1.55127 7.45028 1.20401 7.8153 0.775635 7.8153C0.347264 7.8153 0 7.45028 0 7V6.1847C0 3.93331 1.73632 2.10819 3.87818 2.10819H9.58228L8.90434 1.39181C8.60303 1.07341 8.60303 0.557191 8.90434 0.238796C9.20565 -0.0795988 9.69417 -0.0795987 9.99547 0.238796L12.1706 2.53722L12.1778 2.54481C12.4791 2.86321 12.4791 3.37943 12.1778 3.69782L9.99557 6.00384Z" fill={color}/>
<path d="M1.77185 11.4552C1.46922 11.1368 1.46922 10.6206 1.77185 10.3022L1.7789 10.2948L3.96385 7.99616C4.26649 7.67776 4.75717 7.67776 5.05981 7.99616C5.36245 8.31455 5.36245 8.83077 5.05981 9.14917L4.00279 10.2612H10.1218C11.4069 10.2612 12.4487 9.16614 12.4487 7.8153V7C12.4487 6.54972 12.796 6.1847 13.2244 6.1847C13.6527 6.1847 14 6.54972 14 7V7.8153C14 10.0667 12.2637 11.8918 10.1218 11.8918H4.37879L5.05972 12.6082C5.36236 12.9266 5.36236 13.4428 5.05972 13.7612C4.75708 14.0796 4.26641 14.0796 3.96377 13.7612L1.77185 11.4552Z" fill={color}/>
</svg>
);
