import { combineReducers } from 'redux';
import { deviceReducer } from './device.reducer';
import { authenticationReducer } from './authentication.reducer';
import { AuthenticationModel } from '../models/user.model';
import { reducer as formReducer } from 'redux-form';
import { IcuModel } from '../models/icu.model';
import { icuReducer } from './icu.reducer';
import { TenantModel } from '../models/tenant.model';
import { DeviceObject } from '../models/device.model';
import { tenantReducer } from '../reducers/tenant.reducer';
import { SchedulerEvent } from '../models/scheduler.model';
import { schedulerEventReducer } from './schedulerEvent.reducer';
import { ToastModel } from '../models/toast.model';
import { toastHandlerReducer } from './toastHandler.reducer';
import { RecordingsObjModel } from '../models/recording.model';
import { recordingReducer } from './recording.reducer';
import { QuestionModel } from '../models/question.model';
import { questionReducer } from './question.reducer';


export interface StoreState {
    form: any;
    deviceObject: DeviceObject;
    authInfo: AuthenticationModel | undefined;
    tenant: TenantModel;
    icus: IcuModel[];
    schedulerEvents: SchedulerEvent[];
    toastHandler: ToastModel;
    recordings:  Map<string, RecordingsObjModel>,
    questions: QuestionModel[]
}

export const reducers = combineReducers<StoreState>({
    form: formReducer,
    deviceObject: deviceReducer,
    authInfo: authenticationReducer,
    tenant: tenantReducer,
    icus: icuReducer,
    schedulerEvents: schedulerEventReducer,
    toastHandler: toastHandlerReducer,
    recordings: recordingReducer,
    questions: questionReducer
});
