import { Dispatch } from "redux";
import { VoiceModel } from "..//models/voice.model";
import voiceApi from '../apis/voice';
import { tenantActionTypes } from "./types";

export interface FetchTenantVoicesAction {
    type: tenantActionTypes;
    payload: VoiceModel[];
}

export type TenantDevicesAction = FetchTenantVoicesAction;

export const fetchTenantVoices = (icuId: string) => {
    return async (dispatch: Dispatch) => {
        const response = await voiceApi.get(`/${icuId}`);

        if (response.status === 200) {
            _setTenantVoicesState(dispatch, response.data);
        }
    }
}

const _setTenantVoicesState = (dispatch: Dispatch, voices: VoiceModel[]) => {
    dispatch<FetchTenantVoicesAction>({
        type: tenantActionTypes.SET_VOICES,
        payload: voices,
    });
}

const _capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}