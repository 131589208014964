import React from "react";

export const VolumeBigIcon = ({
    height = "1rem",
    width = "1rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_708_11770)">
            <rect y="3" width="5" height="4" rx="1" fill="#0F284C" />
            <path
                d="M6.77976 6.75196C6.91367 6.88293 7.13028 6.88164 7.24429 6.73303C7.40078 6.52906 7.52906 6.30428 7.6252 6.06471C7.76354 5.71998 7.83263 5.35137 7.82852 4.97993C7.82441 4.6085 7.74718 4.24151 7.60124 3.89992C7.49982 3.66254 7.3666 3.44066 7.20563 3.2402C7.08835 3.09415 6.87178 3.09765 6.7408 3.23156C6.60983 3.36547 6.61477 3.57889 6.72644 3.72927C6.82673 3.86433 6.91107 4.01101 6.97747 4.16642C7.08841 4.4261 7.14712 4.70508 7.15024 4.98744C7.15337 5.26981 7.10085 5.55002 6.99568 5.81208C6.93274 5.96893 6.85167 6.11744 6.75439 6.25468C6.64608 6.4075 6.64586 6.62098 6.77976 6.75196Z"
                fill="#0F284C"
            />
            <path
                d="M8.30261 7.25228C8.42687 7.37382 8.62736 7.37244 8.73737 7.23786C8.96395 6.96069 9.14753 6.65023 9.28131 6.31685C9.45424 5.88593 9.5406 5.42517 9.53546 4.96088C9.53032 4.49659 9.43379 4.03785 9.25136 3.61087C9.11023 3.28053 8.91983 2.97421 8.68716 2.70212C8.5742 2.57002 8.37373 2.57307 8.25219 2.69733C8.13065 2.82158 8.13438 3.01987 8.24478 3.15412C8.41973 3.36689 8.56389 3.60387 8.67254 3.85816C8.82249 4.20914 8.90184 4.58621 8.90606 4.96785C8.91029 5.34949 8.8393 5.72822 8.69715 6.08243C8.59417 6.33906 8.45529 6.57917 8.28508 6.79576C8.17769 6.93243 8.17835 7.13075 8.30261 7.25228Z"
                fill="#0F284C"
            />
            <path
                d="M2 3.3802C2 3.13528 2.08988 2.89889 2.25259 2.71583L4.25259 0.465835C4.86348 -0.221421 6 0.210682 6 1.1302V8.8698C6 9.78932 4.86348 10.2214 4.25259 9.53416L2.25259 7.28416C2.08988 7.10111 2 6.86471 2 6.6198V3.3802Z"
                fill="#0F284C"
            />
        </g>
        <defs>
            <clipPath id="clip0_708_11770">
                <rect width="10" height="10" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
