import React from "react";

export const ArrowDownloadIcon = ({
    height = "1rem",
    width = "1rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="5"
            y="2.5"
            width="10"
            height="1"
            rx="0.5"
            transform="rotate(90 5 2.5)"
            fill="#0F284C"
        />
        <path
            d="M1 9.5L4.36984 12.3884C4.44474 12.4526 4.55526 12.4526 4.63016 12.3884L8 9.5"
            stroke="#0F284C"
            strokeLinecap="round"
        />
        <line
            x1="0.5"
            y1="16"
            x2="8.5"
            y2="16"
            stroke="#0F284C"
            strokeLinecap="round"
        />
    </svg>
);
