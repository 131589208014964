export enum communicatonType {
    SEND_Q_N_A = 1,
    SEND_CAM_ICU = 2,
    STOP_COMMUNICATION = 4,
    SEND_ORIENTATION = 5,
    SEND_MUSIC = 6,
    SEND_FAMILY_MESSAGE = 7,
    SEND_INSTRUCTIONS = 8,
    SEND_PERSONAL_MUSIC = 9,
    SEND_GENERAL_MESSAGE = 10,
    SEND_TRAINING = 11
};