import React from "react";

export const FamilyIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.6956 11.9763C41.0321 11.9763 43.7369 9.29528 43.7369 5.98813C43.7369 2.68098 41.0321 0 37.6956 0C34.3591 0 31.6543 2.68098 31.6543 5.98813C31.6543 9.29528 34.3591 11.9763 37.6956 11.9763Z" fill="#0F284C"/>
<path d="M13.0452 18.7023C16.3817 18.7023 19.0865 16.0214 19.0865 12.7142C19.0865 9.40705 16.3817 6.72607 13.0452 6.72607C9.70868 6.72607 7.00391 9.40705 7.00391 12.7142C7.00391 16.0214 9.70868 18.7023 13.0452 18.7023Z" fill="#0F284C"/>
<path d="M0 36.009H15.177C15.7237 32.0218 18.6628 28.7874 22.514 27.7697C21.3313 26.9033 20.4749 25.6251 20.174 24.1458C18.1156 22.3776 15.6697 21.3506 13.0437 21.3506C6.52074 21.3511 1.10222 27.6785 0 36.009Z" fill="#0F284C"/>
<path d="M29.6621 27.77C33.5133 28.7877 36.4523 32.0216 36.9991 36.0093H49.6002C48.5944 24.2209 43.6499 15.2666 37.6968 15.2666C35.2162 15.2666 32.9115 16.8256 30.993 19.493C31.7007 20.473 32.1223 21.6688 32.1223 22.9629C32.1223 24.9333 31.1507 26.6797 29.6621 27.77Z" fill="#0F284C"/>
<path d="M21.8281 22.963C21.8281 25.2917 23.7394 27.1867 26.0893 27.1867C28.4393 27.1867 30.3505 25.2922 30.3505 22.963C30.3505 20.6337 28.4393 18.7393 26.0893 18.7393C23.7394 18.7393 21.8281 20.6342 21.8281 22.963Z" fill="#0F284C"/>
<path d="M27.0901 29.1831H25.0862C20.555 29.1831 16.8477 32.8578 16.8477 37.3492V40.0002H35.3286V37.3492C35.3286 32.8578 31.6213 29.1831 27.0901 29.1831Z" fill="#0F284C"/>
</svg>
);