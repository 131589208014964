import React from "react";

export const DocumentIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
       <rect x="1" y="1" width="14" height="18" rx="1" stroke="#0F284C" strokeWidth="2"/>
       <rect x="4" y="6" width="8" height="2" rx="1" fill="#0F284C"/>
       <rect x="4" y="11" width="8" height="2" rx="1" fill="#0F284C"/>
    </svg>
);