export enum TOAST_SEVERITY {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
    WARN = 'warn'
}

export interface ToastModel {
    severity: TOAST_SEVERITY | undefined;
    summary: string;
    detail: string;
    life?: number;
    sticky?: boolean;
}