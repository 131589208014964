import { InputText } from "primereact/inputtext";

export interface TextInputProps {
    placeholder: string;
    value: number;
    type: string;
    onChange: Function;
    touched?: boolean
    error?: string
}

const TextInput = ({ placeholder, value, type, onChange, touched, error }: TextInputProps) => {
    return (
        <InputText
            placeholder={placeholder}
            className={`med-input__input ${touched && error ? "input-error__wrapper" : ""}`}
            value={value.toString()}
            onChange={(e) => onChange(e.target.value)}
            type={type}
        />
    )
}

export default TextInput