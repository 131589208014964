import { Checkbox, CheckboxProps } from "primereact/checkbox";
import { useEffect } from "react";

interface MedCheckboxesProps extends CheckboxProps {
    input: { value: string; onChange: Function };
    placeholder: string;
    meta?: any;
    name: string;
    options: any[];
    checkedValues: string;
}

export const MedCheckboxes = ({
    input: { value, onChange },
    meta: { touched, error, warning },
    options,
    name,
    checkedValues
}: MedCheckboxesProps) => {
    const handleChange = (optionValue: string) => {
        let newValue = value;
        if (newValue.includes(optionValue)) {
            newValue = newValue.replace(`${optionValue},`, "");
            newValue = newValue.replace(`,${optionValue}`, "");
            newValue = newValue.replace(optionValue, "");
        } else {
            if (newValue === "") {
                newValue += optionValue;
            } else {
                newValue += `,${optionValue}`;
            }
        }
        onChange(newValue);
    };

    useEffect(() => {
        onChange(checkedValues);
    }, []);

    return (
        <div className="med-checkboxes">
            <div className="med-checkboxes__elements">
                {options.map((option) => (
                    <div key={option.value} className="med-checkbox">
                        <Checkbox
                            onChange={() => handleChange(option?.value)}
                            checked={value.includes(option?.value)}
                            name={name}
                        />
                        <label htmlFor="create-event__repeat-checkbox" className="p-checkbox-label">
                            {option?.label}
                        </label>
                    </div>
                ))}
            </div>
            <div className="med-dropdown__error input-error">
                <div className="med-dropdown__error-text input-error__text">
                    {touched &&
                        ((error && <span>{error}</span>) ||
                            (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
