import { connect } from "react-redux";
import { UserModel } from "../../models/user.model";
import { StoreState } from "../../reducers";
import {
    PagePermissionModel,
    PermissionTypes,
} from "../../models/Authorization.model";
import { getPage } from "src/utils/generalMethods";

interface AuthorizationPageWrapperProps {
    children: JSX.Element;
    pageName: string;
    pagesWithPermission?: PagePermissionModel[];
    isAuthorized?: boolean;
}

const mapStateToProps = ({ authInfo }: StoreState) => {
    return {
        pagesWithPermission:  authInfo?.authorization?.permissions?.pages,
        isAuthorized: authInfo?.isAuthorized
    };
};

const _AuthorizationPageWrapper = ({
    children,
    pageName,
    pagesWithPermission,
    isAuthorized
}: AuthorizationPageWrapperProps) => {

    if (!isAuthorized) {
        return null;
    }

    const currentPageObj = getPage(pageName, pagesWithPermission || []);

    if (currentPageObj?.type === PermissionTypes.DENY) {
        return null;
    }
    
    return children;
};

export const AuthorizationPageWrapper = connect(mapStateToProps, null)(_AuthorizationPageWrapper);
