import React from "react";

export const VolumeSmallIcon = ({
    height = "1rem",
    width = "1rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_708_11771)">
            <rect y="3" width="5" height="4" rx="1" fill="#0F284C" />
            <path
                d="M2 3.3802C2 3.13528 2.08988 2.89889 2.25259 2.71583L4.25259 0.465835C4.86348 -0.221421 6 0.210682 6 1.1302V8.8698C6 9.78932 4.86348 10.2214 4.25259 9.53416L2.25259 7.28416C2.08988 7.10111 2 6.86471 2 6.6198V3.3802Z"
                fill="#0F284C"
            />
            <path
                d="M7.77976 6.74073C7.91367 6.8717 8.13028 6.87041 8.24429 6.7218C8.40078 6.51783 8.52906 6.29305 8.6252 6.05348C8.76354 5.70875 8.83263 5.34014 8.82852 4.9687C8.82441 4.59727 8.74718 4.23028 8.60124 3.88869C8.49982 3.65131 8.3666 3.42943 8.20563 3.22897C8.08835 3.08292 7.87178 3.08642 7.7408 3.22033C7.60983 3.35424 7.61477 3.56766 7.72644 3.71804C7.82673 3.8531 7.91107 3.99978 7.97747 4.15519C8.08841 4.41487 8.14712 4.69385 8.15024 4.97621C8.15337 5.25857 8.10085 5.53879 7.99568 5.80085C7.93274 5.9577 7.85167 6.10621 7.75439 6.24345C7.64608 6.39627 7.64586 6.60975 7.77976 6.74073Z"
                fill="#0F284C"
            />
        </g>
        <defs>
            <clipPath id="clip0_708_11771">
                <rect width="10" height="10" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
