import {shallowEqual, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import {Skeleton} from "primereact/skeleton";
import {StoreState} from "src/reducers";
import {ScrollingSidebar} from "src/components/general/ScrollingSidebar";
import {SchedulerCard} from "src/components/general/settings/SchedulerCard";
import {IcuLanguage} from "src/components/general/settings/IcuLanguage";

interface IcuSettingsSelector {
    doIcusExist: boolean;
}

const IcuSettingsSelector = ({ icus }: StoreState): IcuSettingsSelector => ({
    doIcusExist: !!icus?.length || false
})

const IcuSettings = () => {
    const {t} = useTranslation();
    const {doIcusExist} = useSelector(IcuSettingsSelector, shallowEqual)

    const skeletonObj = {
        title: t("Loading..."),
        subTitle: t(""),
        headerComponent: <Skeleton height="15rem" className="skeleton"/>,
        sections: [
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> },
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> },
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> }
        ],
    };

    const scrollingSidebarObj = {
        title: t("ICU settings"),
        subTitle: t(
            "This settings are global and will affect all EyeControl devices that are associate with your facility. In this section you can define the default settings for your devices."
        ),
        sections: [
            { name: t("Communication"), element: <IcuLanguage /> },
            { name: t("Scheduler"), element: <SchedulerCard /> }
        ],
    };

    return <ScrollingSidebar devices={[]} scrollingSidebarObj={doIcusExist ? scrollingSidebarObj : skeletonObj}/>
}

export default IcuSettings