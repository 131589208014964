import React from "react";

export const MusicIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="3.5" cy="17.5" rx="3.5" ry="2.5" fill="#0F284C"/>
    <path d="M6 17L6 4" stroke="#0F284C" strokeWidth="2" strokeLinecap="round"/>
    <ellipse cx="15.5" cy="13.5" rx="3.5" ry="2.5" fill="#0F284C"/>
    <path d="M18 13L18 2" stroke="#0F284C" strokeWidth="2" strokeLinecap="round"/>
    <path d="M5 2.8673C5 2.36964 5.36593 1.94772 5.85858 1.87735L17.8586 0.16306C18.461 0.0769981 19 0.54446 19 1.15301V3.1327C19 3.63036 18.6341 4.05228 18.1414 4.12265L6.14142 5.83694C5.53899 5.923 5 5.45554 5 4.84699V2.8673Z" fill="#0F284C"/>
    </svg>
);