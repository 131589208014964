import moment from "moment";

class TimeService {
    
    private static _instance: TimeService;
    
    constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private getNth = (timestamp: number) => {
        if (timestamp > 3 && timestamp < 21) return 'th';
        switch (timestamp % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
    }
    
    public sortArrayByDate = (array: any) => {
        return array.sort((a: any, b: any) => {
          return  new Date(b.time).getTime() - new Date(a.time).getTime();
        });
      }
  
      public formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    public isWithinLastTwoDays(timestamp: number, timezone: string): boolean {
      const now = moment().tz(timezone);
      const startOfToday = now.clone().startOf('day');
      const startOfYesterday = startOfToday.clone().subtract(1, 'day');
      const timestampToCheck = moment.tz(timestamp, timezone);
      return timestampToCheck.isBetween(startOfYesterday, now, null, '[]');
  }
}

export default TimeService.Instance;