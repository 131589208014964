import { connect } from "react-redux";
import { UserModel } from "../../models/user.model";
import { StoreState } from "../../reducers";
import {
    ResourceAction,
    ResourcePermissionModel
} from "../../models/Authorization.model";
import React from "react";
import { checkResourcePermission } from "src/utils/generalMethods";

interface AuthorizationResourceWrapperProps {
    children: JSX.Element;
    resourceName: string;
    resourcesWithPermission?: ResourcePermissionModel[];
    isAuthorized?: boolean;
}

const mapStateToProps = ({ authInfo }: StoreState) => {
    return {
        resourcesWithPermission:  authInfo?.authorization?.permissions?.resources,
        isAuthorized: authInfo?.isAuthorized
    };
};

const _AuthorizationResourceWrapper = ({
    children,
    resourceName,
    resourcesWithPermission,
    isAuthorized
}: AuthorizationResourceWrapperProps) => {

    if (!isAuthorized) {
        return null;
    }

    const resourse = getCurrentResource(resourceName, resourcesWithPermission || []);
    
    if (!resourse) {
        return children;
    }

    if (checkResourcePermission(resourse, ResourceAction.READ)) {
        
        return React.cloneElement(children, { currentPermissionResource: resourse });
    }
    
    
    return null;

};

function getCurrentResource(
    resourceName: string,
    resourcePermissions: ResourcePermissionModel[],
) {
    const resourceObject = resourcePermissions.find(
        (res) => res.name === resourceName
    );
    return resourceObject;

}

export const AuthorizationResourceWrapper = connect(mapStateToProps, null)(_AuthorizationResourceWrapper);
