import React from "react";

export const EditButtonIcon = ({
    height = "5rem",
    width = "5rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="62" height="62" rx="9" stroke="#00B4CD" strokeWidth="2"/>
        <g clipPath="url(#clip0_5165_29380)">
            <path fillRule="evenodd" clipRule="evenodd" d="M35.0446 22.9849L40.701 17.3284L47.065 23.6924L41.4085 29.3489L35.0446 22.9849ZM32.2162 22.9849L15.243 39.958C15.0438 40.1572 14.9379 40.4312 14.9512 40.7126L15.3042 48.1378C15.3287 48.6528 15.7406 49.0647 16.2556 49.0892L23.6808 49.4422C23.9622 49.4556 24.2362 49.3496 24.4354 49.1504L49.1863 24.3995C49.5769 24.009 49.5769 23.3758 49.1863 22.9853L41.4082 15.2071C41.0176 14.8166 40.3845 14.8166 39.9939 15.2071L33.6304 21.5707L33.63 21.5703L32.2158 22.9845L32.2162 22.9849ZM33.6304 24.3991L39.9943 30.7631L23.334 47.4234L17.2588 47.1347L16.97 41.0595L33.6304 24.3991Z" fill="#0F284C"/>
        </g>
        <defs>
            <clipPath id="clip0_5165_29380">
                <rect width="36" height="36" fill="white" transform="translate(14 14)"/>
            </clipPath>
        </defs>
    </svg>
);
