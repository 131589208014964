export enum NOTIFICATIONS {
    POWER_OFF = "POWER_OFF",
    POWER_ON = "POWER_ON",
    CAM_ICU_RESULTS = "CAM_ICU_RESULTS",
    Q_AND_A_RESULTS = "Q_AND_A_RESULTS",
    TRAINING = "TRAINING",
    TECHNICAL_INDICATOR_ON = "TECHNICAL_INDICATOR_ON"
};

export enum TYPES {
    STATE = "STATE",
    RESULTS = "RESULTS"
}

export enum SEVERITIES {
    ACTION_REQUIRED = "ACTION_REQUIRED",
    ACTION_SUGGESTED = "ACTION_SUGGESTED",
    NEED_TO_KNOW = "NEED_TO_KNOW"
}

export interface NotificationConfig {
    notification: NOTIFICATIONS;
    severity: SEVERITIES;
    type: TYPES;
    text?: string;
    is_hidden?: boolean;
    remove? : NOTIFICATIONS[];
    styles?: Styles;
    isRead?: boolean;
}
export interface NotificationModel {
    patient_id?: string;
    tenant_id?: string;
    timestamp: number;
    _id?: string;
    configurations: NotificationConfig;
    url?: string
}

export interface Styles {
    [key: string]: string[];
}