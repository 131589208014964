import { ActiveCommunicationType } from "./device.model";

export interface LogModel {
    communication_id: string;
    communication_type_id: ActiveCommunicationType;
    device_id: string;
    message_type_id: number;
    time: Date;
    additional_info: any;
}

export enum MessageType {
    SENT_COMMUNICATION = 1,
    RECIVED_COMMUNICATION = 2,
    START_DOWNLOAD_COMMUNICATION = 3,
    END_DOWNLOAD_COMMUNUICATION = 4,
    START_COMMUNICATION = 5,
    END_COMMUNICATION = 6,
    ERROR_COMMUNUICATION = 7,
    STOP_COMMUNICATION = 8,
    START_NODE = 9,
    END_NODE = 10
}