import React from "react";

export const OOPIcon = ({
  height = "24px",
  width = "24px",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.04378 17.5818C9.01509 17.7167 9 17.8566 9 18C9 19.1046 9.89543 20 11 20H13C13.7523 20 14.4077 19.5846 14.7491 18.9706C16.3451 18.8423 17.6072 18.2982 18.563 17.4394C18.7499 17.2715 18.9223 17.094 19.0814 16.9086C19.3306 17.0282 19.6098 17.0952 19.9048 17.0952C20.9567 17.0952 21.8095 16.2424 21.8095 15.1905V15.1811C21.8722 15.1873 21.9357 15.1905 22 15.1905C23.0519 15.1905 23.9047 14.3377 23.9047 13.2857V11.381C23.9047 10.329 23.0519 9.4762 22 9.4762C21.9357 9.4762 21.8722 9.47938 21.8095 9.4856V9.47619C21.8095 8.62502 21.2512 7.90425 20.4809 7.6601C20.4348 7.45756 20.3875 7.27333 20.3411 7.11046C20.2469 6.78021 20.1424 6.44222 20.0249 6.10161L18.4442 7.75416C18.4765 7.87364 18.5089 8.00502 18.5406 8.1468C18.2061 8.49003 18 8.95904 18 9.47619V14.9879C17.7871 15.3539 17.5307 15.6782 17.2263 15.9517C16.66 16.4605 15.8633 16.8521 14.7124 16.9662C14.362 16.3871 13.7262 16 13 16H11C10.8257 16 10.6566 16.0223 10.4955 16.0642L9.04378 17.5818ZM15.9288 3.15281L17.3128 1.70594C16.1307 0.666731 14.5755 0 12.5533 0C10.1281 0 8.40184 0.927701 7.15485 2.33094C5.95116 3.68546 5.22925 5.44439 4.66862 7.05611C4.60414 7.24147 4.54516 7.42842 4.49132 7.61642C3.63813 7.80524 3 8.5662 3 9.47619L2.99998 9.4762C1.94801 9.4762 1.09521 10.329 1.09521 11.381V13.2857C1.09521 14.3377 1.94801 15.1905 2.99998 15.1905H3C3 15.6067 3.13351 15.9917 3.36003 16.3051C3.40337 16.2517 3.44918 16.1997 3.49747 16.1492L6.80952 12.6866V9.47619C6.80952 9.0211 6.64993 8.6033 6.38365 8.27568C6.43553 8.08561 6.49337 7.89783 6.5576 7.71319C7.10741 6.13259 7.7259 4.69919 8.64985 3.65947C9.5305 2.66846 10.7191 2 12.5533 2C14.0254 2 15.1028 2.45044 15.9288 3.15281Z" fill="#BE3535"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.84516 19.4796C3.45617 19.0876 3.4503 18.4462 3.83205 18.0471L19.7302 1.42638C20.1119 1.02728 20.7367 1.02156 21.1257 1.41361C21.5147 1.80566 21.5206 2.44701 21.1389 2.84612L5.24072 19.4669C4.85897 19.866 4.23415 19.8717 3.84516 19.4796Z" fill="#BE3535"/>
    </svg>
);