import { ToastModel } from "../models/toast.model";
import { ToastAction, toastActionTypes } from "../actions";

const initialState: ToastModel = {
    severity: undefined,
    summary: "",
    detail: "",
};

const TOAST_LIFE_TIME = 4000;

export const toastHandlerReducer = (state: ToastModel = initialState, action: ToastAction) => {
    switch (action.type) {
        case toastActionTypes.UPDATE_TOAST:
            return { life: TOAST_LIFE_TIME, ...action.payload }
        case toastActionTypes.CLEAR_TOAST:
            return initialState;
        default:
            return state;
    }
};