interface MainProps {
    children: JSX.Element;
}

export const Main = ({children} : MainProps): JSX.Element => {
    return (
        <div className="main">
            {children}
        </div>
    );
};
