import { QuestionActionTypes } from "../actions/types";
import { QuestionAction } from "../actions";
import { QuestionModel } from "../models/question.model";

export function questionReducer(
    state: QuestionModel[] = [],
    action: QuestionAction
): QuestionModel[] {
    switch (action.type) {
        case QuestionActionTypes.FETCH_CURRENT_QUESTIONS:
            return [...action.payload];
        default:
            return state;
    }
}
