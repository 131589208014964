// import axios from 'axios'
import { Dispatch } from 'redux';
import { DeviceActionTypes } from './types';
import { DeviceModel, DeviceStateModel, DeviceSettingsModel, WifiStrengthModel, WifiStrength, CommunicationLogModel, PatientModel, ActiveCommunicationType } from '../models/device.model';
import { NotificationModel, NOTIFICATIONS, SEVERITIES, TYPES } from '../models/notification.model';
import shadowApi from '../apis/shadow';
import IotService from "../services/iot.service";
import deviceApi from '../apis/device';
import patientApi from '../apis/patient';
import _ from 'lodash';
import { shadowMapping } from "eyecontrol-med-shared";
import { store } from '../Root';
import NotificationService from "../services/notification.service";
import communicationApi from './../apis/communication';
import { UpsertPatientDto } from './../models/form.model';
import { ZenObservable } from "zen-observable-ts";
import { getShadowName, isEnableView } from '../utils/generalMethods';
import amplifyService from '../services/amplifyService';
import { ViewTypes } from '../models/Authorization.model';

enum UI_UPDATE_TYPE {
    NOTIFICATION = "notification",
    COMMUNICATION_LOG = "communication_log" 
};

enum WifiDBM {
    DBM_FAIR_TO_GOOD = -58,
    DBM_FAIR = -60,
    DBM_GOOD_TO_FAIR = -62,
    DBM_WEAK_TO_FAIR = -68,
    DBM_WEAK = -70,
    DBM_FAIR_TO_WEAK = -72,
}


export const LAST_DBM_WEIGHT = 2;
export const PREV_DBM_WEIGHT_ON_TWO_ELEMENTS = 2;
export const PREV_DBM_WEIGHT_ON_THREE_ELEMENTS = 4;

export interface FetchDevicesAction {
    type: DeviceActionTypes.FETCH_DEVICES;
    payload: DeviceModel[];
}

export interface DeleteDevicesAction {
    type: DeviceActionTypes.DELETE_DEVICES;
}

export interface DeleteDeviceAction {
    type: DeviceActionTypes.DELETE_DEVICE;
    payload: string;
}

export interface SaveSubscriptionAction {
    type: DeviceActionTypes.SAVE_SUBSCRIPTION;
    payload: {
        topic: string,
        subscription: ZenObservable.Subscription
    };
}

export interface UpdateReportedSettingsAction {
    type: DeviceActionTypes.UPDATE_REPORTED_SETTINGS;
    payload: {
        shadowName: string,
        deviceId: string,
        settings: DeviceSettingsModel,
        version: number
    };
}

export interface UpdateDeltaSettingsAction {
    type: DeviceActionTypes.UPDATE_DELTA_SETTINGS;
    payload: {
        shadowName: string,
        deviceId: string,
        settings: DeviceSettingsModel,
        version: number
    };
}

export interface UpdateReportedStateAction {
    type: DeviceActionTypes.UPDATE_REPORTED_STATE;
    payload: {
        shadowName: string
        deviceId: string,
        state: DeviceStateModel
    };
}

export interface UpdateDeviceModelAction {
    type: DeviceActionTypes.UPDATE_DEVICE_MODEL;
    payload: {
        state: DeviceModel | Partial<DeviceModel>
    };
}

export interface UpdatePatientAction {
    type: DeviceActionTypes.UPDATE_PATIENT;
    payload: {
        oldDevice: DeviceModel;
        newDevice: DeviceModel;
    };
}

export interface UpdatePatientImageAction {
    type: DeviceActionTypes.UPDATE_PATIENT_IMAGE;
    payload: {
        deviceId: string;
        image: string;
    };
}

export interface RemoveSubscriptionsAction {
    type: DeviceActionTypes.REMOVE_SUBSCRIPTIONS;
}

export interface UpdateWifiStrengthAction {
    type: DeviceActionTypes.UPDATE_WIFI_STRENGTH;
    payload: {
        state: DeviceModel
    };
}

export interface UpdateActiveCommunicationAction {
    type: DeviceActionTypes.UPDATE_ACTIVE_COMMUNICATION;
    payload: {
        deviceId: string,
        type: ActiveCommunicationType
    };
}

export interface UpdateCommunicationLogsAction {
    type: DeviceActionTypes.UPDATE_COMMUNICATION_LOGS;
    payload: {
        deviceId: string;
        communicationLogs: CommunicationLogModel[];
    };
}

export interface UpdateNotificationsAction {
    type: DeviceActionTypes.UPDATE_NOTIFICATIONS;
    payload: {
        deviceId: string;
        notifications: NotificationModel[];
    };
}

export interface UpdateQNAStatusAction {
    type: DeviceActionTypes.UPDATE_QNA_STATUS;
    payload: {
        device: DeviceModel
    };
}

export interface AttachPatientToDeviceAction {
    type: DeviceActionTypes.ATTACH_PATIENT_TO_DEVICE;
    payload: {
        deviceId: string;
        patient: PatientModel;
    };
}

export interface DetachPatientFromDeviceAction {
    type: DeviceActionTypes.DETACH_PATIENT_FROM_DEVICE;
    payload: {
        deviceId: string;
    };
}

export type DeviceAction =
    | FetchDevicesAction
    | DeleteDeviceAction
    | UpdateReportedSettingsAction
    | UpdateDeltaSettingsAction
    | UpdateReportedStateAction
    | SaveSubscriptionAction
    | UpdateDeviceModelAction
    | UpdatePatientAction
    | UpdatePatientImageAction
    | RemoveSubscriptionsAction
    | UpdateWifiStrengthAction
    | UpdateCommunicationLogsAction
    | UpdateNotificationsAction
    | AttachPatientToDeviceAction
    | DetachPatientFromDeviceAction
    | UpdateQNAStatusAction
    | DeleteDevicesAction;

export const detachDevice = (deviceId: string, callBack: Function) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await deviceApi.patch(`${deviceId}/reset`, {});
            
            if(response.status === 200) {
                unsubscribeOldDevice(deviceId, dispatch);
                dispatch<DetachPatientFromDeviceAction>({
                    type: DeviceActionTypes.DETACH_PATIENT_FROM_DEVICE,
                    payload: {
                        deviceId
                    }
                });

                callBack(true);
            }
        } catch (e) {
            console.log(e);
            callBack(false);
        }
    };
};

export const updatePatientImage = (deviceId: string, newImage: string) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdatePatientImageAction>({
            type: DeviceActionTypes.UPDATE_PATIENT_IMAGE,
            payload: {
                deviceId,
                image: newImage
            }
        });
    }
} 

export const updatePatientDetails = (deviceId: string, patientDetails: UpsertPatientDto, views: ViewTypes[] | undefined,
    callBack: (result: { status: boolean; error?: any }, page?: string) => void
    ) => {
    return async (dispatch: Dispatch) => {
        const devices = store.getState()?.deviceObject.devices;
        const device: DeviceModel | undefined = devices.find(device => device.id === deviceId)
        const newDevice: DeviceModel | undefined = devices.find(device => device.id === patientDetails.device_id)
        if(device) {
            try {
                const oldSettings = {...device.shadow.settings};
                amplifyService.log(`updating patient ${device.patient?._id}: ${JSON.stringify(patientDetails)}...`)
                const response = await patientApi.patch(`/${device.patient?._id}`, patientDetails);
                if(response.status === 200) {
                    amplifyService.log(`patient ${device.patient?._id} was updated successfully`);
                    let updatedDevice: DeviceModel | undefined;
                    if(newDevice && newDevice.id !== device.id) {
                        amplifyService.log(`updating new device ${newDevice.id} shadows..`)
                        updatedDevice = await addValuesFromShadowToDevice(newDevice);
                        amplifyService.log(`shadow of device ${newDevice.id} was updated`)
                        amplifyService.log(`override new device ${newDevice.id} settings with old device ${device.id} settings: ${JSON.stringify(oldSettings)}`)
                        updatedDevice.shadow.settings = {...oldSettings};
                    }
                    
                    updatedDevice = populateDeviceFields(device, patientDetails, newDevice);
                    dispatch<UpdatePatientAction>({
                        type: DeviceActionTypes.UPDATE_PATIENT,
                        payload: {
                            oldDevice: device,
                            newDevice: updatedDevice 
                        }
                    });
    
                    let page: string | undefined = undefined;
                    
                    if(patientDetails.device_id && newDevice && newDevice.id !== device.id) {
                        amplifyService.log(`updating subscription of new device ${newDevice.id}`)
                        replaceSubscription(newDevice, deviceId, dispatch);
                        isEnableView(ViewTypes.NURSE, views) ? 
                            page = `/neartobedpatientview/${newDevice.id}` : 
                            page = `/patientview/${newDevice.id}`;
                    }

                    callBack({
                        status: true,
                        error: null,
                    }, page);
                }
            } catch (e: any) {
                console.log(e);
                callBack({
                    status: false,
                    error: e?.response?.data?.message
                });
            }
        }
    };
};

const replaceSubscription = (newDevice: DeviceModel, oldDeviceId: string, dispatch: Dispatch) => {
    registerToDeviceUpdates(newDevice, dispatch)
    unsubscribeOldDevice(oldDeviceId, dispatch);
}

export const unsubscribeOldDevice = (oldDeviceId: string, dispatch: Dispatch) => {
    const subscriptions = store.getState().deviceObject.subscriptions;
    subscriptions.map((sub) => {
        if(sub.topic.includes(oldDeviceId)) {
            IotService.unsubscribeToTopic(sub.subscription);
        }
    });

    dispatch({
        type: DeviceActionTypes.REMOVE_SUBSCRIPTIONS
    });
}
const populateDeviceFields = (device: DeviceModel, patientDetails: UpsertPatientDto, newDevice: DeviceModel | undefined) => {
    if (newDevice && patientDetails.device_id && newDevice.id !== device.id) {
        newDevice.notifications = [...device.notifications];
        device.notifications = [];
        if(device.communicationLogs) {
            newDevice.communicationLogs = [...device.communicationLogs];
        }
        device.communicationLogs = [];
        device.shadow.settings = {};
        device.shadow.state = {};

        if (patientDetails.patient_voice_id) {
            newDevice.shadow.settings.patient_voice_id = patientDetails.patient_voice_id;
        }
    
        if(device.patient) {
            newDevice.patient = _.merge(newDevice.patient, device.patient);
            if (patientDetails.first_name) {
                newDevice.patient.first_name = patientDetails.first_name;
            }
    
            newDevice.patient.room_number = patientDetails.room_number;
        }
        
        delete device.patient;
        return newDevice;
    } else {
        if (patientDetails.patient_voice_id) {
            device.shadow.settings.patient_voice_id = patientDetails.patient_voice_id;
        }
    
        if(device.patient) {
            if (patientDetails.first_name) {
                device.patient.first_name = patientDetails.first_name;
            }
    
            device.patient.room_number = patientDetails.room_number;
        }
    }

    return device;
}

export const fetchDevices = () => {
    return async (dispatch: Dispatch) => {
        try {

            dispatch<DeleteDevicesAction>({
                type: DeviceActionTypes.DELETE_DEVICES
            });
            
            const response = await deviceApi.get("/");

            const devices: DeviceModel[] = response.data;

            
            const fetchAdditionalFieldsPromises = devices.map(
                (device: DeviceModel) => {
                    if (device.patient) {
                        return addAdditionalFieldsToDevicePromise(
                            device,
                            dispatch
                        );
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(device);
                        });
                    }
                }
            );

            const updatedDevices: DeviceModel[] =  await Promise.all(fetchAdditionalFieldsPromises) as DeviceModel[];

            dispatch<FetchDevicesAction>({
                type: DeviceActionTypes.FETCH_DEVICES,
                payload: updatedDevices,
            });

        } catch (e) {
            console.log(e);
        }
    };
};

export const addAdditionalFieldsToDevicePromise = async (
    device: DeviceModel,
    dispatch: Dispatch
) => {
    return new Promise(async (resolve, reject) => {
        try {
            let updatedDevice: DeviceModel = await addValuesFromShadowToDevice (
                device
            );
            updatedDevice = await NotificationService.addNotificationToDevice (
                updatedDevice,
                updatedDevice.patient?._id
            );
            updatedDevice = wifiStrengthMapping(
                updatedDevice.shadow.state.wifi_dBm,
                updatedDevice
            );
            updatedDevice.communicationLogs = (
                await communicationApi.get(
                    `/uilog/patient/${device.patient?._id}/`
                )
            ).data;
            registerToDeviceUpdates(updatedDevice, dispatch);          
            resolve(updatedDevice) ;
        } catch (error) {
            console.log(error);
            reject(device);
        }
    
    });
};

export const attachPatientToDevice = (
    patientWithDeviceId: UpsertPatientDto,
    callBack: (result: { status: boolean; error?: any }) => void
) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await patientApi.post("/", patientWithDeviceId);
            const updatedDevice = await addAdditionalFieldsToDevicePromise(response.data.deviceWithPatient, dispatch) as DeviceModel;

            dispatch<UpdateDeviceModelAction>({
                type: DeviceActionTypes.UPDATE_DEVICE_MODEL,
                payload: {
                    state: updatedDevice
                }
            });

            callBack({
                status: true,
                error: null,
            });
        } catch (e: any) {
            console.log(e);
            callBack({
                status: false,
                error: e?.response?.data?.message
            });
        }
    };
};

export const deleteDevice = (id: string): DeleteDeviceAction => {
    return {
        type: DeviceActionTypes.DELETE_DEVICE,
        payload: id,
    };
};

export const updateActiveCommunication = (deviceId: string, type: ActiveCommunicationType): UpdateActiveCommunicationAction => {
    return {
        type: DeviceActionTypes.UPDATE_ACTIVE_COMMUNICATION,
        payload: {
            deviceId,
            type
        }
    }
}

export const updateReportedSettings = (deviceId: string, shadowName: string, deviceSettings: DeviceSettingsModel, version: number) => {
    return {
        type: DeviceActionTypes.UPDATE_REPORTED_SETTINGS,
        payload: {
            shadowName,
            deviceId,
            settings: deviceSettings,
            version
        }
    }
}

export const updateDeltaSettings = (deviceId: string, shadowName: string, deviceSettings: DeviceSettingsModel, version: number) => {
    return {
        type: DeviceActionTypes.UPDATE_DELTA_SETTINGS,
        payload: {
            shadowName,
            deviceId,
            settings: deviceSettings,
            version
        }
    }
}

export const updateReportedState = (deviceId: string, shadowName: string, deviceState: DeviceStateModel) => {
    return {
        type: DeviceActionTypes.UPDATE_REPORTED_STATE,
        payload: {
            shadowName,
            deviceId,
            state: deviceState
        }
    }
}

export const updateWifiStrength = (deviceState: DeviceModel) => {
    return {
        type: DeviceActionTypes.UPDATE_WIFI_STRENGTH,
        payload: {
            state: deviceState
        }
    }
}

export const updateCommunicatonLog = (
    deviceId: string,
    communicationLogs: CommunicationLogModel[]
) => {
    return {
        type: DeviceActionTypes.UPDATE_COMMUNICATION_LOGS,
        payload: {
             deviceId,
             communicationLogs
        },
    };
};

export const updateNotification = (
    deviceId: string,
    notificationToAdd: NotificationModel
) => {
    const devices = store.getState().deviceObject.devices;
    const device = devices.filter(device => device.id === deviceId)[0];
    device.notifications.unshift(notificationToAdd);
    const notifications = NotificationService.getNotifications(device.notifications);

    return {
        type: DeviceActionTypes.UPDATE_NOTIFICATIONS,
        payload: {
            deviceId, 
            notifications
        },
    };
};

export const updateQNAStatus = (
    device: DeviceModel
) => {
    return {
        type: DeviceActionTypes.UPDATE_QNA_STATUS,
        payload: {
            device
        },
    };
};

const mergeShadowDeltaWithReported = (shadowData: any) => {
    let reported = shadowData.reported ? shadowData.reported : {};
    let delta = shadowData.delta ? shadowData.delta : {};
    return _.merge(reported, delta);
}

const addValuesFromShadowToDevice = async (device: DeviceModel) => {
    let updatedDevice = device;

    updatedDevice.shadow = {
        settings: {},
        state: {},
    };

    for (let shadowKey in shadowMapping) {
        const response = await shadowApi.get(`${device.id}/${shadowKey}`);
        let mergedShadow = mergeShadowDeltaWithReported(response.data);

        if (response.status === 200) {
            let settings = mergedShadow.settings ? mergedShadow.settings : {};
            let state = mergedShadow.state ? mergedShadow.state : {};
            updatedDevice.shadow.settings = _.assign(
                updatedDevice.shadow.settings,
                settings
            );
            updatedDevice.shadow.state = _.assign(updatedDevice.shadow.state, state);
        }
    }

    return updatedDevice;
};

const getShadowNameFromSettings = (settingsName: string) => {
    for (const shadowkey in shadowMapping) {
        let foundItem = shadowMapping[shadowkey as keyof typeof shadowMapping].settings.find(item => settingsName === item);

        if (foundItem) {
            return shadowkey;
        }
    }

    return null;
}

export const updateSettingsField = async (deviceId: string, settingsName: string, item: any) => {
    let shadowName = getShadowNameFromSettings(settingsName);
    if (!shadowName || !deviceId) {
        console.log("updateSettingsField: ", "shadowName || deviceId is not defined");
        return   
    }

    let deviceSettingsObj = {
        shadowName,
        settings: item
    }

    return await deviceApi.post("settings", {thingName: deviceId, deviceSettings: [deviceSettingsObj]});
}

const subscribeToUiUpdates = (
    device: DeviceModel,
    dispatch: Dispatch
) => {
    const topic = `${device.tenant_id}/${device.icu_id}/${device.id}/uiupdates`;
    const uiSubscrition =  IotService.subscribeToTopic(
        topic,
        (data) => {
            if (data.value.type === UI_UPDATE_TYPE.COMMUNICATION_LOG) {
                dispatch(updateCommunicatonLog(device.id, data.value.communicatoinLogs));
            }

            if (data.value.type === UI_UPDATE_TYPE.NOTIFICATION) {
                dispatch(updateNotification(device.id, data.value.data));
            }
        },
        (err) => {
            console.log(`Error: ${JSON.stringify(err)}`);
        },
        () => {
            _reconnectToCommunicationLog(device, dispatch);
        }
    );

    dispatch({
        type: DeviceActionTypes.SAVE_SUBSCRIPTION,
        payload: {
            subscription: uiSubscrition,
            topic
        }
    });
};

const subscribeToShadow = (
    updatedDevice: DeviceModel,
    dispatch: Dispatch
) => {
    const documentsTopic = `${updatedDevice.tenant_id}/${updatedDevice.icu_id}/${updatedDevice.id}/+/update/documents`;
    const documentShadowSubscrition = IotService.subscribeToTopic(
        documentsTopic,
        (data) => {
            const topicValue = getTopic(data);
            const shadowName = getShadowName(topicValue);
            if (data.value.current?.state?.reported?.settings) {
                // amplifyService.log(`updating reported setting: ${JSON.stringify(data.value.current.state.reported.settings)}`)
                dispatch(
                    updateReportedSettings(
                        updatedDevice.id,
                        shadowName,
                        data.value.current.state.reported.settings,
                        data.value.current.version
                    )
                );
            }
            if (data.value.current?.state?.reported?.state) {
                handleTechnicalErrorNotification(data, updatedDevice, dispatch);
                dispatch(
                    updateReportedState(
                        updatedDevice.id,
                        shadowName,
                        data.value.current.state.reported.state
                    )
                );
                _setWifiStrengthState(data.value.current.state.reported.state.wifi_dBm, updatedDevice, dispatch);
            }
        },
        (err) => {
            console.log(`Error: ${JSON.stringify(err)}`);
        },
        () => {
            _reconnectToShadowUpdates(updatedDevice, dispatch);
        }
    );

    const deltaTopic = `${updatedDevice.tenant_id}/${updatedDevice.icu_id}/${updatedDevice.id}/+/update/delta`;
    const deltaShadowSubscrition = IotService.subscribeToTopic(
        deltaTopic,
        (data) => {
            if (data.value.state?.settings) {
                const topicValue = getTopic(data);
                const shadowName = getShadowName(topicValue);
                // amplifyService.log(`updating delta setting: ${JSON.stringify(data.value.state.settings)}`);
                dispatch(
                    updateDeltaSettings(
                        updatedDevice.id,
                        shadowName,
                        data.value.state.settings,
                        data.value.version
                    )
                );
            }
        },
        (err) => {
            console.log(`Error: ${JSON.stringify(err)}`);
        },
        () => {
            _reconnectToShadowUpdates(updatedDevice, dispatch);
        }
    );

    dispatch({
        type: DeviceActionTypes.SAVE_SUBSCRIPTION,
        payload: {
            subscription: documentShadowSubscrition,
            topic: documentsTopic
        }
    });
    dispatch({
        type: DeviceActionTypes.SAVE_SUBSCRIPTION,
        payload: {
            subscription: deltaShadowSubscrition,
            topic: deltaTopic
        }
    });
};

const _setWifiStrengthState = (wifi_dBm: number | undefined, updatedDevice: DeviceModel, dispatch: Dispatch)  => {
    if(wifi_dBm === undefined) return;
    
    const deviceModel: DeviceModel = wifiStrengthMapping(wifi_dBm, updatedDevice);
    dispatch(updateWifiStrength(deviceModel));
}

export const wifiStrengthMapping = (wifi_dBm: number | undefined, updatedDevice: DeviceModel): DeviceModel => {
    if(wifi_dBm === undefined) {
        return updatedDevice;
    }

    const devices = store.getState().deviceObject.devices;
    const device: DeviceModel | undefined = devices.find((device: DeviceModel) => device.id === updatedDevice.id)
    
    if (!updatedDevice.wifiStrength) {
        updatedDevice.wifiStrength = {} as WifiStrengthModel;
    }

    if (device && device.wifiStrength && updatedDevice.wifiStrength) {
        updatedDevice.wifiStrength.first = device.wifiStrength.middle;
        updatedDevice.wifiStrength.middle = device.wifiStrength.last;
        updatedDevice.wifiStrength.previousAvg = device.wifiStrength.currentAvg;
    }
    
    updatedDevice.wifiStrength.last = wifi_dBm;
    updatedDevice.wifiStrength.currentAvg = calculateWifiCurrentAvg(wifi_dBm, device);
    updatedDevice.wifiStrength.current = calculateCurrentWifiLevel(updatedDevice.wifiStrength);
    
    return updatedDevice;
}

export const calculateCurrentWifiLevel = ({previousAvg, currentAvg, current}: WifiStrengthModel) => {
    if (!previousAvg) {
        if (currentAvg >= WifiDBM.DBM_FAIR) {
            return WifiStrength.GOOD;
        }
        if (currentAvg < WifiDBM.DBM_WEAK) {
            return WifiStrength.WEAK;
        }
        return WifiStrength.FAIR;
    } else {
        if (currentAvg >= WifiDBM.DBM_FAIR_TO_GOOD && previousAvg <= WifiDBM.DBM_FAIR_TO_GOOD) {
            return WifiStrength.GOOD;
        }
        if ((currentAvg >= WifiDBM.DBM_WEAK_TO_FAIR && currentAvg <= WifiDBM.DBM_FAIR_TO_GOOD 
                && previousAvg <= WifiDBM.DBM_WEAK_TO_FAIR) ||
            (currentAvg >= WifiDBM.DBM_FAIR_TO_WEAK && currentAvg <= WifiDBM.DBM_GOOD_TO_FAIR 
                && previousAvg >= WifiDBM.DBM_GOOD_TO_FAIR)) {
                return WifiStrength.FAIR;
        }
        else if (currentAvg <= WifiDBM.DBM_FAIR_TO_WEAK && previousAvg >= WifiDBM.DBM_FAIR_TO_WEAK) {
            return WifiStrength.WEAK;
        }
        return current
    }
}

export const calculateWifiCurrentAvg = (last: number, device: DeviceModel | undefined) => {
    if(device && device.wifiStrength) {
        const {first, middle} = device.wifiStrength;
        if(first) {
            return (first + middle)/PREV_DBM_WEIGHT_ON_THREE_ELEMENTS + last/LAST_DBM_WEIGHT;
        }
        if(middle) {
            return middle/PREV_DBM_WEIGHT_ON_TWO_ELEMENTS + last/LAST_DBM_WEIGHT;
        }
    }
    return last;
}

const registerToDeviceUpdates = (updatesDevice: DeviceModel, dispatch: Dispatch) => {
    const subscriptions = store.getState().deviceObject.subscriptions;
    const isSubscribed = subscriptions.some( 
        subscription => subscription.topic.includes(updatesDevice.id)
    )
    if (isSubscribed) {
        return;
    }
    subscribeToShadow(updatesDevice, dispatch);
    subscribeToUiUpdates(updatesDevice, dispatch);
};

const _reconnectToShadowUpdates = (
    updatesDevice: DeviceModel,
    dispatch: Dispatch
) => {
    setTimeout(() => {
        subscribeToShadow(updatesDevice, dispatch);
    }, 10000);
};

const _reconnectToCommunicationLog = (
    device:DeviceModel,
    dispatch: Dispatch
) => {
    setTimeout(() => {
        subscribeToUiUpdates(device, dispatch);
    }, 10000);
};

const handleTechnicalErrorNotification = (data: any, updatedDevice: DeviceModel, dispatch: Dispatch) => {
    
    const isTechnicalErrorNotificationExists = updatedDevice.notifications.some(
        (notification: NotificationModel) => notification.configurations.notification === NOTIFICATIONS.TECHNICAL_INDICATOR_ON)
    
    if ((!isTechnicalErrorNotificationExists && 
            data.value.current.state.reported.state.is_healthy === false)) {
        addTechnicalErrorNotification(updatedDevice, data.value.current.state.reported.state.is_healthy_timestamp);
    }
    else if (isTechnicalErrorNotificationExists &&
            data.value.current.state.reported.state.is_healthy === true) {
        const notifications = updatedDevice.notifications.filter((notification: NotificationModel) => 
            notification.configurations.notification !== NOTIFICATIONS.TECHNICAL_INDICATOR_ON);
        dispatch({
            type: DeviceActionTypes.UPDATE_NOTIFICATIONS,
            payload: {
                deviceId: updatedDevice.id,
                notifications
            },
        });
    }
}

export const addTechnicalErrorNotification = (updatedDevice: DeviceModel, timestamp: number) => {
    updateNotification(updatedDevice.id, {
        patient_id: updatedDevice?.patient?._id,
        tenant_id: updatedDevice.tenant_id,
        timestamp: timestamp,
        configurations: {
            notification: NOTIFICATIONS.TECHNICAL_INDICATOR_ON,
            severity: SEVERITIES.ACTION_REQUIRED,
            type: TYPES.STATE,
            text: "The device is unavailable due to a technical reason. Contact support."
        },
    });
}

function getTopic(data: any) {
    let topicValue = data.value.topicSymbol;
    if (!topicValue) {
        const value = data.value;
        Object.getOwnPropertySymbols(value).forEach(symbol => {

            if (symbol.toString() === 'Symbol(topic)') {
                topicValue = value[symbol];
            }
        });
    }
    return topicValue;
}

