import React from "react";

export const WifiNoConnectionIcon = ({
  height = "24px",
  width = "24px",
  color = "#0F284C",
  fill= "#fff",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2199_36505)">
  <mask id="mask0_2199_36505" mask-type="alpha" maskUnits="userSpaceOnUse" x="-4" y="2" width="18" height="17">
  <path fillRule="evenodd" clipRule="evenodd" d="M5 17.5833C8.91202 17.5833 12.0833 14.412 12.0833 10.5C12.0833 6.58798 8.91202 3.41667 5 3.41667C1.08798 3.41667 -2.08333 6.58798 -2.08333 10.5C-2.08333 14.412 1.08798 17.5833 5 17.5833ZM5 19C9.69442 19 13.5 15.1944 13.5 10.5C13.5 5.80558 9.69442 2 5 2C0.305579 2 -3.5 5.80558 -3.5 10.5C-3.5 15.1944 0.305579 19 5 19Z" fill="#D23A3A"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M4.99992 14.75C7.34713 14.75 9.24992 12.8472 9.24992 10.5C9.24992 8.15279 7.34713 6.24999 4.99992 6.24999C2.65271 6.24999 0.749919 8.15279 0.749919 10.5C0.749919 12.8472 2.65271 14.75 4.99992 14.75ZM4.99992 16.1667C8.12953 16.1667 10.6666 13.6296 10.6666 10.5C10.6666 7.37038 8.12953 4.83333 4.99992 4.83333C1.87031 4.83333 -0.666748 7.37038 -0.666748 10.5C-0.666748 13.6296 1.87031 16.1667 4.99992 16.1667Z" fill="#D23A3A"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M5.00005 14.75C6.30406 14.75 7.36117 13.6929 7.36117 12.3889C7.36117 11.0849 6.30406 10.0278 5.00005 10.0278C3.69605 10.0278 2.63894 11.0849 2.63894 12.3889C2.63894 13.6929 3.69605 14.75 5.00005 14.75ZM5.00005 17.1111C7.60807 17.1111 9.72228 14.9969 9.72228 12.3889C9.72228 9.78088 7.60807 7.66667 5.00005 7.66667C2.39204 7.66667 0.277832 9.78088 0.277832 12.3889C0.277832 14.9969 2.39204 17.1111 5.00005 17.1111Z" fill="#D23A3A"/>
  </mask>
  <g mask="url(#mask0_2199_36505)">
  <path d="M5.40255 9.48149C5.20272 9.75269 4.79733 9.75269 4.5975 9.48149L-1.61108 1.05556H11.6111L5.40255 9.48149Z" fill="#D23A3A"/>
  </g>
  </g>
  <path d="M1.00112 8.44285C0.615136 8.84637 0.621075 9.49483 1.01437 9.89122C1.40768 10.2876 2.03941 10.2818 2.42539 9.8783L9.53089 2.44984C9.91687 2.04632 9.91093 1.39786 9.51763 1.00147C9.12433 0.605081 8.49259 0.610864 8.10661 1.01439L1.00112 8.44285Z" fill="#D23A3A" stroke={fill} strokeLinejoin="round"/>
  <defs>
  <clipPath id="clip0_2199_36505">
  <rect width="10" height="10" fill="white" transform="translate(0 1)"/>
  </clipPath>
  </defs>
  </svg>
);