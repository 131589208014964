import React from "react";

export const ResultsIcon = ({
  height = "2rem",
  width = "2rem",
  color = "black",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.75" y="0.75" width="10.5" height="13.5" rx="0.75" stroke="#0F284C" strokeWidth="1.5"/>
  <rect x="3" y="4.5" width="6" height="1.5" rx="0.75" fill="#0F284C"/>
  <rect x="3" y="8.25" width="6" height="1.5" rx="0.75" fill="#0F284C"/>
  </svg>
);