import i18next from 'i18next'
import {Dispatch} from "redux";
import {IcuModel, IcuSettingsModel} from "../models/icu.model";
import {IcuActionTypes, updateToast} from ".";
import icusApi from '../apis/icus';
import {TOAST_SEVERITY} from "src/models/toast.model";

export interface FetchIcuAction {
    type: IcuActionTypes.FETCH_ICUS;
    payload: IcuModel[];
}

export interface IcuSettingsAction {
    type: IcuActionTypes.SET_ICU_SETTINGS;
    payload: {
        icuId: string,
        icuSettings: IcuSettingsModel
    };
}

export type IcuAction = FetchIcuAction | IcuSettingsAction;

export const getIcus = (jwtToken: string) => {
    return async (dispatch: Dispatch) => {
        await icusApi.get("/", {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            }
        }).then((response) => {
            if (response.status === 200) {
                setIcusState(dispatch, response.data);
            }
        }).catch(((error) => {
            if (error.response.status === 404) {
                dispatch(updateToast({
                    severity: TOAST_SEVERITY.ERROR,
                    summary: i18next.t("Something went wrong!"),
                    detail: i18next.t("Looks like current user has no relation to any ICU"),
                    sticky: true
                }))
            }
        }))
    }
}

export const setIcuSettings = (settings: any, icuId: string) => {
    return async (dispatch: Dispatch) => {
        const response = await icusApi.patch(`${icuId}`, {settings});

        if (response.status === 200) {
            _setIcusSettings(dispatch, settings, icuId);
        }
    }
}

const setIcusState = (dispatch: Dispatch, icus: IcuModel[] ) => {
    dispatch<IcuAction>({
        type: IcuActionTypes.FETCH_ICUS,
        payload: icus,
    });
}

const _setIcusSettings = (dispatch: Dispatch, icuSettings: IcuSettingsModel, icuId: string ) => {
    dispatch<IcuAction>({
        type: IcuActionTypes.SET_ICU_SETTINGS,
        payload: {icuId, icuSettings}
    });
}