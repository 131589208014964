import axios from 'axios';
import { BASE_HEADERS, BASE_URL } from '../globals';

let AUTH_URL: string = `${BASE_URL}/user/`;

export default axios.create({
    baseURL: AUTH_URL,
    headers: {
        ...BASE_HEADERS
    }
});