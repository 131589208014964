export const getToken = () => {
    return localStorage.getItem("token");
};

export const getRole = () => {
    const role = localStorage.getItem("role");

    return role ? JSON.parse(role) : null;
};

export const getCurrentUser = () => {
    const user = localStorage.getItem("user");

    return user ? JSON.parse(user) : null;
};