import React from "react";

export const SquareIcon = ({
    height = "24px",
    width = "24px",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg  className="square-icon" width="15" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="2" height="8" rx="1" fill="white"/>
<rect x="4" width="2" height="8" rx="1" fill="white"/>
</svg>



);

