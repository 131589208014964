import { useEffect, useState } from "react";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateSettingsField } from "../../../actions/device.action";
import { DeviceModel } from "../../../models/device.model";
import { Slider } from 'primereact/slider';
import { VolumeBigIcon } from "../../../icons/VolumeBig";
import { VolumeSmallIcon } from "../../../icons/VolumeSmall";
import { SettingsConstants } from "../../../constans";

interface DeviceEarphoneVolumeProps {
    devices?: DeviceModel[];
}

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _DeviceEarphoneVolume = ({
    devices,
}: DeviceEarphoneVolumeProps): JSX.Element => {
    const { t } = useTranslation();
    let params = useParams();
    let [volumeValue, setVolumeValue] = useState<number>(1);
    let [isCurrentValueFromShadow, setIsCurrentValueFromShadow] =
        useState<boolean>(false);
    let [isSliderDisabled, setIsSliderDisabled] = useState<boolean>(true);
    let deviceId = params.deviceId ? params.deviceId : "";

    const onVolumeChange = (value: number) => {
        if (value !== volumeValue) {
            setVolumeValue(value);
            updateSettingsField(deviceId, "earphone_volume", {
                earphone_volume: value,
            });
        }
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);
        if (
            currentDevice &&
            currentDevice?.shadow?.settings?.earphone_volume &&
            (!isCurrentValueFromShadow || currentDevice.isReset)
        ) {
            setVolumeValue(currentDevice.shadow.settings.earphone_volume);
            setIsSliderDisabled(false);
            setIsCurrentValueFromShadow(true);
        }
    }, [devices]);

    return (
        <div className="device-earphone-volume card-container">
            <div className="device-earphone-volume__header-wrapper">
                <div className="device-earphone-volume__header">
                    {t("Earphone Volume")}
                </div>
                <div className="device-earphone-volume__sub-title">
                    {t(
                        "Set the volume of the communication the patient hears through the earphone."
                    )}
                </div>
            </div>

            <div className="device-earphone-volume__box">
                <div className="device-earphone-volume__slider-wrapper">
                    <div className="device-earphone-volume__icon">
                        <VolumeSmallIcon />
                    </div>
                    <div className="device-earphone-volume__slider">
                        <Slider
                            disabled={isSliderDisabled}
                            min={SettingsConstants.DEFAULT_MIN_VOLUME}
                            max={SettingsConstants.DEFAULT_MAX_VOLUME}
                            value={volumeValue}
                            onChange={(e: any) => {
                                onVolumeChange(e.value);
                            }}
                        />
                    </div>
                    <div className="device-earphone-volume__icon">
                        <VolumeBigIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const DeviceEarphoneVolume = connect(
    mapStateToProps,
    null
)(_DeviceEarphoneVolume);
