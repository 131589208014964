import React from "react";

export const GeneralMessageIcon = ({
    height = "2.4rem",
    width = "2.4rem",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 5C0.5 4.17157 1.17157 3.5 2 3.5H12C12.8284 3.5 13.5 4.17157 13.5 5V10.2308C13.5 11.0592 12.8284 11.7308 12 11.7308H4.19552C3.63246 11.7308 3.08588 11.9208 2.64432 12.2702L2.95456 12.6623L2.64432 12.2702L0.5 13.9668L0.5 5Z"
            stroke="#0F284C"
        />
        <path
            d="M2 2H13C14.1046 2 15 2.89543 15 4V11"
            stroke="#0F284C"
            strokeLinecap="round"
        />
        <rect x="3" y="6" width="5" height="1" rx="0.5" fill="#0F284C" />
        <rect x="3" y="8" width="8" height="1" rx="0.5" fill="#0F284C" />
    </svg>
);
