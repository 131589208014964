import { RadioButton } from "primereact/radiobutton";
import { useEffect } from "react";

interface MedRadioButtonsProps {
    input: { value: number; onChange: Function };
    label?: string;
    placeholder: string;
    meta?: any;
    name: string;
    checked: boolean;
    inputId: string;
    options: any[];
    getUpdatedValue: Function;
}

export const MedRadioButtons = ({
    input: { value, onChange },
    meta: { touched, error, warning },
    name,
    inputId,
    options,
    getUpdatedValue
}: MedRadioButtonsProps) => {
    useEffect(() => {
        if(!value) {
            getUpdatedValue(options[0].value);
            onChange(options[0].value);
        }
    }, [value]);

    return (
        <div className="med-radio-buttons">
            {options.map((option) => (
                <div key={option?.value} className="med-radio-button">
                    <RadioButton
                        inputId={inputId}
                        name={name}
                        value={value}
                        onChange={(e: any) => {
                            getUpdatedValue(option.value);
                            onChange(option.value);
                        }}
                        checked={option?.value === value}
                    />

                    <label htmlFor={inputId}>{option?.value}</label>
                </div>
            ))}

            <div className="med-dropdown__error input-error">
                <div className="med-dropdown__error-text input-error__text">
                    {touched &&
                        ((error && <span>{error}</span>) ||
                            (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
