import { Moment } from "moment";
import { ActiveCommunicationType } from "./device.model";

export enum WEEK_DAYS {
    SUNDAY = 'Sunday',
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
}

export enum REPEAT_TYPE {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    NONE = 'NONE'
}

export enum SCHEDULER_VIEW {
    ICU = 'ICU',
    DEVICE = 'DEVICE'
}

export interface Repeat {
    isActive: boolean;
    type: REPEAT_TYPE;
    endDate: Date | null;
    days: Array<WEEK_DAYS>;
}

export interface SchedulerEvent {
    id: string;
    communicationTypeId: ActiveCommunicationType;
    text: string;
    subType: string;
    startDate: Date;
    timezone: string,
    time: string;
    repeat: Repeat;
    genre: string;
    subOption?: number | string;
}

export interface RepeatEvent {
    event: SchedulerEvent;
    startDate: Moment;
    reapeatType: REPEAT_TYPE;
    repeatDays: Array<WEEK_DAYS>;
}