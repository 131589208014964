import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { clearSubmitErrors, Field, InjectedFormProps, reduxForm, SubmissionError } from "redux-form";
import { ThunkDispatch } from "redux-thunk";
import { loginUser, samlAuthentication } from "../../actions";
import { UserLogin, UserModel } from "../../models/user.model";
import { StoreState } from "../../reducers";
import { MedInput } from "../forms/customComponents/MedInput/MedInput";
import { EyeControlIconLogin } from "../../icons/EyeControlIconLogin";
import { DialogContext } from "../../contexts/DialogContext";
import { DialogPropTypes } from "../../contexts/types";

interface LoginProps extends InjectedFormProps {
    errorMessage?: string;
    loginUser: Function;
    currentUser: UserModel | null | undefined;
}

const mapStateToProps = ({ authInfo }: StoreState) => {
    return {
        currentUser: authInfo?.currentUser,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>
) => {
    return {
        loginUser: (credentials: UserLogin, callback: Function) =>
            dispatch(
                loginUser(credentials, (result: string) => callback(result), "SAML")
            ),
    };
};

const validate = (values: any) => {
    const errors: any = {};

    if (!values.userName) {
        errors.userName = "User name is a mandatory field";
    }

    if (!values.password) {
        errors.password = "Password is a mandatory field";
    }

    return errors;
}

export const _Login = ({
    error,
    loginUser,
    handleSubmit,
    submitting,
    currentUser
}: LoginProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { hide } = useContext(
        DialogContext
    ) as DialogPropTypes;

    useEffect(() => {
        if (currentUser !== null) {
            navigate("/overview");
        }
        hide();
    }, []);

    const submitPromise = (values: any) =>
        new Promise((resolve) => {
            loginUser(values, (result: any) => {
                resolve(result);
            });
        });

    const submit = (values: any) => {
        return submitPromise(values).then((result) => {
            if (result !== "/overview") {
                throw new SubmissionError({
                    userName: " ",
                    password: " ",
                    _error: t("Invalid credentials. Please try again.") as string,
                });
            } else {
                navigate(result);
            }
        });
    };

    const isSaml = process.env.REACT_APP_IS_SAML?.toLowerCase() === 'true'

    return (
        <div className="login">
            <form className={`${isSaml ? "login__saml" : "login__field-set"}`} onSubmit={handleSubmit(submit)}>
                {isSaml ?
                    <div className="login__icon-box">
                        <EyeControlIconLogin />
                    </div>
                    :
                    <div>
                        <div className="login__fields">
                            <h3 className="login__title">{t("Login")}</h3>
                            <div className="form__input">
                                <Field
                                    component={MedInput}
                                    label={t("User")}
                                    name="userName"
                                    placeholder={t("Enter User Name")}
                                    type="text"
                                />
                            </div>
                            <div className="form__input">
                                <Field
                                    component={MedInput}
                                    label={t("Password")}
                                    name="password"
                                    placeholder={t("Enter password")}
                                    type="password"
                                />
                            </div>
                        </div>
                        <div className="login__error">{error}</div>
                    </div>
                }
                <Button
                    type="submit"
                    className={`button button-form ${submitting ? "button-disabled" : ""}  ${isSaml ? "login__saml-button" : "login__button"}`}
                    disabled={submitting}
                >
                    {submitting ? (
                        <div className="button-loading">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        t("Login")
                    )}
                </Button>
                {isSaml ?
                    <>
                        <div className="login__saml-description-box">
                            <div className="login__saml-description">{t("samlLoginDescription1")}</div>
                            <div className="login__saml-description">{t("samlLoginDescription2")}</div>
                        </div>
                        <div className="login__eyecontrol-saml" onClick={() => samlAuthentication("Eyecontrol-SAML")}>{t("samlEyecontrolLoginText")}</div>
                    </>
                    :
                    <div />
                }
            </form>
        </div>
    );
};

export const Login = reduxForm({
    form: "admin_login",
    validate,
    onChange: (values, dispatch, props) => {
        if (props.error) dispatch(clearSubmitErrors("admin_login"));
    },
})(connect(mapStateToProps, mapDispatchToProps)(_Login));