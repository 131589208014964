import { useTranslation } from "react-i18next";
import { NotificationModel, NOTIFICATIONS, SEVERITIES, Styles, TYPES } from "../../models/notification.model";
import { PowerConnectedIcon, PowerNotConnectedIcon, ResultsIcon, TrashIcon } from "../../icons";
import Moment from 'react-moment';
import 'moment-timezone';
import { TechnicalErrorIcon } from "../../icons/TechnicalError";
import { getS3Item } from "src/utils/generalMethods";

interface NotificationsProps {
    notifications: NotificationModel[] | undefined;
    timeZone: string | undefined;
}

export let notificationIconMap: Map<any, React.SVGProps<SVGSVGElement>> = new Map();
notificationIconMap.set(NOTIFICATIONS.POWER_ON, <PowerConnectedIcon height={"2rem"} width={"2rem"} />);
notificationIconMap.set(NOTIFICATIONS.POWER_OFF, <PowerNotConnectedIcon height={"2rem"} width={"2rem"}/>);
notificationIconMap.set(NOTIFICATIONS.TECHNICAL_INDICATOR_ON, <TechnicalErrorIcon height={"2rem"} width={"2rem"}/>);
notificationIconMap.set(NOTIFICATIONS.CAM_ICU_RESULTS, <ResultsIcon height={"2rem"} width={"2rem"}/>);
notificationIconMap.set(NOTIFICATIONS.Q_AND_A_RESULTS, <ResultsIcon height={"2rem"} width={"2rem"}/>);
notificationIconMap.set(NOTIFICATIONS.TRAINING, <ResultsIcon height={"2rem"} width={"2rem"}/>);

export const Notifications = ({
    notifications,
    timeZone
}: NotificationsProps): JSX.Element => {
    const { t } = useTranslation();
    
    const getNotificationElement = (text: string | undefined, styles: Styles | undefined): JSX.Element => {
        if (!text) {
            return <span />;
        }
    
        if (!styles) {
            return <span>{text}</span>;
        }
        
        const applyStyles = (text: string): JSX.Element => {
            const elements: JSX.Element[] = [];
            let currentIndex = 0;
    
            const addTextPart = (part: string, isBold: boolean, isItalic: boolean) => {
                elements.push(
                    <span
                        key={currentIndex}
                        style={{
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal'
                        }}
                    >
                        {part}
                    </span>
                );
                currentIndex++;
            };
    
            const { bold = [], italic = [] } = styles;
    
            let pattern = bold.concat(italic).join('|');
            const regex = new RegExp(`(${pattern})`, 'g');
    
            let match;
            let lastIndex = 0;
    
            while ((match = regex.exec(text)) !== null && regex.lastIndex > 0) {
                const startPart = text.slice(lastIndex, match.index);
                if (startPart) {
                    addTextPart(startPart, false, false);
                }
    
                const matchedText = match[0];
                const isBold = bold.includes(matchedText);
                const isItalic = italic.includes(matchedText);
    
                addTextPart(matchedText, isBold, isItalic);
                lastIndex = regex.lastIndex;
            }
    
            const remainingPart = text.slice(lastIndex);
            if (remainingPart) {
                addTextPart(remainingPart, false, false);
            }
    
            return <>{elements}</>;
        };
    
        return <span>{applyStyles(text)}</span>;
    }

    if (!notifications) return <div>Loading...</div>;
    return (
        <div className="notifications">
            <div className="notifications__header">{t("notifications")}</div>
            {/* <div className="notifications__filter">
                <span className="notifications__span-wrapper">
                    <span className="notifications__span-wrapper__filter-option-selected">Unmanaged</span>
                </span>
                <span className="notifications__span-wrapper">
                    <span className="notifications__span-wrapper__filter-option">All</span>
                </span>
            </div> */}
            <ul className="notifications__items">
                {notifications?.map((notificationObj, index) => {
                    return (
                        <li key={index} className={`notifications__item ${notificationObj.configurations.severity === SEVERITIES.ACTION_REQUIRED ? " notifications__action-required" : ""}`}>
                            <div className="notifications__wrapper">
                                <div className="notifications__information-wrapper">
                                    <div className="notifications__information">
                                        <div className="notifications__icon">
                                            {notificationIconMap.get(notificationObj.configurations.notification)}
                                        </div>
                                        <div className="notifications__text">
                                            {getNotificationElement(notificationObj.configurations?.text, notificationObj.configurations?.styles)}
                                        </div>
                                    </div>
                                    <div className="notifications__results">
                                        { notificationObj.url && notificationObj.configurations?.type === TYPES.RESULTS &&
                                            <a className="notifications__results-download" onClick={() => {getS3Item(notificationObj.url?.split("public/")[1])}} target="_blank">Download results</a>
                                        }
                                    </div>
                                </div>

                                <div className="notifications__date">
                                    <div>
                                        <Moment tz={timeZone} format="MMM Do, YYYY">{notificationObj.timestamp}</Moment>
                                    </div>
                                    <div className="notifications__hour">
                                        <Moment tz={timeZone} format="HH:mm">{notificationObj.timestamp}</Moment>
                                    </div>
                                </div>
                                {/* <div className="notifications__trash-icon">
                                    <TrashIcon />
                                </div> */}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
