import { webRtcStatus } from "../models/device.model";
import IotService from "./iot.service";

const RTC_ICU_SERVER =
    process.env.REACT_RTC_ICU_SERVER || "stun:stun.l.google.com:19302";

const WEBRTC_TURN_URL = process.env.REACT_APP_WEBRTC_TURN_URL || "";
const WEBRTC_USER = process.env.REACT_APP_WEBRTC_USER || "";
const WEBRTC_PASSWORD = process.env.REACT_APP_WEBRTC_PASSWORD || "";

class WebRtcService {
    private rtcSubscriptionMap;
    private peerConnectionnMap;

    constructor() {
        this.rtcSubscriptionMap = new Map<string, any>();
        this.peerConnectionnMap = new Map<string, RTCPeerConnection>();
    }

    public unsubscribe() {
        if (this.rtcSubscriptionMap?.size) {
            this.rtcSubscriptionMap.forEach((rtcSubscription) => {
                if (rtcSubscription) {
                    rtcSubscription?.unsubscribe();
                }
            });
        }
    }

    public closeConnections() {
        if (this.peerConnectionnMap?.size) {
            this.peerConnectionnMap.forEach((peerConnectionn) => {
                peerConnectionn.close();
            });
        }
    }

    public async createConnection(
        deviceId: string,
        icuId: string,
        tenantId: string,
        streamWindow: React.RefObject<HTMLVideoElement>,
        setIsStream: Function,
        setIsBusy: Function
    ) {
        if (!deviceId || !tenantId) return;

        this.unsubscribe();

        const remoteStream = new MediaStream();

        const currentPc = new RTCPeerConnection({
            iceServers: [
                {
                    urls: WEBRTC_TURN_URL,
                    username: WEBRTC_USER,
                    credential: WEBRTC_PASSWORD
                }
            ],
        });

        currentPc.addTransceiver("video", {
            direction: "recvonly",
        });
        
        currentPc.ontrack = async (event) => {
            event.streams[0].getTracks().forEach((track) => {
                remoteStream.addTrack(track);
            });

            if (streamWindow?.current !== null) {
                streamWindow.current.srcObject = remoteStream;
            }
        };

        currentPc.oniceconnectionstatechange = (e) =>
            this.log(currentPc.iceConnectionState);

        currentPc.onicecandidate = async (event) => {
            if (event.candidate === null && deviceId) {
                try {
                    await IotService.publishToTopic(
                        deviceId,
                        "request_webrtc",
                        {
                            webrtc_request_token: btoa(
                                JSON.stringify(currentPc.localDescription)
                            ),
                            webrtc_turn_url: WEBRTC_TURN_URL,
                            webrtc_turn_user: WEBRTC_USER,
                            webrtc_turn_password: WEBRTC_PASSWORD
                        }
                    );
                } catch (err) {
                    console.log("onicecandidate error: ", err);
                }
            }
        };

        const subscription = await this.subscribeToRtcResponse(
            deviceId,
            icuId,
            tenantId,
            setIsStream,
            setIsBusy
        );

        this.rtcSubscriptionMap.set(deviceId, subscription);

        currentPc
            .createOffer()
            .then((d) => currentPc.setLocalDescription(d))
            .catch(this.log);
        this.peerConnectionnMap.set(deviceId, currentPc);
    }

    private async subscribeToRtcResponse(
        deviceId: string,
        icuId: string,
        tenantId: string,
        setIsStream: Function,
        setIsBusy: Function
    ) {
        return IotService.subscribeToTopic(
            `${tenantId}/${icuId}/${deviceId}/response_webrtc`,
            (data) => {
                try {
                    switch (data.value.status) {
                        case webRtcStatus.AVAILABLE:
                            this.startSession(
                                data.value.webrtc_response_token,
                                deviceId,
                                setIsStream
                            );
                            break;
                        case webRtcStatus.BUSY:
                            setIsBusy(true);
                            break;
                        case webRtcStatus.ERROR:
                            this.log(`web_rtc_error: ${data.value.error}`);
                            break;
                    }
                } catch (err) {
                    this.log(`Start Session error: ${err}`);
                }
            },
            (err) => {
                console.log(`Error: ${err}`);
                this.reconnectToRtcResponse(
                    tenantId,
                    icuId,
                    deviceId,
                    setIsStream,
                    setIsBusy
                );
            },
            () => {
                this.reconnectToRtcResponse(
                    tenantId,
                    icuId,
                    deviceId,
                    setIsStream,
                    setIsBusy
                );
            }
        );
    }

    private reconnectToRtcResponse(
        tenantId: string,
        icuId:string,
        deviceId: string,
        setIsStream: Function,
        setIsBusy: Function
    ) {
        setTimeout(() => {
            this.rtcSubscriptionMap.set(
                deviceId,
                this.subscribeToRtcResponse(
                    tenantId,
                    icuId,
                    deviceId,
                    setIsStream,
                    setIsBusy
                )
            );
        }, 10000);
    }

    private startSession(sd: string, deviceId: string, setIsStream: Function) {
        try {
            const rtc = new RTCSessionDescription(JSON.parse(atob(sd)));
            this.peerConnectionnMap.get(deviceId)?.setRemoteDescription(rtc);
            setIsStream(true);
        } catch (e) {
            console.log(e);
        }
    }

    private log(msg: string) {
        console.log(msg);
    }
}

export default WebRtcService;
