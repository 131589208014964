import { useEffect, useState } from "react";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateSettingsField } from "../../../actions/device.action";
import { DeviceModel } from "../../../models/device.model";
import { Checkbox } from 'primereact/checkbox';

interface CameraOopIndicatiorsProps {
    devices?: DeviceModel[];
}

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _CameraOopIndicatiors = ({
    devices,
}: CameraOopIndicatiorsProps): JSX.Element => {
    const { t } = useTranslation();
    let params = useParams();
    let [oopDeviceIndicator, setOopDeviceIndicator] = useState<boolean>();
    let [oopDashboardIndicator, setOopDashboardIndicator] = useState<boolean>();
    let [isCurrentValueFromShadow, setIsCurrentValueFromShadow] = useState<boolean>(false);
    let deviceId = params.deviceId ? params.deviceId : "";

    const onOopDeviceIndicatorChange = (value: boolean) => {
        if(value !== oopDeviceIndicator) {
            setOopDeviceIndicator(value);
            updateSettingsField(deviceId, "oop_device_indication", { oop_device_indication: value });
        }
    };

    const onOopDashboardIndicatorChange = (value: boolean) => {
        if(value !== oopDashboardIndicator) {
            setOopDashboardIndicator(value);
            updateSettingsField(deviceId, "oop_dashboard_indication", { oop_dashboard_indication: value });
        }
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);
        if (currentDevice && (!isCurrentValueFromShadow || currentDevice.isReset)) {
            setOopDeviceIndicator(currentDevice.shadow?.settings?.oop_device_indication as boolean );
            setOopDashboardIndicator(currentDevice.shadow?.settings?.oop_dashboard_indication as boolean );
            setIsCurrentValueFromShadow(true);
        }
    }, [devices?.length]);

    return (
        <div className="card-container">
            <div className="oop-indication__header-wrapper">
                <div className="card-container__title">{t("cameraOopIndicatiors")}</div>
            </div>
            <div className="oop-indication__description">{t("cameraOopIndicatiorsDescription")}</div>
            <div className='oop-indication__checkbox med-checkbox'>
                <Checkbox onChange={e => onOopDeviceIndicatorChange(e.checked || false)} checked={oopDeviceIndicator || false}></Checkbox>
                <label htmlFor='rb1' className='oop-indication__checbox-label p-checkbox-label'>
                    {t("powerLedIndocationsCheckbox")}
                </label>
            </div>
            <div className='oop-indication__checkbox med-checkbox'>
                <Checkbox onChange={e => onOopDashboardIndicatorChange(e.checked || false)} checked={oopDashboardIndicator || false}></Checkbox>
                <label htmlFor='rb1' className='oop-indication__checbox-label p-checkbox-label'>
                    {t("dashboardIndicationsCheckbox")}
                </label>
            </div>
        </div>
    );
};

export const CameraOopIndicatiors = connect(
    mapStateToProps,
    null
)(_CameraOopIndicatiors);