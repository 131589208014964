import React from "react";

export const ArrowDownIcon = ({
    height = "1rem",
    width = "1rem",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 6L6.35355 1.35355C6.15829 1.15829 5.84171 1.15829 5.64645 1.35355L0.999999 6"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
