
import { ActiveCommunicationType } from "../models/device.model";
import { ClockIcon } from "./Clock";
import { DocumentIcon } from "./Document";
import { FamilyIcon } from "./Family";
import { GeneralMessageIcon } from "./GeneralMessage";
import { MusicIcon } from "./Music";
import { StopIcon } from "./Stop";

interface CommunicationIconProps {
    communicatoinType?: ActiveCommunicationType;
    height?: string;
    width?: string;
    isWrapped?: boolean;
    wrapSize?: WrapSize;
}

export enum WrapSize {
    SMALL = "small",
    MEDIUM = "medium",
    MID_LARGE = "mid-large",
    LARGE = "large",
    NONE = "none"
}

export const CommunicationIcon = ({
    communicatoinType,
    height = "1.6rem",
    width = "1.4rem",
    isWrapped = true,
    wrapSize = WrapSize.NONE,
}: CommunicationIconProps) => {
    const communicationIconBoxType = isWrapped
        ? `communication-icon__box communication-icon__box--${communicatoinType} ${
            wrapSize != WrapSize.NONE ? `communication-icon__box--${wrapSize}` : ""
          }`
        : "";

    switch (communicatoinType) {
        case ActiveCommunicationType.TRAINING:
        case ActiveCommunicationType.Q_AND_A:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <DocumentIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.CAM_ICU:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <DocumentIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.TUTORIAL:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <DocumentIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.STOP_COMMUNICATION:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <StopIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.ORIENTATION:
        case ActiveCommunicationType.INSTRUCTIONS:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <ClockIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.FAMILY:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <FamilyIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.MUSIC:
        case ActiveCommunicationType.PERSONAL_MUSIC:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <MusicIcon height={height} width={width} />
                </div>
            );
        case ActiveCommunicationType.GENERAL_MESSAGE:
            return (
                <div className={`${communicationIconBoxType}`}>
                    <GeneralMessageIcon height={height} width={width} />
                </div>
            );

        default:
            return (
                <div className={`${communicationIconBoxType}`}>
                </div>
            );
    }
};
