import React from "react";

export const ArrowRightIcon = ({
    height = "15",
    width = "7",
    stroke = "#0F284C",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 7 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 14L5.7655 7.80486C5.90375 7.62513 5.90375 7.37487 5.7655 7.19514L0.999999 1"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
