import React from "react";

export const PlayIcon = ({
    height = "24px",
    width = "24px",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
<svg className="play-icon" width={width} height={height} viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.75 3.89711C7.08333 4.08956 7.08333 4.57069 6.75 4.76314L0.75 8.22724C0.416667 8.41969 -3.57521e-07 8.17913 -3.40697e-07 7.79423L-3.78552e-08 0.866026C-2.10307e-08 0.481126 0.416666 0.240562 0.75 0.433012L6.75 3.89711Z" fill="white"/>
</svg>

);
