import { t } from "i18next";
import parsePhoneNumberFromString, { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/reducers";
import patientApi from "src/apis/patient";
import { updateToast } from "src/actions";
import { TOAST_SEVERITY } from "src/models/toast.model";
import { SentIcon } from "src/icons/Sent";
import { useLocation } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { useMixpanel } from "src/hooks/useMixpanel";

export enum LinkDialogStep {
    INPUT = 'input',
    CONFIRMATION = 'confirmation',
    SUCCESS = 'success'
}

interface CountryOption {
    name: string;
    code: CountryCode;
    dialCode: string;
}

const countryOptions: CountryOption[] = [
    { name: 'Israel', code: 'IL', dialCode: '+972' },
    { name: 'United States', code: 'US', dialCode: '+1' },
];

interface CustomDialogSelectorResult {
    patientId: string | undefined;
}

const CustomDialogSelector = ({deviceObject}: StoreState, deviceId: string): CustomDialogSelectorResult => {
    const device = deviceObject.devices.find(d => d.id === deviceId);
    return {
        patientId: device?.patient?._id,
    };
};

interface ShareLinkDialogProps {
    onClose: () => void;
    isNurseView?: boolean;
}

export const ShareLinkDialog: React.FC<ShareLinkDialogProps> = ({ onClose, isNurseView }) => {
    const [selectedCountry, setSelectedCountry] = useState<CountryOption>(countryOptions[0]);
    const [linkDialogStep, setLinkDialogStep] = useState<LinkDialogStep>(LinkDialogStep.INPUT);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const location = useLocation();
    const deviceId = location.pathname.split('/').pop() || '';
    const {patientId} = useSelector((state: StoreState) => CustomDialogSelector(state, deviceId), shallowEqual);
    const [isSending, setIsSending] = useState(false);
    const dispatch = useDispatch();
    const { trackClickEditPhoneButton,
            trackClickSendMeLinkButton,
            trackClickYesSendMeLinkButton,
            trackClickCloseGetLinkLastStepButton,
            trackClickResendSmsButton
    } = useMixpanel();

    useEffect(() => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, selectedCountry.code);
        if (parsedNumber) {
            setPhoneNumber(parsedNumber.nationalNumber);
            setIsValidNumber(isValidPhoneNumber(parsedNumber.number, selectedCountry.code));
        } else {
            setIsValidNumber(false);
        }
    }, [phoneNumber, selectedCountry]);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const handleCountryChange = (e: { value: CountryOption }) => {
        setSelectedCountry(e.value);
    };

    const onAction = async () => {
            if (linkDialogStep === LinkDialogStep.INPUT) {
                if (isNurseView) {
                    trackClickSendMeLinkButton();
                }
                setLinkDialogStep(LinkDialogStep.CONFIRMATION);
                return;
            }
            if (linkDialogStep === LinkDialogStep.CONFIRMATION || linkDialogStep === LinkDialogStep.SUCCESS) {
                if (isNurseView) {
                    if (linkDialogStep === LinkDialogStep.CONFIRMATION) {
                        trackClickYesSendMeLinkButton();
                    } else if (linkDialogStep === LinkDialogStep.SUCCESS) {
                        trackClickResendSmsButton();
                    }
                }
                if (!patientId) {
                    console.error("Patient ID not found");
                    return;
                }
                try {
                    const fullPhoneNumber = `${selectedCountry.dialCode}${phoneNumber}`;
                    const encodedPhoneNumber = encodeURIComponent(fullPhoneNumber);
                    setIsSending(true);
                    await patientApi.get(`/${patientId}/url?phoneNumber=${encodedPhoneNumber}`);
                    setLinkDialogStep(LinkDialogStep.SUCCESS);
                } catch (error) {
                    console.error("Error sending link:", error);
                    dispatch(updateToast({
                        severity: TOAST_SEVERITY.ERROR,
                        summary: t('Error'),
                        detail: t('Failed to send the SMS. Please try again.'),
                        life: 5000
                    }));
                } finally {
                    setIsSending(false);
                }
                return;

        }
    };

    return (
        <div className="link-dialog__wrapper">
            {linkDialogStep === LinkDialogStep.INPUT && (
            <>
                <h2 className="link-dialog__title">{t("Get a link to support your loved one")}</h2>
                <p className="link-dialog__description">
                    {t("Enter your phone number to get a personal link to support your loved one via EyeControl:")}
                </p>
                <div className="link-dialog__input-wrapper">
                    <div className="p-inputgroup">
                        <Dropdown
                            value={selectedCountry}
                            options={countryOptions}
                            onChange={handleCountryChange}
                            optionLabel="dialCode"
                            className="country-dropdown"
                        />
                        <span className={`p-float-label ${isValidNumber ? 'valid-number' : ''}`}>
                            <InputText
                                id="phone"
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                className={isValidNumber ? 'valid-number' : ''}
                            />
                            <label htmlFor="phone">{t("Phone number")}</label>
                        </span>
                    </div>
                    {!isValidNumber && phoneNumber && (
                        <small className="p-error">{t("Please enter a valid phone number")}</small>
                    )}
                </div>
                <Button
                    label={t("Send Me a Link")}
                    className="link-dialog__submit-button"
                    onClick={onAction}
                    disabled={!isValidNumber}
                />
            </>
        )}
        {linkDialogStep === LinkDialogStep.CONFIRMATION && (
            <>
                <h2 className="link-dialog__title">{t("Just One More Check...")}</h2>
                <p className="link-dialog__description">
                    {t("Please confirm the number to send the personal support link:")}
                </p>
                <div className="link-dialog__phone-number">
                    {selectedCountry.dialCode} {phoneNumber}
                </div>
                <span
                    className="link-dialog__clickable-text"
                    onClick={() => {
                        if (isNurseView) {
                            trackClickEditPhoneButton();
                        }
                        setLinkDialogStep(LinkDialogStep.INPUT)}}
                >
                    {t("Edit phone number")}
                </span>
                <Button
                    loading={isSending}
                    label={t("Yes, send Me a link")}
                    className="link-dialog__submit-button"
                    onClick={onAction}
                />
            </>
        )}
        {linkDialogStep === LinkDialogStep.SUCCESS && (
            <>
                <h2 className="link-dialog__title">{t("We've sent you a message with a link")}</h2>
                <div className="link-dialog__icon">
                    <SentIcon/>
                </div>
                {isSending ? (
                    <div className="link-dialog__loader">
                        <PuffLoader color="#007CC7" size={30} />
                    </div>
                ) : (
                    <span
                        className="link-dialog__clickable-text"
                        onClick={onAction}
                    >
                        {t("I didn't receive, please send again")}
                    </span>
                )}
                <Button
                    label={t("Close")}
                    className="link-dialog__submit-button"
                    onClick={() => {
                        if (isNurseView) {
                            trackClickCloseGetLinkLastStepButton();
                        }
                        onClose();
                    }}
                />
            </>
        )}
        </div>
    )
}