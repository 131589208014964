import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import {MIXPANEL_ACTIONS, MIXPANEL_ACTION_TYPES, MIXPANEL_ACTION_SUB_TYPES} from "src/constans/mixpanelEnum";

interface TrackEventProperties {
    type?: MIXPANEL_ACTION_TYPES;
    subType?: MIXPANEL_ACTION_SUB_TYPES;
    icuName: string;
    userName: string;
    tenantName: string;
    deviceId: string;
}

class MixpanelService {
    private static instance: MixpanelService;
    private mixpanel: OverridedMixpanel | undefined;

    private constructor() {
        if(process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN,{
                ignore_dnt: true
            });

            this.mixpanel = mixpanel;

            if (!this.mixpanel) {
                console.error('Failed to initialize Mixpanel');
            }
        }
    }

    public static getInstance(): MixpanelService {
        if (!MixpanelService.instance) {
            MixpanelService.instance = new MixpanelService();
        }
        return MixpanelService.instance;
    }

    public trackEvent(event: MIXPANEL_ACTIONS, properties?: TrackEventProperties): void {
        if (this.mixpanel) {
            try {
                this.mixpanel.track(event, properties);
            } catch (error) {
                console.log(error)
            }
        } else {
            if(process.env.REACT_APP_MIXPANEL_TOKEN) console.error('Mixpanel instance is not available');
        }
    }
}

export default MixpanelService;
