import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { useTranslation } from "react-i18next";
import {
    FamilyRecordingAttribute,
    FamilyRecordingModel,
    PreCommunicationSlotModel,
    RecordingsObjModel,
} from "../../models/recording.model";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import {
    deletePreCommunicationSlot,
    deleteRecordingSlot,
    updateFamilyRecording,
    updatePreCommunicationSlot,
    updateRecordingSlot,
    updateToast,
} from "../../actions";
import { ToastModel } from "../../models/toast.model";
import { IcuRecordingFeatureModel } from "../../models/icu.model";
import FamilyRecordings from "./FamilyRecordings";
import GeneralRecordings from "./GeneralRecordings";
import RecordingSlots from "./RecordingSlots";
import { AuthorizationResourceWrapper } from "../authentication/AuthorizationResourceWrapper";
import {
    CustomAccordion,
    CustomAccordionItem,
} from "../general/CustomAccordion";
import { GeneralUploadButton } from "./GeneralRecordingUploadButton";

interface RecordingsSectionProps {
    recordingsObjModel: RecordingsObjModel | undefined;
    patientId: string;
    updateFamilyRecording: Function;
    showToast: Function;
    recordingFlags?: IcuRecordingFeatureModel;
    updateRecordingSlot: Function;
    deleteRecordingSlot: Function;
    updatePreCommunicationSlot: Function;
    deletePreCommunicationSlot: Function;
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>
) => {
    return {
        updateFamilyRecording: (
            patientId: string,
            recording: FamilyRecordingModel,
            prevRecording: FamilyRecordingModel,
            attribute: FamilyRecordingAttribute,
            errorCallback: Function
        ) =>
            dispatch(
                updateFamilyRecording(
                    patientId,
                    recording,
                    prevRecording,
                    attribute,
                    errorCallback
                )
            ),
        showToast: (ToastModel: ToastModel) =>
            dispatch(updateToast(ToastModel)),
        updateRecordingSlot: (
            patientId: string,
            slotNumber: number,
            recordingId: string,
            prevId: string,
            callback: Function
        ) =>
            dispatch(
                updateRecordingSlot(
                    patientId,
                    slotNumber,
                    recordingId,
                    prevId,
                    callback
                )
            ),
        deleteRecordingSlot: (
            patientId: string,
            slotNumber: number,
            prevId: string,
            callback: Function
        ) =>
            dispatch(
                deleteRecordingSlot(patientId, slotNumber, prevId, callback)
            ),
        updatePreCommunicationSlot: (
            patientId: string,
            slotName: string,
            recordingId: string,
            prevId: string,
            callback: Function
        ) =>
            dispatch(
                updatePreCommunicationSlot(patientId, slotName, recordingId, prevId, callback)
            ),
        deletePreCommunicationSlot: (
            patientId: string,
            slotName: string,
            prevId: string,
            callback: Function
        ) =>
            dispatch(
                deletePreCommunicationSlot(patientId, slotName, prevId, callback)
            ),
    };
};

const _Recordings = ({
    recordingsObjModel,
    patientId,
    updateFamilyRecording,
    showToast,
    recordingFlags,
    updateRecordingSlot,
    deleteRecordingSlot,
    updatePreCommunicationSlot,
    deletePreCommunicationSlot
}: RecordingsSectionProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="recordings-list">
            <div className="recordings-list__header">
                <div className="recordings-list__header-title">
                    {t("Recordings")}
                </div>
                <div className="recordings-list__invite-button"></div>
            </div>
            <div className="recordings-list__main">
                <CustomAccordion>
                    {recordingFlags?.family_recordings_management ? (
                        <AuthorizationResourceWrapper resourceName="GeneralRecordings">
                            <CustomAccordionItem
                                isTabOpen={true}
                                headerStartElement={
                                    <div className="recordings-list__sub-header">
                                        <div className="recordings-list__sub-header-start">
                                            <div className="recordings-list__sub-header-text">
                                                {t("General Slots")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <RecordingSlots
                                    slots={recordingsObjModel?.slots || []}
                                    recordings={
                                        recordingsObjModel?.generalRecordings ||
                                        []
                                    }
                                    patientId={patientId}
                                    showToast={showToast}
                                    updateRecordingSlot={(
                                        patientId: string,
                                        slotNumber: number,
                                        recordingId: string,
                                        prevId: string,
                                        callback: Function
                                    ) => {
                                        updateRecordingSlot(
                                            patientId,
                                            slotNumber,
                                            recordingId,
                                            prevId,
                                            callback
                                        );
                                    }}
                                    deleteRecordingSlot={(
                                        patientId: string,
                                        slotNumber: number,
                                        prevId: string,
                                        callback: Function
                                    ) => {
                                        deleteRecordingSlot(
                                            patientId,
                                            slotNumber,
                                            prevId,
                                            callback
                                        );
                                    }}
                                />
                            </CustomAccordionItem>
                        </AuthorizationResourceWrapper>
                    ) : null}
                    {recordingFlags?.family_recordings_management ? (
                        <AuthorizationResourceWrapper resourceName="GeneralRecordings">
                            <CustomAccordionItem
                                isTabOpen={true}
                                headerStartElement={
                                    <div className="recordings-list__sub-header">
                                        <div className="recordings-list__sub-header-start">
                                            <div className="recordings-list__sub-header-text">
                                                {t("Pre-Q&A Slot")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <RecordingSlots
                                    slots={recordingsObjModel?.preCommunicationSlot ? 
                                        [recordingsObjModel.preCommunicationSlot] : 
                                        [{
                                            patient_id: patientId,
                                            name: "Stimuli"
                                        } as PreCommunicationSlotModel]
                                    }
                                    recordings={
                                        recordingsObjModel?.generalRecordings ||
                                        []
                                    }
                                    patientId={patientId}
                                    showToast={showToast}
                                    updateRecordingSlot={(
                                        patientId: string,
                                        slotName: string,
                                        recordingId: string,
                                        prevId: string,
                                        callback: Function
                                    ) => {
                                        updatePreCommunicationSlot(
                                            patientId,
                                            slotName,
                                            recordingId,
                                            prevId,
                                            callback
                                        );
                                    }}
                                    deleteRecordingSlot={(
                                        patientId: string,
                                        slotName: string,
                                        prevId: string,
                                        callback: Function
                                    ) => {
                                        deletePreCommunicationSlot(
                                            patientId,
                                            slotName,
                                            prevId,
                                            callback
                                        );
                                    }}
                                />
                            </CustomAccordionItem>
                        </AuthorizationResourceWrapper>
                    ) : null}

                    {recordingFlags?.family_recordings_management ? (
                        <AuthorizationResourceWrapper resourceName="GeneralRecordings">
                            <CustomAccordionItem
                                headerStartElement={
                                    <div className="recordings-list__sub-header-start">
                                        <div className="recordings-list__sub-header-text">
                                            {t("General Messages")}
                                        </div>
                                        <div className="recordings-list__number-records">
                                            (
                                            <div>
                                                {
                                                    recordingsObjModel
                                                        ?.generalRecordings
                                                        ?.length
                                                }
                                            </div>
                                            <div className="recordings-list__number-records--text">
                                                {recordingsObjModel
                                                    ?.generalRecordings
                                                    ?.length == 1
                                                    ? t("Record")
                                                    : t("Records")}
                                            </div>
                                            )
                                        </div>
                                    </div>
                                }
                                headerEndElement={
                                    <AuthorizationResourceWrapper resourceName="GeneralRecordings">
                                        <GeneralUploadButton
                                            patientId={patientId}
                                            showToast={showToast}
                                        />
                                    </AuthorizationResourceWrapper>
                                }
                            >
                                <GeneralRecordings
                                    recordings={
                                        recordingsObjModel?.generalRecordings ||
                                        []
                                    }
                                    patientId={patientId}
                                    showToast={showToast}
                                />
                            </CustomAccordionItem>
                        </AuthorizationResourceWrapper>
                    ) : null}
                    {recordingFlags?.family_recordings_management ? (
                        <CustomAccordionItem
                            headerStartElement={
                                <div className="recordings-list__sub-header-start">
                                    <div className="recordings-list__sub-header-text">
                                        {t("Family Recordings")}
                                    </div>
                                    <div className="recordings-list__number-records">
                                        (
                                        <div>
                                            {
                                                recordingsObjModel
                                                    ?.familyRecordings?.length
                                            }
                                        </div>
                                        <div className="recordings-list__number-records--text">
                                            {recordingsObjModel
                                                ?.familyRecordings?.length == 1
                                                ? t("Record")
                                                : t("Records")}
                                        </div>
                                        )
                                    </div>
                                </div>
                            }
                        >
                            <FamilyRecordings
                                recordings={
                                    recordingsObjModel?.familyRecordings || []
                                }
                                patientId={patientId}
                                updateFamilyRecording={updateFamilyRecording}
                                showToast={showToast}
                            />
                        </CustomAccordionItem>
                    ) : null}
                </CustomAccordion>
            </div>
        </div>
    );
};

export const RecordingsSection = connect(null, mapDispatchToProps)(_Recordings);
