import { useEffect, useState } from "react";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateSettingsField } from "src/actions";
import { DeviceModel } from "src/models/device.model";
import { Checkbox } from 'primereact/checkbox';

interface DeviceInitiationBehaviorProps {
    devices?: DeviceModel[];
}

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _DeviceInitiationBehavior = ({ devices }: DeviceInitiationBehaviorProps): JSX.Element => {
    const { t } = useTranslation();
    let params = useParams();
    let [disableInitiationLoop, setDisableInitiationLoop] = useState<boolean>();
    let [isCurrentValueFromShadow, setIsCurrentValueFromShadow] = useState<boolean>(false);
    let deviceId = params.deviceId ? params.deviceId : "";

    const onDisableInitiationLoopChange = (value: boolean) => {
        if(value !== disableInitiationLoop) {
            setDisableInitiationLoop(value);
            updateSettingsField(deviceId, "disable_initiation_loop", { disable_initiation_loop: value });
        }
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);
        if (currentDevice && (!isCurrentValueFromShadow || currentDevice.isReset)) {
            setDisableInitiationLoop(currentDevice.shadow?.settings?.disable_initiation_loop as boolean );
            setIsCurrentValueFromShadow(true);
        }
    }, [devices?.length]);

    return (
        <div className="card-container">
            <div className="oop-indication__header-wrapper">
                <div className="card-container__title">{t("deviceInitiationBehavior")}</div>
            </div>
            <div className="oop-indication__description">{t("deviceInitiationBehaviorDescription")}</div>
            <div className='oop-indication__checkbox med-checkbox'>
                <Checkbox onChange={e => onDisableInitiationLoopChange(e.checked || false)}
                          checked={disableInitiationLoop || false}/>
                <label htmlFor='rb1' className='oop-indication__checbox-label p-checkbox-label'>
                    {t("disableInitiationLoopCheckbox")}
                </label>
            </div>
        </div>
    );
};

export const DeviceInitiationBehavior = connect(mapStateToProps, null)(_DeviceInitiationBehavior);