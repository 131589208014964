import { schedulerEventActionTypes } from "../actions";
import { SchedulerEventsAction } from "../actions/schedulerEvent.action";
import { SchedulerEvent } from "../models/scheduler.model";

const initialState: SchedulerEvent[] =  [];

export const schedulerEventReducer = (state: SchedulerEvent[] = initialState, action: SchedulerEventsAction) => {
    switch (action.type) {
        case schedulerEventActionTypes.FETCH_EVENTS:
            return [...action.payload];
        case schedulerEventActionTypes.ADD_EVENT:
            return [...state, action.payload];
        case schedulerEventActionTypes.UPDATE_EVENT:
            return state.map(event => event.id === action.payload.id ? action.payload : event);
        case schedulerEventActionTypes.DELETE_EVENT:
            return state.filter(event => event.id !== action.payload);
        default:
            return state;
    }
};