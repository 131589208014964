import {
    CommunicationErrorType,
} from "../models/device.model";
import { OOPIcon } from "./OOP";
import { PowerNotConnectedIcon } from "./PowerNotConnected";
import { TechnicalErrorIcon } from "./TechnicalError";
import { WifiNoConnectionIcon } from "./WifiNoConnection";

interface CommunicationErrorIconProps {
    communicatoinErrorType: CommunicationErrorType;
    height?: string;
    width?: string;
}

export const CommunicationErrorIcon = ({
    communicatoinErrorType,
    height = "6.4rem",
    width = "6.4rem",
}: CommunicationErrorIconProps) => {
    switch (communicatoinErrorType) {
        case CommunicationErrorType.TECHNICAL:
            return (
                <div className="communication-error-icon">
                    <TechnicalErrorIcon height={height} width={width} />
                </div>
            );
        case CommunicationErrorType.OOP:
            return (
                <div className="communication-error-icon">
                    <OOPIcon height={height} width={width} />
                </div>
            );
        case CommunicationErrorType.POWER_NOT_CONNECTED:
            return (
                <div className="communication-error-icon">
                    <PowerNotConnectedIcon height={height} width={width} />
                </div>
            );
        case CommunicationErrorType.DEVICE_OFF:
            return <div className="communication-error-icon"></div>;
        case CommunicationErrorType.CONNECTION_ERROR:
            return (
                <div className="communication-error-icon">
                    <WifiNoConnectionIcon height={height} width={width} />
                </div>
            );

        default:
            return <div className="communication-error-icon"></div>;
    }
};
