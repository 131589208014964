import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { Skeleton } from 'primereact/skeleton';
import { Column } from "primereact/column";
import { StoreState } from "src/reducers";
import { DeviceModel } from "src/models/device.model";

interface DeviceListSelectorResult {
    devices: DeviceModel[] | undefined;
    isDevicesObjUpdated: boolean;
}

const DeviceListSelector = ({ deviceObject } : StoreState): DeviceListSelectorResult => {
    return {
        devices: deviceObject?.devices || [],
        isDevicesObjUpdated: deviceObject?.isDevicesObjUpdated || false
    }
}

export const DeviceList = () => {
    const { t } = useTranslation();
    const {devices, isDevicesObjUpdated} = useSelector(DeviceListSelector, shallowEqual)
    const navigate: NavigateFunction = useNavigate();

    const navigateToDeviceSetting = (event: DataTableRowClickEvent) => {
        navigate(`/settings/devices/${event.data.id}`);
    };

    const patientNameBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="custom-table__data">
                {rawData?.patient?.first_name
                    ? rawData?.patient?.first_name
                    : "" + " " + rawData?.patient?.last_name
                    ? rawData?.patient?.last_name
                    : ""}
            </div>
        );
    };

    const deviceSerialBodyTemplate = (rawData: DeviceModel) => <div className="custom-table__data">{rawData.serial}</div>

    const deviceIdTemplate = (rawData: DeviceModel) => <div className="custom-table__data">{rawData.id}</div>

    const roomNumberBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="custom-table__data">
                {rawData?.patient?.room_number}
            </div>
        );
    };

    const getRowClass = () => ({"custom-table__table-row": true});
    
    const HEIGHT = 3;
    const skeletonItems = [{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT}];

    const bodyTemplate = (height: any) => <Skeleton height={`${height.height}rem`} className="overview__data skeleton"/>

    const skeletonTable = () => {
        return (
            <DataTable value={skeletonItems} className="p-datatable-striped">
                <Column header={t("DEVICE SERIAL")} style={{ width: "25%" }} body={bodyTemplate}/>
                <Column header={t("MAC ADDRESS")} style={{ width: "25%" }} body={bodyTemplate}/>
                <Column header={t("PATIENT NAME")} style={{ width: "25%" }} body={bodyTemplate}/>
                <Column header={t("PATIENT ROOM")} style={{ width: "25%" }} body={bodyTemplate}/>
            </DataTable>
        );
    };

    return (
        <div className="device-list">
            {isDevicesObjUpdated ? <DataTable
                rowHover
                className="custom-table"
                value={devices}
                rowClassName={getRowClass}
                responsiveLayout="scroll"
                onRowClick={navigateToDeviceSetting}
                emptyMessage={t("No devices found")}
            >
                <Column header={t("DEVICE SERIAL")} body={deviceSerialBodyTemplate}/>
                <Column header={t("MAC ADDRESS")} body={deviceIdTemplate}/>
                <Column header={t("PATIENT NAME")} body={patientNameBodyTemplate}/>
                <Column header={t("PATIENT ROOM")} body={roomNumberBodyTemplate}/>
            </DataTable> : skeletonTable()}
        </div>
    );
};
