import _ from "lodash";
import iotService from "../services/iot.service";
import { DeviceActionTypes, DeviceAction } from "../actions";
import {
    DeviceModel,
    DeviceObject,
    SettingsDeltaModel,
} from "../models/device.model";

export const deviceReducer = (
    state: DeviceObject = { devices: [], subscriptions: [], isDevicesObjUpdated: false },
    action: DeviceAction
): DeviceObject => {
    switch (action.type) {
        case DeviceActionTypes.FETCH_DEVICES:
            return { ...state, devices: action.payload, isDevicesObjUpdated: true};

        case DeviceActionTypes.DELETE_DEVICE:
            return {
                ...state,
                devices: state.devices.filter(
                    (device: DeviceModel) => device.id !== action.payload
                ),
            };

        case DeviceActionTypes.UPDATE_REPORTED_SETTINGS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.shadow.settings = _.merge(
                            device.shadow.settings,
                            action.payload.settings
                        );
                    }

                    if (device.shadow?.settingsDeltaMap) {
                        let settingsDelta = device.shadow.settingsDeltaMap.get(
                            action.payload.shadowName
                        );
                        if (
                            settingsDelta &&
                            settingsDelta.version >= action.payload.version
                        ) {
                            device.shadow.settings = _.merge(
                                device.shadow.settings,
                                settingsDelta.settings
                            );
                        }
                    }

                    return device;
                }),
            };

        case DeviceActionTypes.UPDATE_DELTA_SETTINGS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (
                        device.id === action.payload.deviceId &&
                        device.shadow
                    ) {
                        device.shadow.settings = _.merge(
                            device.shadow.settings,
                            action.payload.settings
                        );
                        if (!device.shadow.settingsDeltaMap) {
                            device.shadow.settingsDeltaMap = new Map<
                                string,
                                SettingsDeltaModel
                            >();
                        }
                        device.shadow.settingsDeltaMap.set(
                            action.payload.shadowName,
                            {
                                version: action.payload.version,
                                settings: action.payload.settings,
                            }
                        );
                    }

                    return device;
                }),
            };

        case DeviceActionTypes.UPDATE_REPORTED_STATE:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.shadow.state = _.merge(
                            device.shadow.state,
                            action.payload.state
                        );
                    }

                    return device;
                }),
            };
        case DeviceActionTypes.UPDATE_DEVICE_MODEL:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.state.id) {
                        device = _.merge(device, action.payload.state);
                    }

                    return device;
                }),
            };
        case DeviceActionTypes.UPDATE_PATIENT:
            const devices = state.devices.map((device) => {
                if (device.id === action.payload.oldDevice.id) {
                    device = _.merge(device, action.payload.oldDevice);
                } 

                else if (device.id === action.payload.newDevice.id) {
                    device = _.merge(device, action.payload.newDevice);
                }

                return device;
            });
            
            return {
                ...state,
                devices
            };
        case DeviceActionTypes.UPDATE_PATIENT_IMAGE:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        if (device.patient) {
                            device.patient.profile_image = action.payload.image
                        }
                    }

                    return device;
                }),
            };
        case DeviceActionTypes.UPDATE_WIFI_STRENGTH:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.state.id) {
                        device.wifiStrength = _.merge(device.wifiStrength, action.payload.state.wifiStrength);
                    }

                    return device;
                }),
            };
        case DeviceActionTypes.SAVE_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: [
                    ...state.subscriptions,
                    action.payload,
                ],
            };
        case DeviceActionTypes.REMOVE_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: []
            }
        case DeviceActionTypes.UPDATE_COMMUNICATION_LOGS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.communicationLogs =
                            device?.communicationLogs?.concat(
                                action.payload.communicationLogs
                            );
                    }
                    return device;
                }),
            };
        case DeviceActionTypes.UPDATE_NOTIFICATIONS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.notifications = action.payload.notifications;
                    }
                    return device;
                }),
            };
        case DeviceActionTypes.UPDATE_QNA_STATUS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.device.id) {
                        device.notifications.map(notification => {
                            if(notification.configurations.isRead === false) {
                                notification.configurations.isRead = true;
                            }
                        });
                    }
                    return device;
                }),
            };
        case DeviceActionTypes.ATTACH_PATIENT_TO_DEVICE:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.patient = action.payload.patient;
                        device.isReset = false;
                    }
                    return device;
                }),
            };
        case DeviceActionTypes.DETACH_PATIENT_FROM_DEVICE:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    if (device.id === action.payload.deviceId) {
                        device.patient = undefined;
                        device.notifications = [];
                        device.communicationLogs = [];
                        device.isReset = true;
                    }
                    return device;
                }),
            };
        case DeviceActionTypes.DELETE_DEVICES:
            if (state.subscriptions.length !== 0) {
                state.subscriptions.forEach((sub) => {
                    if (sub) {
                        iotService.unsubscribeToTopic(sub.subscription);
                    }
                });
            }
            return {
                ...state,
                subscriptions: [],
                devices: [],
                isDevicesObjUpdated: false
            };

        default:
            return state;
    }
};
