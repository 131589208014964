import { useTranslation } from "react-i18next";
import { TechnicalErrorIcon } from "../../icons/TechnicalError";
import {
    EyeCloseIcon,
    OOPIcon,
    PowerNotConnectedIcon,
    WifiStrengthIcon,
} from "../../icons";
import { DeviceConnectionStatus, DeviceModel } from "../../models/device.model";

interface PatientInformationBarProps {
    device: DeviceModel | undefined;
}

// TODO: create a general component and think about grouping the icon logic
const getIsHealthy = (
    isHealthy: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined
) => {
    return !(isHealthy || isHealthy === undefined) &&
        deviceState === DeviceConnectionStatus.CONNECTED ? (
        <TechnicalErrorIcon height={"2rem"} width={"2rem"} />
    ) : (
        ""
    );
};

const getIsOOP = (
    isOOP: boolean | undefined,
    oopDashboardIndication: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined
) => {
    return deviceState === DeviceConnectionStatus.CONNECTED &&
        isOOP &&
        oopDashboardIndication !== false ? (
        <OOPIcon />
    ) : (
        ""
    );
};

const getIsConnected = (
    isPower: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined
) => {
    return !(isPower || isPower === undefined) &&
        deviceState === DeviceConnectionStatus.CONNECTED ? (
        <PowerNotConnectedIcon height={"2rem"} width={"2rem"} />
    ) : (
        ""
    );
};

export const PatientInformationBar = ({
    device,
}: PatientInformationBarProps): JSX.Element => {
    const { t } = useTranslation();

    const technicalErrorIcon = getIsHealthy(
        device?.shadow?.state?.is_healthy,
        device?.shadow?.state?.presence?.state
    );
    
    const powerNotConnectedIcon = getIsConnected(
        device?.shadow?.state?.pwr,
        device?.shadow?.state?.presence?.state);

    const oopIcon = getIsOOP(
        device?.shadow?.state?.oop,
        device?.shadow?.settings?.oop_dashboard_indication,
        device?.shadow?.state?.presence?.state
    );

    return (
        <div className="patient-information-bar">
            <div className="patient-information-bar__full-name-wrapper">
                <div className="patient-information-bar__full-name">
                    <div className="patient-information-bar__first-name">
                        {device?.patient?.first_name}
                    </div>
                    <div className="patient-information-bar__last-name">
                        {device?.patient?.last_name}
                    </div>
                </div>
            </div>
            <div className="patient-information-bar__info-icons-box">
                <div className="patient-information-bar__general-info">
                    <div className="patient-information-bar__room">
                        <div className="patient-information-bar__room-number">
                            {device?.patient?.room_number}
                        </div>
                        <div className="patient-information-bar__room-text patient-information-bar__text-font">
                            {t("Room")}
                        </div>
                    </div>
                    <div className="patient-information-bar__passkey-wrapper"></div>
                    <div className="patient-information-bar__device-number-wrapper">
                        <div className="patient-information-bar__device-number">
                            {device?.serial}
                        </div>
                        <div className="patient-information-bar__device-number-text patient-information-bar__text-font">
                            {t("Device number")}
                        </div>
                    </div>
                </div>
                <div className="patient-information-bar__icons">
                    <div className={technicalErrorIcon === "" ? "" : "patient-information-bar__not-last-icon"}>
                        {technicalErrorIcon}
                    </div>
                    <div className={oopIcon === "" ? "" : "patient-information-bar__not-last-icon"}>
                        {oopIcon}
                    </div>
                    <div className={powerNotConnectedIcon === "" ? "" : "patient-information-bar__not-last-icon"}>
                        {powerNotConnectedIcon}
                    </div>
                    <WifiStrengthIcon
                        currentStrength={device?.wifiStrength?.current}
                        connectionState={device?.shadow?.state?.presence}
                    />
                </div>
            </div>
        </div>
    );
};
