import { useEffect, useState } from "react";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateSettingsField } from "../../../actions/device.action";
import { BroadcastMethod, DeviceModel } from "../../../models/device.model";
import { RadioButton } from 'primereact/radiobutton';

interface BroadcastMethodCardProps {
    devices?: DeviceModel[];
}

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _BroadcastMethodCard = ({
    devices,
}: BroadcastMethodCardProps): JSX.Element => {
    const { t } = useTranslation();
    let params = useParams();
    let [broadcastMethod, setBroadcastMethod] = useState<BroadcastMethod>();
    let [isCurrentValueFromShadow, setIsCurrentValueFromShadow] = useState<boolean>(false);
    let deviceId = params.deviceId ? params.deviceId : "";

    const resetBroadcastMethod = () => {
        onBroadcastMethodChange(BroadcastMethod.EARPHONE);
    }

    const onBroadcastMethodChange = (value: BroadcastMethod) => {
        if(value !== broadcastMethod) {
            setBroadcastMethod(value);
            updateSettingsField(deviceId, "broadcast_method", { broadcast_method: value });
        }
    };

    useEffect(() => {
        let currentDevice = devices?.find((device) => device.id === deviceId);

        if (currentDevice && (!isCurrentValueFromShadow || currentDevice.isReset)) {
            setBroadcastMethod(currentDevice.shadow?.settings?.broadcast_method as BroadcastMethod);
            setIsCurrentValueFromShadow(true);
        }
    }, [devices]);

    return (
        <div className="card-container">
            <div className="broadcast-method__header-wrapper">
                <div className="card-container__title">{t("broadcastMethod")}</div>
            </div>
            <div className="broadcast-method__description">{t("Select the channel from which you would like the communication to be played to the patient")}</div>
            <div className='broadcast-method__radio-button'>
                <RadioButton
                    inputId='rb1'
                    name='broadcast-method'
                    value={BroadcastMethod.EARPHONE}
                    onChange={(e: any) => {onBroadcastMethodChange(e.value)}}
                    checked={broadcastMethod === BroadcastMethod.EARPHONE}
                />
                <label htmlFor='rb1' className='broadcast-method__radio-button-label p-radiobutton-label'>
                    {t("earphone")}
                </label>
            </div>
            <div className='broadcast-method__radio-button'>
                <RadioButton
                    inputId='rb2'
                    name='broadcast-method'
                    value={BroadcastMethod.SPEAKER}
                    onChange={(e: any) => {onBroadcastMethodChange(e.value)}}
                    checked={broadcastMethod === BroadcastMethod.SPEAKER}
                />
                <label htmlFor='rb2' className='broadcast-method__radio-button-label p-radiobutton-label'>
                    {t("speaker")}
                </label>
            </div>
        </div>
    );
};

export const BroadcastMethodCard = connect(
    mapStateToProps,
    null
)(_BroadcastMethodCard);