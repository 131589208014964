import { ToastModel } from "../models/toast.model";
import { toastActionTypes } from "./types";

export interface UpdateToastAction {
    type: toastActionTypes;
    payload: ToastModel;
}

export type ToastAction = UpdateToastAction;

export const updateToast = (toastObj: ToastModel) => {
    return {
        type: toastActionTypes.UPDATE_TOAST,
        payload: toastObj,
    };
};

export const clearToast = () => {
    return { type: toastActionTypes.CLEAR_TOAST };
};
