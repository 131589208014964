import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { RepeatEvent, SchedulerEvent } from "src/models/scheduler.model";
import { StoreState } from "src/reducers";
import { CommunicationIcon, RepeatIcon } from "src/icons";
import { DialogTypes, OpenDialogArgs } from "src/components/general/CustomDialog";
import { SchedulerEventForm } from "src/components/forms/ScheduleEventForm/SchedulerEventForm";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import { SchedulerNavigator } from "./SchedulerNavigator";
import { sortEventTimeStrings, findRowWithCurrentDate, getUpdatedEvents, isTodayUsingDay, groupByYearAndMonth } from './utils'
import { useParams } from "react-router-dom";

const daysInWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const MAX_NUMBER_OF_DAYS = 42;
const MAX_NUMBER_OF_WEEKS = 6;

interface CustomSchedulerSelectorResult {
    events?: SchedulerEvent[]
}

const customSchedulerSelector = ({schedulerEvents}: StoreState): CustomSchedulerSelectorResult => ({
    events: schedulerEvents || []
})

export const CustomScheduler = (): JSX.Element => {
    const { t } = useTranslation();
    const { events} = useSelector(customSchedulerSelector, shallowEqual)
    const { showDialog, hide} = React.useContext(DialogContext) as DialogPropTypes;
    const [selectedDate, setSelectedDate] = useState<Moment>(moment());
    const [rowIndex, setRowIndex] = useState<number>(0);
    const [sevenDayRows, setSevenDayRows] = useState<JSX.Element[]>([]);
    const [eventsRows, setEventsRows] = useState<JSX.Element[]>([]);
    const [weeksArray, setWeeksArray] = useState<number[][]>([]);

    const params = useParams();
    const deviceId = params.deviceId;

    const handlePrevMonth = () => {
        if (rowIndex - 1 < 0) {
            const previousMonth = selectedDate.clone().subtract(1, "month");
            setSelectedDate(previousMonth);
            const { eventsMap, repeatEvents } = groupByYearAndMonth(events || []);
            const currentCalendar = createCalendar(previousMonth, eventsMap, repeatEvents);

            if (checkIfRowContainsCurrentMonth(currentCalendar.currentWeeksArray)) {
                setRowIndex(MAX_NUMBER_OF_WEEKS - 2);
            } else {
                setRowIndex(MAX_NUMBER_OF_WEEKS - 3);
            }
        } else {
            setRowIndex(rowIndex - 1);
        }
    };

    const handleNextMonth = () => {
        if (rowIndex + 1 >= MAX_NUMBER_OF_WEEKS - 1) {
            if (checkIfRowContainsCurrentMonth(weeksArray) && rowIndex !== MAX_NUMBER_OF_WEEKS - 1) {
                setRowIndex(rowIndex + 1);
            } else {
                const nextMonth = selectedDate.clone().add(1, "month");
                setSelectedDate(nextMonth);
                const { eventsMap, repeatEvents } = groupByYearAndMonth(events || []);
                createCalendar(nextMonth, eventsMap, repeatEvents);
                setRowIndex(1);
            }
        } else {
            setRowIndex(rowIndex + 1);
        }
    };

    const checkIfRowContainsCurrentMonth = (currentWeeksArray: number[][]) => currentWeeksArray[MAX_NUMBER_OF_WEEKS - 1][0] > 8;

    const handleOpenEditEventDialog = (schedulerEvent: SchedulerEvent) => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Edit Event"),
            component: <SchedulerEventForm schedulerEvent={schedulerEvent} onClose={hide} />,
            actionText: t("Edit"),
            cancelText: t("Cancel"),
        };

        showDialog(args);
    }

    const createEventItem = (events: SchedulerEvent[]) => {
        const sortedEvents = sortEventTimeStrings(events);

        return (
            <div className="custom-scheduler__day-items">
                {sortedEvents.map((event: SchedulerEvent) =>{
                    const {id, communicationTypeId, time, text, repeat} = event

                    return (
                        <div key={id}
                             className={`custom-scheduler__day-item custom-scheduler__day-item--border-color-${communicationTypeId} 
                                ${deviceId ? "custom-scheduler__day-item__device ": ""}`}
                             onClick={deviceId ? undefined: () => handleOpenEditEventDialog(event)}
                        >
                            <div className="custom-scheduler__day-item__content">
                                <div className="custom-scheduler__day-item__hour">
                                    {time}
                                </div>
                                <div className="custom-scheduler__day-item__communicatoin-wrapper">
                                    <div className="custom-scheduler__day-item__communicatoin-icon">
                                        <CommunicationIcon
                                            width="1.2rem"
                                            isWrapped={false}
                                            communicatoinType={communicationTypeId}
                                        />
                                    </div>
                                    <div className="custom-scheduler__day-item__communicatoin-text">
                                        {text}
                                    </div>
                                </div>
                            </div>
                            <div className="custom-scheduler__day-item__repeat_icon">
                                {repeat.isActive ? <RepeatIcon /> : null}
                            </div>
                        </div>
                    )
                } )}
            </div>
        );
    };

    const insertEventsToCell = (groupedEvents: any, repeatEvents: RepeatEvent[], year: number, month: number, day: number) => {
        let eventsArray: SchedulerEvent[] = getUpdatedEvents(groupedEvents,repeatEvents, year, month, day);

        return !!eventsArray.length ? createEventItem(eventsArray) : null
    };

    const createCalendar = (currentSelectedDate: Moment, groupedEvents: any, repeatEvents: RepeatEvent[]) => {
        const firstDayOfMonth = currentSelectedDate.startOf("month");

        let day = firstDayOfMonth.day();
        let dayBack = 0;

        const daysInMonth = currentSelectedDate.daysInMonth();
        let prevMonth = currentSelectedDate.month() - 1;
        let prevOrCurrentYear = currentSelectedDate.year();

        let nextMonth = currentSelectedDate.month() + 1;
        let nextOrCurrentYear = currentSelectedDate.year();

        // If January
        if (prevMonth === -1) {
            prevMonth = 11;
            prevOrCurrentYear -= 1;
        }

        if (nextMonth === 12) {
            nextMonth = 0;
            nextOrCurrentYear += 1;
        }

        const daysInPrevMonth = moment([prevOrCurrentYear, prevMonth]).daysInMonth();

        let dateCells: JSX.Element[] = [];
        let eventCells: JSX.Element[] = [];
        let week: number[] = [];

        let currentSevenDayRows = [];
        let currentSevenDayEventsRows = [];
        let currentWeeksArray = [];

        for (let i = 0; i < MAX_NUMBER_OF_DAYS; i++) {
            if (i < firstDayOfMonth.day()) {
                const previousMonthDay = daysInPrevMonth - firstDayOfMonth.day() + i + 1;
                dayBack++;
                week.push(previousMonthDay);
                dateCells.push(
                    <td key={i} className="custom-scheduler__cell custom-scheduler__cell--other-month">
                        <div className="custom-scheduler__day">
                            <div className={`custom-scheduler__day-number ${
                                    isTodayUsingDay(prevOrCurrentYear, currentSelectedDate.month(), previousMonthDay)
                                        ? "custom-scheduler__day-number--selected"
                                        : ""
                                }`}
                            >
                                <span>{previousMonthDay}</span>
                            </div>
                        </div>
                    </td>
                );

                eventCells.push(
                    <td
                        key={i}
                        className="custom-scheduler__cell custom-scheduler__event-cell custom-scheduler__cell--other-month"
                    >
                        {insertEventsToCell(groupedEvents, repeatEvents, prevOrCurrentYear, prevMonth, previousMonthDay)}
                    </td>
                );
            } else if (i >= daysInMonth + firstDayOfMonth.day()) {
                const nextMonthDay = i - daysInMonth - firstDayOfMonth.day() + 1;

                week.push(nextMonthDay);
                dateCells.push(
                    <td key={i} className="custom-scheduler__cell custom-scheduler__cell--other-month">
                        <div className="custom-scheduler__day">
                            <div className={`custom-scheduler__day-number ${
                                    isTodayUsingDay(nextOrCurrentYear, nextMonth, nextMonthDay)
                                        ? "custom-scheduler__day-number--selected"
                                        : ""
                                }`}
                            >
                                <span>{nextMonthDay}</span>
                            </div>
                        </div>
                    </td>
                );
                eventCells.push(
                    <td
                        key={i}
                        className="custom-scheduler__cell custom-scheduler__event-cell custom-scheduler__cell--other-month"
                    >
                        {insertEventsToCell(groupedEvents, repeatEvents, nextOrCurrentYear, nextMonth, nextMonthDay)}
                    </td>
                );
            } else {
                const currentMonthDay = day - dayBack + 1;
                const isToday = isTodayUsingDay(currentSelectedDate.year(), currentSelectedDate.month(), currentMonthDay)
                week.push(currentMonthDay);
                dateCells.push(
                    <td key={i} className="custom-scheduler__cell">
                        <div className="custom-scheduler__day">
                            <div
                                className={`custom-scheduler__day-number ${isToday
                                        ? "custom-scheduler__day-number--selected"
                                        : ""
                                }`}
                            >
                                <span>{currentMonthDay}</span>
                            </div>
                        </div>
                    </td>
                );
                eventCells.push(
                  <td
                      key={i}
                      className="custom-scheduler__cell custom-scheduler__event-cell custom-scheduler__cell--other-month"
                  >
                        {insertEventsToCell(
                            groupedEvents,
                            repeatEvents,
                            currentSelectedDate.year(),
                            currentSelectedDate.month(),
                            currentMonthDay
                        )}
                  </td>
              );
                day++;
            }

            if (i % 7 === 6) {
                currentWeeksArray.push(week);
                currentSevenDayRows.push(<tr className="custom-scheduler__row custom-scheduler__date-row">{dateCells}</tr>);
                currentSevenDayEventsRows.push(<tr className="custom-scheduler__row custom-scheduler__events-row">{eventCells}</tr>);
                week = [];
                dateCells = [];
                eventCells = [];
            }
        }

        if (dateCells.length > 0) {
            currentWeeksArray.push(week);
            currentSevenDayRows.push(<tr className="custom-scheduler__row custom-scheduler__date-row">{dateCells}</tr>);
            currentSevenDayEventsRows.push(<tr className="custom-scheduler__row custom-scheduler__events-row">{eventCells}</tr>);
        }

        setWeeksArray(currentWeeksArray);
        setSevenDayRows(currentSevenDayRows);
        setEventsRows(currentSevenDayEventsRows);

        return { currentWeeksArray, currentSevenDayRows };
    };

    useEffect(() => {
        const { eventsMap, repeatEvents } = groupByYearAndMonth(events || []);
        const calendarObj = createCalendar(moment(), eventsMap, repeatEvents);
        const rowIndex = findRowWithCurrentDate(calendarObj.currentWeeksArray, moment().date()) || 0;

        setRowIndex(rowIndex);
    }, [events]);

    return (
        <div className="custom-scheduler">
            <div className="custom-scheduler__navigator">
                <SchedulerNavigator
                    handlePrevMonth={handlePrevMonth}
                    handleNextMonth={handleNextMonth}
                    selectedDate={selectedDate}
                />
            </div>
            <table className="custom-scheduler__table">
                <thead className="custom-scheduler__header">
                    <tr className="custom-scheduler__row">
                        {daysInWeek.map((day) => (
                            <th key={day} className="custom-scheduler__cell custom-scheduler__cell--header">
                                {t(day)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="custom-scheduler__body">
                    {sevenDayRows[rowIndex]}
                    {eventsRows[rowIndex]}
                </tbody>
            </table>
        </div>
    );
};
