import { Moment } from "moment";
import { MouseEventHandler } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "./../../../icons"

interface SchedulerNavigatorProps {
    handlePrevMonth: MouseEventHandler<HTMLButtonElement>;
    handleNextMonth: MouseEventHandler<HTMLButtonElement>;
    selectedDate: Moment
}

export const SchedulerNavigator = ({
    handlePrevMonth,
    handleNextMonth,
    selectedDate,
}: SchedulerNavigatorProps): JSX.Element => {
    return (
        <div className="scheduler-navigator">
            <div className="scheduler-navigator__header">
                <button
                    className="scheduler-navigator__arrow-btn scheduler-navigator__arrow-btn--left"
                    onClick={handlePrevMonth}
                >
                   <ArrowLeftIcon/>
                </button>
                <div className="scheduler-navigator__month-year">
                    {selectedDate.format("MMMM YYYY")}
                </div>
                <button
                    className="scheduler-navigator__arrow-btn scheduler-navigator__arrow-btn--right"
                    onClick={handleNextMonth}
                >
                    <ArrowRightIcon/>
                </button>
            </div>
        </div>
    );
};
