import { AuthenticationAction, AuthenticationActionTypes } from 'src/actions';
import { AuthenticationModel } from 'src/models/user.model';
import { getRole, getToken, getCurrentUser } from 'src/services/localStorageService';

const initialState: AuthenticationModel = {
    isAuthChecked: getToken() ? true : false,
    isAuthorized: getRole() ? true : false,
    isLoggedIn: getToken() ? true : false,
    currentUser: getCurrentUser(),
    errorMessage: "",
    authorization: getToken() && getRole() ? getRole() : null
};

export function authenticationReducer(state: AuthenticationModel = initialState, action: AuthenticationAction) {
    switch (action.type) {
        case AuthenticationActionTypes.AUTHENTICATED:
            const {user, role} = action.payload;
            return {
                ...state,
                isAuthChecked: true,
                isLoggedIn: true,
                currentUser: user,
                errorMessage: "",
                authorization: role,
                isAuthorized: true
            };
        case AuthenticationActionTypes.NOT_AUTHENTICATED:
            let errorMessage = "";
            if (typeof action.payload === "string" && action.payload) {
                errorMessage = action.payload;
            }
            return {
                ...state,
                isAuthChecked: true,
                isLoggedIn: false,
                currentUser: null,
                errorMessage: errorMessage,
                authorization: null,
                isAuthorized: false
            };
        default:
            return state;
    }
}
