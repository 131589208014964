import React from "react";
import { useTranslation } from "react-i18next";

import { SchedulerEvent } from "src/models/scheduler.model";
import { CommunicationIcon, NoScheduledEventsIcon, WrapSize } from "src/icons";

import NearBedSchedulerViewInformation from "./NearBedSchedulerViewInformation";
import {getEventDuration} from "./utils";

interface EventsScheduleProps {
    events: SchedulerEvent[];
    nextEventIndex: number;
    musicDuration: string;
    familyDuration: string;
}

const EventsSchedule = ({ events, nextEventIndex, musicDuration, familyDuration }: EventsScheduleProps): JSX.Element  => {
    const { t } = useTranslation();
    const areEventsExist = events.length > 0

    const renderEvent = (event: SchedulerEvent, index: number) => {
        const { time, text, id, communicationTypeId } = event
        const isNextEvent = index === nextEventIndex
        const duration = getEventDuration({ communicationTypeId, musicDuration, familyDuration })

        return (
            <li key={id} className="near-bed-scheduler-view__item">
                <CommunicationIcon
                    width="4rem"
                    height="4rem"
                    communicatoinType={communicationTypeId}
                    wrapSize={WrapSize.MEDIUM}
                />
                <div className={`near-bed-scheduler-view__line near-bed-scheduler-view__line--${communicationTypeId}`}/>
                <div
                    className={`near-bed-scheduler-view__information-wrapper near-bed-scheduler-view__information-wrapper-dialog ${
                        isNextEvent ? "near-bed-scheduler-view__information-wrapper-dialog--next" : ""
                    }`}
                >
                    <NearBedSchedulerViewInformation time={time} text={text} duration={duration} />
                </div>
            </li>
        );
    }

    if (areEventsExist) {
        return (
            <div className="near-bed-scheduler-view__items-wrapper">
                <ul className="near-bed-scheduler-view__items near-bed-scheduler-view__items__daily-scheduled">
                    {events.map(renderEvent)}
                </ul>
            </div>
        )
    }

    return (
        <div className="near-bed-scheduler-view__empty-popup">
            <div className="near-bed-scheduler-view__no-scheduled-icon">
                <NoScheduledEventsIcon/>
            </div>
            <div className="near-bed-scheduler-view__empty-text">
                {t("No future communication scheduled")}
            </div>
        </div>
    )
}

export default EventsSchedule;