import React from "react";

export const CloseBigIcon = ({
    color = "#0F284C",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1028_22188)">
            <rect
                x="14.3643"
                y="0.22168"
                width="2"
                height="20"
                rx="1"
                transform="rotate(45 14.3643 0.22168)"
                fill={color}
            />
            <rect
                x="15.7783"
                y="14.3638"
                width="2"
                height="20"
                rx="1"
                transform="rotate(135 15.7783 14.3638)"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_1028_22188">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
