export enum ResourceAction {
    CREATE = "create",
    READ = "read",
    UPDATE = "update",
    DELETE = "delete",
    ALL = "*"
};

export enum PermissionTypes {
    ALLOW = "allow",
    DENY = "deny"
};

export enum ViewTypes {
    NURSE = "NURSE",
    REGULAR = "REGULAR"
};

export type AuthorizationModel = {
    name: string;
    permissions: {
        pages: PagePermissionModel[];
        resources: ResourcePermissionModel[];
    },
    views: ViewTypes[]
};

export type ResourcePermissionModel = {
    name: string;
    type: PermissionTypes;
    actions?: ResourceAction[];
};

export type PagePermissionModel = {
    name: string;
    type: PermissionTypes;
};