import { NavigateFunction, useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { isAlert } from "src/utils/generalMethods";
import { OOPIcon, RoomIcon, WifiStrengthIcon, PowerNotConnectedIcon, TechnicalErrorIcon } from "src/icons";
import { DeviceConnectionStatus, DeviceModel} from "src/models/device.model";

interface PatientSideBarProps {
    devices: DeviceModel[] | undefined;
    activeDeviceId: string | undefined;
}

const getWifiStrengthIcon = (device: DeviceModel, iconColor: string) => {
    return (
        <WifiStrengthIcon color={iconColor} currentStrength={device?.wifiStrength?.current} connectionState={device?.shadow?.state?.presence}/>
    );
};

const getIsOOP = (
    isOOP: boolean | undefined,
    oopDashboardIndication: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined
) => {
    return deviceState === DeviceConnectionStatus.CONNECTED &&
        isOOP &&
        oopDashboardIndication !== false ? (
        <OOPIcon />
    ) : (
        ""
    );
};

const getIsConnected = (
    isPower: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined
) => {
    return !(isPower || isPower === undefined) &&
        deviceState === DeviceConnectionStatus.CONNECTED ? (
        <PowerNotConnectedIcon height={"1.2rem"} width={"1.2rem"} />
    ) : (
        ""
    );
};

const getIsHealthy = (
    isHealthy: boolean | undefined,
    deviceState: DeviceConnectionStatus | undefined,
    iconColor: string
) => {
    return !(isHealthy || isHealthy === undefined) &&
        deviceState === DeviceConnectionStatus.CONNECTED ? (
        <TechnicalErrorIcon color={iconColor} height={"1.2rem"} width={"1.2rem"} />
    ) : (
        ""
    );
};


export const PatientSideBar = ({
    devices,
    activeDeviceId
}: PatientSideBarProps): JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const selectPatient = (selectedDeviceId: string) => {
        navigate(`/patientview/${selectedDeviceId}`);
    }
    
    const createDeviceList = () => {
        return (
            <ul className="patient-side-bar__items">
                {devices?.map((device, index) => {
                    const iconColor = device.id === activeDeviceId ? "#FFF" : "#0F284C";
                    const liClasses = classNames({
                        "patient-side-bar__item": true,
                        "patient-side-bar__active-item":
                            device.id === activeDeviceId,
                        "patient-side-bar__alert": isAlert(device)
                    });
                    return (
                        <li
                            className={liClasses}
                            key={index}
                            onClick={() => {
                                selectPatient(device.id);
                            }}
                        >
                            <div className="patient-side-bar__general-box">
                                <div className="patient-side-bar__name-box">
                                    <span className="patient-side-bar__first-name">
                                        {device.patient?.first_name}
                                    </span>
                                    <span className="patient-side-bar__last-name">
                                        {device.patient?.last_name}
                                    </span>
                                </div>
                                <div className="patient-side-bar__information">
                                    <div className="patient-side-bar__room-box">
                                        <div className="patient-side-bar__room-wrapper">
                                            <div className="patient-side-bar__small-icon">
                                                <RoomIcon color={iconColor} />
                                            </div>
                                            <span className="patient-side-bar__room-number patient-side-bar__number">
                                                {device.patient?.room_number}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="patient-side-bar__icons">
                                        <div className="patient-side-bar__oop-icon">
                                            {getIsHealthy(
                                                device?.shadow?.state?.is_healthy,
                                                device?.shadow?.state?.presence
                                                    ?.state,
                                                iconColor
                                            )}
                                        </div>
                                        <div className="patient-side-bar__oop-icon">
                                            {getIsOOP(
                                                device?.shadow?.state?.oop,
                                                device?.shadow?.settings
                                                    ?.oop_dashboard_indication,
                                                device?.shadow?.state?.presence
                                                    ?.state
                                            )}
                                        </div>
                                        <div className="patient-side-bar__cable-connection">
                                            {getIsConnected(
                                                device?.shadow?.state?.pwr,
                                                device?.shadow?.state?.presence
                                                    ?.state
                                            )}
                                        </div>
                                        <div className="patient-side-bar__wifi-icon">
                                            {getWifiStrengthIcon(
                                                device,
                                                iconColor
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return <div className="patient-side-bar">{createDeviceList()}</div>;
};
