import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SideBarProps {
  items: { label: string; link: string }[];
  activeIndex: number;
  onChange: Function;
}

export const SideBar = ({
    items,
    onChange,
    activeIndex,
}: SideBarProps): JSX.Element => {
    const selectTab = (index: number) => {
        onChange(index);
    };

    const { t } = useTranslation();

    let createSidebarItems = () => {
        return (
            <div className="side-bar">
                <ul className="side-bar__items">
                    {items.map((item, index) => {
                        return (
                            <li
                                onClick={() => selectTab(index)}
                                className={`side-bar__item ${
                                    index === activeIndex
                                        ? "side-bar__active-item"
                                        : ""
                                }`}
                                key={index}
                            >
                                <span className={"side-bar__item-label"}>
                                    {t(item.label)}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    return createSidebarItems();
};
