import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

const translateValue = <T extends object, K extends keyof T & string>(
    t: (key: string | TemplateStringsArray | (string | TemplateStringsArray)[]) => string,
    item: T,
    key: K
): T[K] => {
    const value = item[key];

    if (typeof value === 'string') return t(value) as T[K];

    return value;
};

export const useCustomTranslation = () => {
    const { t } = useTranslation();

    const getTranslatedArray = useCallback(<T extends object>(array: T[], keys: (keyof T & string)[]) => {
        return array.map(item => {
            const newItem: T = { ...item };

            for (const key of keys) {
                newItem[key] = translateValue(t, item, key);
            }

            return newItem;
        });
    }, [t]);

    const memoizedGetTranslatedArray = useMemo(() => getTranslatedArray, [getTranslatedArray]);

    return {
        t,
        getTranslatedArray: memoizedGetTranslatedArray,
    };
};