import { useState } from "react"

import TextInput, { TextInputProps } from "./TextInput"
import { EyeOpenIcon } from "../../../../icons/EyeOpen";
import { EyeCloseIcon } from "../../../../icons/EyeClose";

type PasswordInputProps = Omit<TextInputProps, 'type'>

const PasswordInput = (inputProps: PasswordInputProps) => {
    const [passwordIsVisible, setPasswordIsVisible] = useState(false)
    const { type, Icon } = passwordIsVisible ? { type: 'text', Icon: EyeCloseIcon } : { type: 'password', Icon: EyeOpenIcon }

    const handleToggleShowPassword = () => {
        setPasswordIsVisible(prevValue => !prevValue)
    }

    return (
        <span className="p-input-icon-right">
            <Icon onClick={handleToggleShowPassword} />
            <TextInput {...inputProps} type={type} />
        </span>
    )
}

export default PasswordInput