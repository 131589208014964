import { NotificationModel } from '../models/notification.model';
import { RecordingsObjModel } from './recording.model';

export enum ActiveCommunicationType {
    Q_AND_A = 1,
    CAM_ICU = 2,
    TUTORIAL = 3,
    STOP_COMMUNICATION = 4,
    ORIENTATION = 5,
    MUSIC = 6,
    FAMILY = 7,
    INSTRUCTIONS = 8,
    PERSONAL_MUSIC = 9,
    GENERAL_MESSAGE = 10,
    TRAINING = 11,
    NONE = 0,
}

export enum CommunicationErrorType {
    TECHNICAL = 1,
    OOP = 2,
    POWER_NOT_CONNECTED = 3,
    DEVICE_OFF = 4,
    CONNECTION_ERROR = 5,
    NONE = 0,
}

export const mapCommunicationErrorTypeToString = (type: CommunicationErrorType): string => {
    switch (type) {
        case CommunicationErrorType.TECHNICAL:
            return "The device is unavailable due to a technical reason. Contact ‘Eyecontrol’ support.";
        case CommunicationErrorType.OOP:
            return "OOP";
        case CommunicationErrorType.POWER_NOT_CONNECTED:
            return "The device is disconnected from a power source. Please make sure it’s connected properly.";
        case CommunicationErrorType.DEVICE_OFF:
            return " The device is turned off. Press the power button on the device to turn it on.";
        case CommunicationErrorType.CONNECTION_ERROR:
            return "The device is disconnected from the WIFI. Please contact ‘Eyecontrol’ support.";
        case CommunicationErrorType.NONE:
            return "";
        default:
            return "";
    }
}

export const  mapCommunicationTypeToString = (type: ActiveCommunicationType): string => {
    switch (type) {
        case ActiveCommunicationType.Q_AND_A:
            return "Q & A";
        case ActiveCommunicationType.CAM_ICU:
            return "CAM ICU";
        case ActiveCommunicationType.TUTORIAL:
            return "Tutorial";
        case ActiveCommunicationType.STOP_COMMUNICATION:
            return "Stop Communication";
        case ActiveCommunicationType.ORIENTATION:
            return "Orientation";
        case ActiveCommunicationType.INSTRUCTIONS:
            return "Instructions";
        case ActiveCommunicationType.MUSIC:
            return "Relaxing";
        case ActiveCommunicationType.PERSONAL_MUSIC:
            return "Personal";
        case ActiveCommunicationType.FAMILY:
            return "Family Messages";
        case ActiveCommunicationType.TRAINING:
            return "Training";
        case ActiveCommunicationType.GENERAL_MESSAGE:
            return "Message ";
        default:
            return "None";
    }
}

export const  getCommunicationSecLength = (
    type: ActiveCommunicationType, 
    // recordings: Map<string, RecordingsObjModel> | undefined,
    patientId: string | undefined
): number => {
    switch (type) {
        case ActiveCommunicationType.CAM_ICU:
            return 7 * 60;
        case ActiveCommunicationType.ORIENTATION:
            return 45;
        case ActiveCommunicationType.INSTRUCTIONS:
            return 1 * 60;
        case ActiveCommunicationType.MUSIC:
            return 16 * 60;
        case ActiveCommunicationType.PERSONAL_MUSIC:
            return 16 * 60;
        case ActiveCommunicationType.FAMILY:
            return 4 * 60;
        case ActiveCommunicationType.TRAINING:
            return 5 * 60;
        case ActiveCommunicationType.Q_AND_A:
            return 4 * 60;
        case ActiveCommunicationType.GENERAL_MESSAGE:
            // if (patientId && recordings?.get(patientId)) {
            //     console.log(recordings.get(patientId)?.generalRecordings);
                
            // } 
            return 4 * 60;
            // return 0;
        default:
            return 0;
    }
}

export const  mapCommunicationTypeToStringPlayButtons = (type: ActiveCommunicationType, questionName?: string): string => {
    switch (type) {
        case ActiveCommunicationType.Q_AND_A:
            if(questionName) {
                return questionName;
            }
            return "Q & A";
        case ActiveCommunicationType.CAM_ICU:
            return "CAM ICU";
        case ActiveCommunicationType.TRAINING:
            return "Training";
        case ActiveCommunicationType.TUTORIAL:
            return "Tutorial";
        case ActiveCommunicationType.STOP_COMMUNICATION:
            return "Stop Communication";
        case ActiveCommunicationType.ORIENTATION:
            return "Orientation";
        case ActiveCommunicationType.INSTRUCTIONS:
            return "Instructions";
        case ActiveCommunicationType.MUSIC:
            return "Music";
        case ActiveCommunicationType.PERSONAL_MUSIC:
            return "Music";
        case ActiveCommunicationType.FAMILY:
            return "Family Messages";
        case ActiveCommunicationType.GENERAL_MESSAGE:
            return "Message ";
        default:
            return "None";
    }
}

export enum webRtcStatus {
    BUSY = 0,
    AVAILABLE = 1,
    ERROR = 2,
}

export enum BroadcastMethod {
    SPEAKER,
    EARPHONE
}

export enum WifiStrength {
    WEAK,
    FAIR,
    GOOD,
}

export enum DeviceConnectionStatus {
    CONNECTED = "connected",
    DISCONNECTED = "disconnected"
}

export enum DisconnectionReason {
    CONNECTION_ERROR = "MQTT_KEEP_ALIVE_TIMEOUT",
    CLIENT_INITIATION = "CLIENT_INITIATED_DISCONNECT",
    CONNECTION_LOST = "CONNECTION_LOST"
}

export interface PatientModel {
    _id?: string;
    first_name?: string;
    last_name?: string;
    room_number?: number;
    bed_number?: number;
    family_secret?: string;
    profile_image?: string;
}

export interface DeviceObject {
    devices: DeviceModel[],
    subscriptions: any[],
    isDevicesObjUpdated: boolean
}

export interface DeviceShadowModel {
    settings: DeviceSettingsModel;
    state: DeviceStateModel;
    settingsDeltaMap?: Map<string, SettingsDeltaModel>;
    unsyncItems?: any;
}

export interface SettingsDeltaModel {
    version: number;
    settings: any;
}
export interface DeviceSettingsModel {
    patient_voice_id?: number;
    medical_staff_voice_id?: number;
    is_earphone?: number;
    periodic_check?: boolean;
    earphone_volume?: number;
    broadcast_method?: BroadcastMethod;
    oop_device_indication? :boolean;
    oop_dashboard_indication? :boolean;
    is_scheduler_on? :boolean;
    disable_initiation_loop? :boolean;
}

export interface ConnectionStateModel {
    state: DeviceConnectionStatus,
    disconnection_reason: DisconnectionReason,
    last_presence_timestamp: number
}
export interface DeviceStateModel {
    communication_state?: number;
    pwr?: boolean;
    oop?: boolean;
    wifi_dBm?: number;
    presence?: ConnectionStateModel;
    active_communication_info?: {
        id: string;
        id_type: ActiveCommunicationType;
        sub_type_info?: {
            slot_number: number;
            questionName?: string
        }
    };
    is_healthy?: boolean;
    is_healthy_timestamp?: number;
    device_state?: number;
}

export interface WifiStrengthModel {
    first: number;
    middle: number;
    last: number;
    currentAvg: number;
    previousAvg: number;
    current: WifiStrength
}

export interface CommunicationLogModel {
    communication_id: string;
    communication_type_id: number;
    message_type_id: number;
    text: string;
    time: string;
    source: string;
    // sub_type_info?: Object;
}

export interface DeviceModel {
    id: string;
    serial: string;
    patient?: PatientModel;
    shadow: DeviceShadowModel;
    wifiStrength: WifiStrengthModel;
    notifications: NotificationModel[];
    icu_id:string;
    tenant_id: string;
    communicationLogs?: CommunicationLogModel[];
    isReset: boolean;
}