import ReactDom from "react-dom";
import Root from "./Root";

import { App } from "./components/App";
import "./scss/components/App.scss";
import "./services/i18n/i18n.service";


ReactDom.render(
  
  <Root>
    <App/>
  </Root>,
  document.querySelector("#root")
);
