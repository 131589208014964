import React from "react";

export const WifiGoodIcon = ({
  height = "24px",
  width = "24px",
  color = "#0F284C",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_66_4726)">
  <mask id="mask0_66_4726" mask-type="alpha" maskUnits="userSpaceOnUse" x="-4" y="1" width="18" height="17">
  <path fillRule="evenodd" clipRule="evenodd" d="M5 16.5833C8.91202 16.5833 12.0833 13.412 12.0833 9.5C12.0833 5.58798 8.91202 2.41667 5 2.41667C1.08798 2.41667 -2.08333 5.58798 -2.08333 9.5C-2.08333 13.412 1.08798 16.5833 5 16.5833ZM5 18C9.69442 18 13.5 14.1944 13.5 9.5C13.5 4.80558 9.69442 1 5 1C0.305579 1 -3.5 4.80558 -3.5 9.5C-3.5 14.1944 0.305579 18 5 18Z" fill="#D23A3A"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M4.99992 13.75C7.34713 13.75 9.24992 11.8472 9.24992 9.5C9.24992 7.15279 7.34713 5.24999 4.99992 5.24999C2.65271 5.24999 0.749919 7.15279 0.749919 9.5C0.749919 11.8472 2.65271 13.75 4.99992 13.75ZM4.99992 15.1667C8.12953 15.1667 10.6666 12.6296 10.6666 9.5C10.6666 6.37038 8.12953 3.83333 4.99992 3.83333C1.87031 3.83333 -0.666748 6.37038 -0.666748 9.5C-0.666748 12.6296 1.87031 15.1667 4.99992 15.1667Z" fill="#D23A3A"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M5.00005 13.75C6.30406 13.75 7.36117 12.6929 7.36117 11.3889C7.36117 10.0849 6.30406 9.02778 5.00005 9.02778C3.69605 9.02778 2.63894 10.0849 2.63894 11.3889C2.63894 12.6929 3.69605 13.75 5.00005 13.75ZM5.00005 16.1111C7.60807 16.1111 9.72228 13.9969 9.72228 11.3889C9.72228 8.78088 7.60807 6.66667 5.00005 6.66667C2.39204 6.66667 0.277832 8.78088 0.277832 11.3889C0.277832 13.9969 2.39204 16.1111 5.00005 16.1111Z" fill="#D23A3A"/>
  </mask>
  <g mask="url(#mask0_66_4726)">
  <path d="M5.40255 8.48149C5.20272 8.75269 4.79733 8.75269 4.5975 8.48149L-1.61108 0.0555573H11.6111L5.40255 8.48149Z" fill={color}/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_66_4726">
  <rect width="10" height="10" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);