import React from "react";

export const ClockIcon = ({
    height = "2.4rem",
    width = "2.4rem",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="9" cy="11" r="8" stroke="#0F284C" strokeWidth="2" />
        <path
            d="M9 7V11L11 13"
            stroke="#0F284C"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <rect x="6" width="6" height="3" rx="1.5" fill="#0F284C" />
        <rect
            x="14.4143"
            y="16"
            width="4"
            height="2"
            rx="1"
            transform="rotate(45 14.4143 16)"
            fill="#0F284C"
        />
        <rect
            x="4.24268"
            y="17.4143"
            width="4"
            height="2"
            rx="1"
            transform="rotate(135 4.24268 17.4143)"
            fill="#0F284C"
        />
    </svg>
);