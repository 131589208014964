import amplifyService from './amplifyService';
import iotApi from '../apis/iot';
import { ZenObservable } from "zen-observable-ts";
type callBack = (value?: any) => void;

class IotService {
    
    private static _instance: IotService;
    
    constructor(private readonly pubSub = amplifyService.getPubSub) {}

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    subscribeToTopic = (topic: string, next: callBack, err:  callBack, complete: callBack) => {
        return this.pubSub.subscribe(topic).subscribe(next, err, complete);
    }

    unsubscribeToTopic = (reference: ZenObservable.Subscription) => {
        return reference.unsubscribe();
    }
    
    publishToTopic = (deviceId: string, topic: string, payload: any )  => {
        return iotApi.post("publish", {deviceId, topic, payload});
    }
}

export default IotService.Instance;