import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from 'react-redux';

import { CommunicationIcon, WrapSize } from "src/icons";
import {getNextEventIndex} from "src/utils/generalMethods";
import { SchedulerEvent } from "src/models/scheduler.model";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import {StoreState} from "src/reducers";
import { DialogTypes, OpenDialogArgs } from "../../general/CustomDialog";
import {
    getEventDuration,
    getTodayEvents,
    getFamilyRecordingsDuration,
    getRoundedMinutesFromMilliseconds
} from "./utils";
import EventsSchedule from "./EventsSchedule";
import NearBedSchedulerViewInformation from "./NearBedSchedulerViewInformation";
import {useMixpanel} from "src/hooks/useMixpanel";
import { ActiveCommunicationType } from "src/models/device.model";

interface NearBedSchedulerViewProps {
    activeCommunication: ActiveCommunicationType;
}
interface NearBedSchedulerViewSelectorResult {
    schedulerEvents: SchedulerEvent[];
    timeZone: string | undefined;
    musicDuration: string;
    familyDuration: string;
    activeCommunication?: ActiveCommunicationType;
}

const nearBedSchedulerViewSelector = ({icus, schedulerEvents}: StoreState): NearBedSchedulerViewSelectorResult => {
    const icu = icus[0] || {}

    return {
        schedulerEvents: schedulerEvents || [],
        timeZone: icu?.settings?.account_timezone,
        musicDuration: `${getRoundedMinutesFromMilliseconds(icu?.settings?.stream_timeout)} min`,
        familyDuration: getFamilyRecordingsDuration(icu?.settings?.family_recordings_duration, icu?.settings?.family_recordings_tolerance),
    }
}

export const NearBedSchedulerView = ({ activeCommunication }: NearBedSchedulerViewProps): JSX.Element => {
    const { timeZone, musicDuration, familyDuration, schedulerEvents } = useSelector(nearBedSchedulerViewSelector, shallowEqual);
    const { t } = useTranslation();
    const { trackOpenDailySchedule, trackCloseDailySchedule } = useMixpanel()
    const [event, setEvent] = useState<SchedulerEvent>();
    const [dailyEvents, setDailyEvents] = useState<SchedulerEvent[]>([]);
    const [nextEventIndex, setNextEventIndex] = useState<number>(-1);
    const { showDialog} = React.useContext(DialogContext) as DialogPropTypes;

    const { communicationTypeId, time, text } = event || {};
    const duration = getEventDuration({ communicationTypeId, musicDuration, familyDuration })
    const isNexEventExists = nextEventIndex !== -1

    const openDailyScheduleDialog = () => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.DAILY_SCHEDULER,
            component: (
                <div className="daily-scheduled-content">
                    <EventsSchedule events={dailyEvents} nextEventIndex={nextEventIndex} musicDuration={musicDuration}
                               familyDuration={familyDuration} />
                </div>
            ),
            cancelCallback: trackCloseDailySchedule

        };
        trackOpenDailySchedule()
        showDialog(args);
    };

    useEffect(() => {
        if (timeZone && schedulerEvents) {
            const todayEvents = getTodayEvents(schedulerEvents, timeZone)
            const index = getNextEventIndex(todayEvents, timeZone);

            setDailyEvents(todayEvents);

            if (index >= 0) {
                setEvent(todayEvents[index]);
                setNextEventIndex(index);
            }
        }
    }, [timeZone, schedulerEvents, activeCommunication]);

    if (!schedulerEvents) return <div>Loading...</div>;

    return (
        <div className="near-bed-scheduler-view">
            <div className="near-bed-scheduler-view__title-wrapper">
                <div className="near-bed-scheduler-view__title">
                    {t(`Scheduled Next`)}
                </div>
            </div>
            <div className="near-bed-scheduler-view__communications-wrapper">
                <ul className="near-bed-scheduler-view__items">
                    {isNexEventExists ?
                        <li className="near-bed-scheduler-view__item">
                            <CommunicationIcon
                                width="4rem"
                                height="4rem"
                                communicatoinType={communicationTypeId}
                                wrapSize={WrapSize.MEDIUM}
                            />
                            <div
                                className={`near-bed-scheduler-view__line near-bed-scheduler-view__line--${communicationTypeId}`}/>
                            <div className={`near-bed-scheduler-view__information-wrapper`}>
                                <NearBedSchedulerViewInformation time={time} text={text} duration={duration} />
                            </div>
                        </li>
                        :
                        <div className="near-bed-scheduler-view__empty">
                            {t("That's all for today :)")}
                        </div>
                    }
                </ul>
            </div>
            <div className="near-bed-scheduler-view__daily-schedule">
                <div className="near-bed-scheduler-view__daily-schedule-text" onClick={openDailyScheduleDialog}>
                    {t("Daily schedule")}
                </div>
            </div>
        </div>
    );
};
